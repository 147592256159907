import type { MasterSizeScaleModel, SizeScaleModel, UpdateSizeScaleModel } from './model/sizeModel'
import { backgroundJobConstants } from '@/models/constants'
import Net from '@/services/net'
import utils from '@/services/utils'

export function updateModelSizeScale(catalogCode: number, modelId: number, requestBody: UpdateSizeScaleModel) {
  return Net.t1.put(`catalogs/${catalogCode}/models/${modelId}/assignsizescale`, requestBody)
}

export function getMasterSizeScales(sellerId: number) {
  return Net.t1.get<MasterSizeScaleModel[]>(`sellers/${sellerId}/mastersizescales`)
    .then(response => response.data)
}

export function getSellerAvailableSizeScales(sellerId: number) {
  return Net.t1.get<SizeScaleModel[]>(`sellers/${sellerId}/sizescales`)
    .then((response) => {
      const result: SizeScaleModel[] = []
      if (response.data) {
        response.data.forEach((sizeScale) => {
          if (sizeScale.Criteria) {
            sizeScale.Criteria = utils.tryParse(sizeScale.Criteria.toString())
          }
          result.push(sizeScale)
        })
      }
      return result
    })
}

export function getCatalogSizeScales(catalogCode: number) {
  return Net.t1.get<SizeScaleModel[]>(`catalogs/${catalogCode}/sizescales`)
}

export function updateModelSizeScaleBgJob(sellerId: number, attributeGroupId: number, requestObject: Record<string, any>) {
  return Net.t1.put(`/sellers/${sellerId}/${attributeGroupId}/models/bulkassignsizescale?sourceapplication=${backgroundJobConstants.sourceApplication}&sourceaction=${backgroundJobConstants.sourceAction.editSizes}`, requestObject)
}
