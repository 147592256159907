<template>
  <div class="flex items-center h-4 max-h-4">
    <tx-checkbox
      v-if="showCheckbox"
      v-model="checkedModel"
      class="w-full"
      :disabled="disabled"
      :label="attribute.DisplayName"
      :label-class="`text-xs h-3 cursor-pointer leading-3 tracking-wide uppercase label w-full whitespace-nowrap text-ellipsis overflow-hidden inline-block ${required ? 'required' : ''}`"
      @change="onCheckChanged"
    />
    <div v-else class="w-full h-3 overflow-hidden text-xs leading-3 tracking-wide uppercase label whitespace-nowrap text-ellipsis" :class="{ required }">
      {{ attribute.DisplayName }}
    </div>
    <div v-if="attribute.CriteriaVettingList" v-tooltip="{ text: getCriteriaInfo(attribute), theme: { placement: tooltipPlacement, width: tooltipWidth } }">
      <font-awesome-icon icon="fa-light fa-info-circle" class="w-4 h-4 ml-0.5 cursor-pointer hover:text-primary-500" />
    </div>
    <div v-if="attribute.AttributeType === AttributeType.LookupTable" v-tooltip="{ text: getLookupTableAttributeInfo(attribute), theme: { placement: tooltipPlacement, width: tooltipWidth } }">
      <font-awesome-icon icon="fa-light fa-info-circle" class="w-4 h-4 ml-0.5 cursor-pointer hover:text-primary-500" />
    </div>
    <div v-if="attribute.IsSeasonless && showUpdateSeasonlessAttributeWarning" v-tooltip="{ text: t('updateArticle.seasonlessAttributeWarning'), theme: { placement: tooltipPlacement, width: tooltipWidth } }">
      <font-awesome-icon icon="fa-light fa-info-circle" class="w-4 h-4 ml-0.5 cursor-pointer hover:text-primary-500" />
    </div>
    <div
      v-if="showExternalChangeManagementInfo && attribute.ReadOnly && attribute.ExternalChangeManagementURL && attribute.ExternalChangeManagementURL.toString().trim()"
      v-tooltip="{ text: t('modelCreate.changeManagement'), theme: { placement: tooltipPlacement, width: tooltipWidth } }" @click="openNewBrowserTab($event, attribute.ExternalChangeManagementURL)"
    >
      <font-awesome-icon icon="fa-light fa-info-circle" class="w-4 h-4 ml-0.5 cursor-pointer hover:text-primary-500" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import TxCheckbox from './TxCheckbox.vue'
import utils from '@/services/utils'
import { useUserStore } from '@/store/userData'
import { AttributeType } from '@/models/catalogAttribute'

interface IProps {
  attribute: IFormEditorAttribute
  checked?: boolean
  disabled?: boolean
  required?: boolean
  showCheckbox?: boolean
  showExternalChangeManagementInfo?: boolean
  showUpdateSeasonlessAttributeWarning?: boolean
  tooltipPlacement?: string
  tooltipWidth?: string
}
const props = withDefaults(defineProps<IProps>(), {
  checked: false,
  disabled: false,
  required: false,
  showCheckbox: false,
  showExternalChangeManagementInfo: false,
  showUpdateSeasonlessAttributeWarning: true,
  tooltipPlacement: 'left',
  tooltipWidth: '250px',
})

const emit = defineEmits<{
  (e: 'update:checked', checked: boolean): void
  (e: 'checkChanged', checked: boolean): void
}>()

const { t } = useI18n()
const userStore = useUserStore()

const checkedModel = ref(props.checked)

function getCriteriaInfo(attribute) {
  const dependentAttributesDisplayName = new Set<string>()
  Object.keys(attribute.Criteria).forEach((vettingListValue) => {
    const vettingListValueCriteria = attribute.Criteria[vettingListValue] // criteria for each possible vetting list attribute value
    const dependantAttributesSystemName = utils.isDefined(vettingListValueCriteria) ? Object.keys(vettingListValueCriteria) : [] // dependant attributes are the attributes where the current vetting list attribute's value will be depend on
    dependantAttributesSystemName.forEach((dependantAttributeSystemName) => {
      if (userStore.myAttributes && userStore.myAttributes[dependantAttributeSystemName]) {
        dependentAttributesDisplayName.add(userStore.myAttributes[dependantAttributeSystemName].DisplayName)
      }
    })
  })
  return `${attribute.DisplayName} is dependent on ${[...dependentAttributesDisplayName].join(', ')}`
}

function getLookupTableAttributeInfo(attribute) {
  if (userStore.indexedLookupAttributeDefinition) {
    if (userStore.indexedLookupAttributeDefinition[attribute.SystemName]) {
      return `${attribute.DisplayName} is dependent on ${[...userStore.indexedLookupAttributeDefinition[attribute.SystemName].SourceAttributes].join(', ')}`
    }
    else {
      return `${attribute.DisplayName} does not have any source attribute(s) assigned`
    }
  }
}

function onCheckChanged(checkboxCurrentValue) {
  emit('checkChanged', checkboxCurrentValue)
  emit('update:checked', checkboxCurrentValue)
}

function openNewBrowserTab(event, url) {
  window.open(url, '_blank')?.focus()
}

watch(() => props.checked, (checked) => {
  checkedModel.value = checked
})
</script>
