import { clone, cloneDeep, isEmpty } from 'lodash-es'
import useMasterDataLookup from '../../composables/masterDataLookup'
import type { IDataExistConstraint, IField, IImportModel } from '../../type'
import type { ITxDataTableColumn, ITxDataTableVisibleColumn } from '@/shared/components/txDataTable/TxDataTable.types'
import { AttributeType } from '@/models/catalogAttribute'
import { useUserStore } from '@/store/userData'
import utils from '@/services/utils'
import { appConstants, privileges } from '@/models/constants'

export default class ArticleModel {
  modelPath: string
  fields: IField[]
  importInfo: Record<string, any>

  constructor(t) {
    this.modelPath = 'catalogs.articles'
    this.importInfo = {
      validateStateEditableFields: true,
      validateRequiredLookupTableAttributes: true,
      isCatalogSpecificModel: true,
      uniqueConstraints: [
        { name: 'Constraint 1', fields: ['ArticleNumber'] },
      ],
    }
    // NOTE: Always use the clone of fields never change the property directly on field
    this.fields = this.getFields(t)
  }

  private getFields(t) {
    const userStore = useUserStore()
    const { getModelsLookup, getSizeScalesLookup, getMasterSizeScalesLookup, getRetailWindowsLookup, getArticleStatesLookup, getColorsLookup, getArticlesLookup, getLinkedCatalogArticleNumberAttributesLookup, getSellerModelsLookup } = useMasterDataLookup()
    const statusAttributeTypeFilterLookup = new Map([
      [-1, t('general.all')],
      [1, t('general.active')],
      [0, t('general.inactive')],
    ])
    let articleStatusFilterLookUp = new Map([
      [-1, t('general.all')],
      [1, t('general.active')],
      [0, t('general.inactive')],
    ])
    if (userStore.activeCatalog?.DataSourceTypeId === appConstants.catalogTypes.inherited) {
      articleStatusFilterLookUp = new Map([
        [-1, t('general.all')],
        [1, t('general.active')],
        [3, t('general.globallyDropped')],
      ])
      // for Inherited Catalog dont show not assorted articles articles if the UI_ViewUnAssortedArticles is not assigned.
      if (userStore.userProfile.isValidPrivilege(privileges.article.viewUnAssortedArticles)) {
        articleStatusFilterLookUp.set(2, t('general.notAssorted'))
      }
    }

    const fields: IField[] = [
      { SystemName: 'Id', DisplayName: t('admin.articles.fields.articleId'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Int, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'ArticleNumber', DisplayName: t('admin.articles.fields.articleNumber'), Visible: true, Creatable: true, Editable: true, AttributeType: AttributeType.Nvarchar, IsPersonal: false, IsStatic: true, IsRequired: true, maxLength: 20, minLength: 1, pattern: '^[^ ]*(?: [^ ]+)* ?$', autoMap: ['article', 'articlenumber', 'article number', 'colorway number', 'colorwaynumber', 'artnbr', 'style color code'], messages: { pattern: t('validations.consecutiveWhiteSpaceNotAllowed') }, column: -1 } as IField,
      { SystemName: 'ModelId', DisplayName: t('admin.articles.fields.modelId'), Visible: false, Creatable: true, Editable: false, AttributeType: AttributeType.Lookup, lookupSource: getModelsLookup, lookupIdField: 'ModelId', lookupDisplayField: 'ModelNumber', IsRequired: false, maxLength: -1, minLength: 1 } as IField,
      { SystemName: 'ModelId', DisplayName: t('admin.articles.fields.modelId'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Int, IsRequired: false, maxLength: -1, minLength: 1 } as IField,
      { SystemName: 'ModelNumber', DisplayName: t('admin.articles.fields.modelNumber'), Visible: true, Creatable: true, Editable: false, showInEdit: true, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: 200, minLength: 0, autoMap: ['model', 'modelnumber', 'model number', 'style number', 'stylenumber', 'stylenbr'] } as IField,
      { SystemName: 'ArticleName', DisplayName: t('admin.articles.fields.modelName'), Visible: false, Creatable: true, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: true, maxLength: 100, minLength: 1 } as IField,
      { SystemName: 'ModelName', DisplayName: t('admin.articles.fields.modelName'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: true, maxLength: 100, minLength: 1 } as IField,
      // { SystemName: 'MasterModelId', DisplayName: t('admin.articles.fields.modelId'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Int, IsRequired: false, maxLength: -1, minLength: 1} as IField,
      { SystemName: 'LicenseeModelNumber', DisplayName: t('admin.articles.fields.licenseeModelNumber'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: 200, minLength: 0 } as IField,
      { SystemName: 'ParentModelNumber', DisplayName: t('admin.articles.fields.parentModelNumber'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: 200, minLength: 0 } as IField,
      { SystemName: 'ParentModelName', DisplayName: t('admin.articles.fields.parentModelName'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: 200, minLength: 0 } as IField,
      { SystemName: 'ColorCode', DisplayName: t('admin.articles.fields.colorCode'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Int, IsPersonal: false, IsStatic: true, prefixZeros: 3, IsRequired: false, maxLength: -1, minLength: 1, pattern: '^(0*[1-9][0-9]{0,2}|100|999)$', messages: { pattern: t('validations.positiveColorCode') } } as IField,
      { SystemName: 'ColorName', DisplayName: t('admin.articles.fields.colorName'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'HexCode', DisplayName: t('admin.articles.fields.hexCode'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: 7, minLength: 0 } as IField,
      { SystemName: 'ColorGroup', DisplayName: t('admin.articles.fields.colorGroup'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: 250, minLength: 0 } as IField,
      { SystemName: 'RAID', DisplayName: t('admin.articles.fields.RAID'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Int, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'RAN', DisplayName: t('admin.articles.fields.RAN'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'PRAID', DisplayName: t('admin.articles.fields.PRAID'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Int, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'PRAN', DisplayName: t('admin.articles.fields.PRAN'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'Status', DisplayName: t('admin.articles.fields.status'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Status, parentField: 'PS', IsRequired: false, maxLength: -1, minLength: 0, FilterLookup: articleStatusFilterLookUp } as IField,
      { SystemName: 'DropReason', DisplayName: t('admin.articles.fields.dropReason'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'PS', DisplayName: t('admin.articles.fields.parentStatus'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Status, IsRequired: false, maxLength: -1, minLength: 0, FilterLookup: statusAttributeTypeFilterLookup } as IField,
      { SystemName: 'Locked', DisplayName: t('admin.articles.fields.locked'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.BoolInt, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'ArticleSource', DisplayName: t('admin.articles.fields.articleSource'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'PSizeScale', DisplayName: t('admin.articles.fields.pSizeScale'), Visible: false, Creatable: false, Editable: false, showInEdit: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'PSizeScaleId', DisplayName: t('admin.articles.fields.pSizeScaleId'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'SizeScaleId', DisplayName: t('admin.articles.fields.sizeScaleId'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Int, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'SizeScaleId', DisplayName: t('admin.articles.fields.sizeScaleId'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Lookup, lookupSource: getSizeScalesLookup, lookupIdField: 'SizeScaleId', lookupDisplayField: 'SizeScale', IsRequired: true, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'MasterSizeScale', DisplayName: t('admin.articles.fields.masterSizeScale'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'SizeScale', DisplayName: t('admin.articles.fields.sizeScale'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'MasterSizeScaleId', DisplayName: t('admin.articles.fields.masterSizeScaleId'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Int, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'MasterSizeScaleId', DisplayName: t('admin.articles.fields.masterSizeScaleId'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Lookup, lookupSource: getMasterSizeScalesLookup, lookupIdField: 'MasterSizeScaleId', lookupDisplayField: 'SizeScale', IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'SortOrder', DisplayName: t('admin.articles.fields.sortOrder'), Visible: true, Creatable: true, Editable: true, AttributeType: AttributeType.Int, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'CreatedDate', DisplayName: t('admin.articles.fields.createdDate'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Date, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'UpdatedDate', DisplayName: t('admin.articles.fields.updatedDate'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Date, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
    ]
    if (userStore.myAttributes) {
      const myAttributes = Object.values(userStore.myAttributes!).filter(o => !o.IsRequest && !o.IsStatic && !o.IsPersonal)
      myAttributes.forEach((attribute) => {
        const newAtt: IField = clone(attribute) as IField
        fields.push(newAtt)
      })
    }
    const staticFields = [
      { SystemName: 'Created_By_User', DisplayName: t('admin.articles.fields.createdBy'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'Updated_By_User', DisplayName: t('admin.articles.fields.updatedBy'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      // TODO: when we this field need to check, what should be correct attribute type
      { SystemName: 'isArticleLockingDisable', Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.BoolInt, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'BlockImage', DisplayName: t('admin.articles.fields.blockImage'), Visible: true, Creatable: true, Editable: true, AttributeType: AttributeType.BoolInt, IsRequired: false, maxLength: -1, minLength: 0 } as IField,

      // retail window fields
      { SystemName: 'PShipmentStartDate', DisplayName: t('admin.articles.fields.pShipmentStartDate'), Visible: false, Creatable: false, Editable: false, showInEdit: false, AttributeType: AttributeType.DateTime, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'PShipmentEndDate', DisplayName: t('admin.articles.fields.pShipmentEndDate'), Visible: false, Creatable: false, Editable: false, showInEdit: false, AttributeType: AttributeType.DateTime, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'PRetailIntroMonth', DisplayName: t('admin.articles.fields.pRetailIntroMonth'), Visible: false, Creatable: false, Editable: false, showInEdit: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'PRetailExitMonth', DisplayName: t('admin.articles.fields.pRetailExitMonth'), Visible: false, Creatable: false, Editable: false, showInEdit: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'PPeriod', DisplayName: t('admin.articles.fields.pPeriod'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'PShipmentWindowRangeId', DisplayName: t('admin.articles.fields.pShipmentWindowRangeId'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'RetailIntroMonth', DisplayName: t('admin.articles.fields.retailIntroMonth'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'RetailExitMonth', DisplayName: t('admin.articles.fields.retailExitMonth'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'Period', DisplayName: t('admin.articles.fields.period'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'RetailIntroMonthId', DisplayName: t('admin.articles.fields.retailIntroMonth'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Lookup, lookupSource: getRetailWindowsLookup, lookupIdField: 'ShipmentWindowRangeId', lookupDisplayField: 'RetailIntroMonth', useLookupName: true, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'RetailExitMonthId', DisplayName: t('admin.articles.fields.retailExitMonth'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Lookup, lookupSource: getRetailWindowsLookup, lookupIdField: 'ShipmentWindowRangeId', lookupDisplayField: 'RetailExitMonth', useLookupName: true, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'ShipmentStartDate', DisplayName: t('admin.articles.fields.shipmentStartDate'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.DateTime, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'ShipmentEndDate', DisplayName: t('admin.articles.fields.shipmentEndDate'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.DateTime, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'IsOutOfShippingRange', DisplayName: t('admin.articles.fields.isOutOfShippingRange'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.BoolInt, IsRequired: false, maxLength: -1, minLength: 0 } as IField,

      // state level fields
      { SystemName: 'StateId', DisplayName: t('admin.articles.fields.stateId'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Lookup, lookupSource: getArticleStatesLookup, lookupIdField: 'StateId', lookupDisplayField: 'StateName', IsRequired: true, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'StateId', DisplayName: t('admin.articles.fields.stateId'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'StateName', DisplayName: t('admin.articles.fields.stateName'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      // these two fields used internally
      { SystemName: 'StateRank', DisplayName: t('admin.articles.fields.stateRank'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Int, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'ModelStateId', DisplayName: t('admin.articles.fields.modelStateId'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'ModelStateName', DisplayName: t('admin.articles.fields.modelStateName'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'ModelStateRank', DisplayName: t('admin.articles.fields.modelStateRank'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Int, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'AllowRetailWindowUpdateOnChild', DisplayName: t('admin.articles.fields.allowRetailWindowUpdateOnChild'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.BoolInt, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'AllowRetailWindowUpdate', DisplayName: t('admin.articles.fields.allowRetailWindowUpdate'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.BoolInt, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'SeasonalSequence', DisplayName: t('admin.articles.fields.seasonalSequence'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Int, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'ArticleLifecycle', DisplayName: t('admin.articles.fields.articleLifecycle'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'ModelLifecycle', DisplayName: t('admin.articles.fields.modelLifecycle'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'ModelSourceSeason', DisplayName: t('admin.articles.fields.modelSourceSeason'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 1 } as IField,
      { SystemName: 'ArticleSourceSeason', DisplayName: t('admin.articles.fields.articleSourceSeason'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 1 } as IField,
      { SystemName: 'ActivationDate', DisplayName: t('admin.articles.fields.activationDate'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Date, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'DropDate', DisplayName: t('admin.articles.fields.dropDate'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Date, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'ModelCreatedDate', DisplayName: t('admin.articles.fields.modelCreatedDate'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Date, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'ModelDropDate', DisplayName: t('admin.articles.fields.modelDropDate'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Date, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'ModelActivationDate', DisplayName: t('admin.articles.fields.modelActivationDate'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Date, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'SourceRequestId', DisplayName: t('admin.articles.fields.sourceRequestId'), Visible: true, Creatable: false, Editable: true, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'SourceRequestNumber', DisplayName: t('admin.articles.fields.sourceRequestNumber'), Visible: true, Creatable: false, Editable: true, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'ColoredUpBy', DisplayName: t('admin.articles.fields.coloredUpBy'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Nvarchar, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      { SystemName: 'ColoredUpDate', DisplayName: t('admin.articles.fields.coloredUpDate'), Visible: true, Creatable: false, Editable: false, AttributeType: AttributeType.Date, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
      // isfilterCriteria is used to filter colors based on the criteria, adding color at the end to validate the color criteria.
      { SystemName: 'ColorId', DisplayName: t('admin.articles.fields.primaryColor'), Visible: false, Creatable: false, Editable: false, AttributeType: AttributeType.Lookup, lookupSource: getColorsLookup, lookupIdField: 'ColorId', lookupDisplayField: 'ColorName', isfilterCriteria: true, IsRequired: false, maxLength: -1, minLength: 0 } as IField,
    ]
    fields.push(...staticFields)
    if (userStore.activeCatalog) {
      userStore.articleStateList.forEach((state) => {
        if (state.Status) {
          const stateField: IField = {
            SystemName: `StatesTimestamp.${state.StateId}`,
            DisplayName: `State TimeStamp: ${state.StateName}`,
            AttributeType: AttributeType.Date,
            Visible: true,
            Creatable: false,
            Editable: false,
            IsRequired: false,
            IsStatic: true,
            IsPersonal: false,
          }
          fields.push(stateField)
        }
      })
      userStore.activeCatalog.CatalogPriceGroupList.forEach((priceGroup) => {
        if (priceGroup.Status) {
          const priceField: IField = {
            SystemName: `_Prices.${priceGroup.Id}`,
            DisplayName: `P:${priceGroup.Name} (${priceGroup.CurrencyCode})`,
            AttributeType: AttributeType.Decimal,
            Visible: true,
            Creatable: false,
            Editable: false,
            IsRequired: false,
            IsStatic: true,
            IsPersonal: false,
          }
          fields.push(priceField)
        }
      })
      userStore.activeCatalog.CatalogSegmentationList.forEach((seg) => {
        if (seg.Status) {
          const segField: IField = {
            SystemName: `_Segmentations.${seg.Id}`,
            DisplayName: `S:${seg.Name}`,
            AttributeType: AttributeType.Nvarchar,
            Visible: true,
            Creatable: false,
            Editable: false,
            IsRequired: false,
            IsStatic: true,
            IsPersonal: false,
          }
          fields.push(segField)
        }
      })
    }
    // transform model
    // if auto numbering enabled article number and model number fields will be not creatable and ColorId will be enabled for create and edit
    if (userStore.sellerDetails?.AutomaticNumbering === 1) {
      const articleNumberField = fields.filter(field => field.SystemName === 'ArticleNumber')[0]
      if (utils.isDefined(articleNumberField)) {
        articleNumberField.Creatable = false
        articleNumberField.Editable = false
      }
      const modelNumberField = fields.filter(field => field.SystemName === 'ModelNumber')[0]
      if (utils.isDefined(modelNumberField)) {
        modelNumberField.Creatable = false
        modelNumberField.Editable = false
      }
      const colorIdField = fields.filter(field => field.SystemName === 'ColorId')[0]
      if (utils.isDefined(colorIdField)) {
        colorIdField.Creatable = true
        colorIdField.Editable = true
        colorIdField.showInEdit = true
      }
      const colorCodeField = fields.filter(field => field.SystemName === 'ColorCode')[0]
      if (utils.isDefined(colorCodeField)) {
        colorCodeField.Visible = true
      }
      const hexCodeField = fields.filter(field => field.SystemName === 'HexCode')[0]
      if (utils.isDefined(hexCodeField)) {
        hexCodeField.Visible = true
      }
      const colorNameField = fields.filter(field => field.SystemName === 'ColorName')[0]
      if (utils.isDefined(colorNameField)) {
        colorNameField.Visible = true
      }
    }

    if (userStore.activeCatalog?.IsShippingWindowApply === 1) {
      const allowRetailWindowUpdateField = fields.filter(field => field.SystemName === 'AllowRetailWindowUpdate')[0]
      if (utils.isDefined(allowRetailWindowUpdateField)) {
        allowRetailWindowUpdateField.Visible = true
      }

      const allowRetailWindowUpdateOnChild = fields.filter(field => field.SystemName === 'AllowRetailWindowUpdateOnChild')[0]
      if (utils.isDefined(allowRetailWindowUpdateOnChild)) {
        allowRetailWindowUpdateOnChild.Visible = true
        allowRetailWindowUpdateOnChild.Creatable = true
        allowRetailWindowUpdateOnChild.Editable = true
      }
    }

    // role based restriction: if retail window is set as not viweable do not show retail window related fields
    if (utils.isDefined(userStore.activeCatalog?._RoleRestrictedRetailWindow) && !isEmpty(userStore.activeCatalog?._RoleRestrictedRetailWindow) && userStore.activeCatalog?._RoleRestrictedRetailWindow.NotViewable) {
      const retailIntroMonthField = fields.filter(field => field.SystemName === 'RetailIntroMonth')[0]
      if (utils.isDefined(retailIntroMonthField)) {
        retailIntroMonthField.Visible = false
      }
      const retailExitMonthField = fields.filter(field => field.SystemName === 'RetailExitMonth')[0]
      if (utils.isDefined(retailExitMonthField)) {
        retailExitMonthField.Visible = false
      }
      const periodField = fields.filter(field => field.SystemName === 'Period')[0]
      if (utils.isDefined(periodField)) {
        periodField.Visible = false
      }
      const shipmentStartDateField = fields.filter(field => field.SystemName === 'ShipmentStartDate')[0]
      if (utils.isDefined(shipmentStartDateField)) {
        shipmentStartDateField.Visible = false
      }
      const shipmentEndDateField = fields.filter(field => field.SystemName === 'ShipmentEndDate')[0]
      if (utils.isDefined(shipmentEndDateField)) {
        shipmentEndDateField.Visible = false
      }
    }

    if (userStore.activeCatalog!.IsPriceByStyleApply) {
      if (userStore.sellerDetails?.AutomaticNumbering !== 1) {
        const modelNumberField = fields.filter(field => field.SystemName === 'ModelNumber')[0]
        if (utils.isDefined(modelNumberField)) {
          modelNumberField.IsRequired = true
        }
      }
      else {
        // if auto numbering enabled modelId lookup field will be mandatory
        const modelIdField = fields.filter(field => field.SystemName === 'ModelId' && field.AttributeType === AttributeType.Lookup)[0]
        if (utils.isDefined(modelIdField)) {
          modelIdField.IsRequired = true
        }
        // if auto numbering enabled modelId number field will be visible
        const modelIdNumberField = fields.filter(field => field.SystemName === 'ModelId' && field.AttributeType === AttributeType.Int)[0]
        if (utils.isDefined(modelIdNumberField)) {
          modelIdNumberField.Visible = true
        }
      }
    }

    // make setSizes itemAction and listAction visible if IsSizeScaleByStyle is enabled at seller
    if (userStore.sellerDetails?.IsSizeScaleByStyle) {
      // make SizeScale field visible
      const SizeScaleField = fields.filter(field => field.SystemName === 'SizeScale')[0]
      if (utils.isDefined(SizeScaleField)) {
        SizeScaleField.Visible = true
      }
      const masterSizeScaleIdField = fields.filter(field => field.SystemName === 'MasterSizeScaleId' && field.AttributeType === AttributeType.Int)[0]
      if (utils.isDefined(masterSizeScaleIdField)) {
        masterSizeScaleIdField.Visible = true
      }
      const masterSizeScaleField = fields.filter(field => field.SystemName === 'MasterSizeScale')[0]
      if (utils.isDefined(masterSizeScaleField)) {
        masterSizeScaleField.Visible = true
      }
      const modelIdField = fields.filter(field => field.SystemName === 'SizeScaleId')[0]
      if (utils.isDefined(modelIdField)) {
        modelIdField.Visible = true
      }
    }

    if (userStore.activeCatalog?.DataSourceTypeId === 3) {
    // disable block image edit/create for child catalog as this flag is now cascaded from master catalog
      const blockImageField = fields.filter(field => field.SystemName === 'BlockImage')[0]
      if (utils.isDefined(blockImageField)) {
        blockImageField.Creatable = false
        blockImageField.Editable = false
      }
      // make Overridable attributes editable and creatable for inherited catalogs if its required
      const overridableAttributes = fields.filter(field => field.Overridable)
      if (utils.isDefined(overridableAttributes) && overridableAttributes.length) {
        overridableAttributes.forEach((attribute) => {
          if (attribute.IsRequired) {
            attribute.Editable = true
          }
          if (attribute.IsRequired) {
            attribute.Creatable = true
          }
        })
      }
      // make parent_model_type attribute creatable/editable false
      const parentModelTypeField = fields.filter(field => field.SystemName === 'parent_model_type')[0]
      if (utils.isDefined(parentModelTypeField)) {
        parentModelTypeField.Creatable = false
        parentModelTypeField.Editable = false
      }

      // modify child catalog Articles Fields
      // add Is Assigned field used internally
      const IsAccessibleField: IField = Object.assign({}, appConstants.staticFieldTemplate, {
        AttributeType: AttributeType.IntBool,
        DisplayName: t('admin.articles.fields.isAssigned'),
        SystemName: 'IsAccessible',
        Creatable: false,
        Editable: false,
        Visible: false,
        column: -1,
      })
      fields.push(IsAccessibleField)

      const PRANfield = fields.filter(field => field.SystemName === 'PRAN')[0]
      if (utils.isDefined(PRANfield)) {
        PRANfield.Visible = true
      }
      const PSfield = fields.filter(field => field.SystemName === 'PS')[0]
      if (utils.isDefined(PSfield)) {
        PSfield.Visible = true
      }

      if (userStore.activeCatalog?.IsShippingWindowApply === 1) {
        // make parent shipping range visible and read only properties on edit dialog for inherited articles
        const pRetailIntroMonthField = fields.filter(field => field.SystemName === 'PRetailIntroMonth')[0]
        if (utils.isDefined(pRetailIntroMonthField)) {
          pRetailIntroMonthField.Visible = true
          pRetailIntroMonthField.showInEdit = true
        }
        const pRetailExitMonthField = fields.filter(field => field.SystemName === 'PRetailExitMonth')[0]
        if (utils.isDefined(pRetailExitMonthField)) {
          pRetailExitMonthField.Visible = true
          pRetailExitMonthField.showInEdit = true
        }

        // make parent shipping start date visible and read only properties on edit dialog for inherited articles
        const pShipmentStartDateField = fields.filter(field => field.SystemName === 'PShipmentStartDate')[0]
        if (utils.isDefined(pShipmentStartDateField)) {
          pShipmentStartDateField.Visible = true
          pShipmentStartDateField.showInEdit = true
        }

        // make parent shipping end date visible and read only properties on edit dialog for inherited articles
        const pShipmentEndDateField = fields.filter(field => field.SystemName === 'PShipmentEndDate')[0]
        if (utils.isDefined(pShipmentEndDateField)) {
          pShipmentEndDateField.Visible = true
          pShipmentEndDateField.showInEdit = true
        }

        const pPeriodField = fields.filter(field => field.SystemName === 'PPeriod')[0]
        if (utils.isDefined(pPeriodField)) {
          pPeriodField.Visible = true
          pPeriodField.showInEdit = true
        }
      }
      if (userStore.sellerDetails?.IsSizeScaleByStyle) {
        // make parent size scale visible and read only properties on edit dialog for inherited articles
        const pSizeScaleField = fields.filter(field => field.SystemName === 'PSizeScale')[0]
        if (utils.isDefined(pSizeScaleField)) {
          pSizeScaleField.Visible = true
        }

        // make parent size scale visible for inherited articles
        const pSizeScaleIdField = fields.filter(field => field.SystemName === 'PSizeScaleId')[0]
        if (utils.isDefined(pSizeScaleIdField)) {
          pSizeScaleIdField.Visible = true
        }
      }
    }

    // add lookup surce for articlenumber attributes
    const articleNumberAttributes = fields.filter(field => field.AttributeType === AttributeType.ArticleNumber)
    if (utils.isDefined(articleNumberAttributes) && articleNumberAttributes.length) {
      articleNumberAttributes.forEach((attribute) => {
        attribute.lookupSource = getArticlesLookup
        attribute.lookupIdField = 'Id'
        attribute.lookupDisplayField = 'ArticleNumber'
      })
    }

    // add lookup source for linkedcatalogarticlenumber attributes
    const linkedCatalogArticleNumberAttributes = fields.filter(field => field.AttributeType === AttributeType.LinkedCatalogArticleNumber)
    if (utils.isDefined(linkedCatalogArticleNumberAttributes) && linkedCatalogArticleNumberAttributes.length) {
      linkedCatalogArticleNumberAttributes.forEach((attribute) => {
        attribute.lookupSource = getLinkedCatalogArticleNumberAttributesLookup
        attribute.lookupIdField = 'Id'
        attribute.lookupDisplayField = 'ArticleNumber'
      })
    }

    // add lookup source for modelnumber attributes
    const modelNumberAttributes = fields.filter(field => field.AttributeType === AttributeType.ModelNumber)
    if (utils.isDefined(modelNumberAttributes) && modelNumberAttributes.length) {
      modelNumberAttributes.forEach((attribute) => {
        attribute.lookupSource = getSellerModelsLookup
        attribute.lookupIdField = 'ModelId'
        attribute.lookupDisplayField = 'ModelNumber'
      })
    }

    // non calc attributes with validation expression
    const attributesWithValidationExpression = fields.filter(field => field.AttributeType !== AttributeType.Calc && utils.isDefined(field.ValidationExpression) && field.ValidationExpression.length)
    if (utils.isDefined(attributesWithValidationExpression) && attributesWithValidationExpression.length) {
      attributesWithValidationExpression.forEach((attribute) => {
        if (utils.tryParse(attribute.ValidationExpression!)) {
          attribute.txPattern = attribute.ValidationExpression !== '{}' ? attribute.ValidationExpression : ''
          attribute.pattern = ''
        }
        else {
          attribute.txPattern = ''
          attribute.pattern = attribute.ValidationExpression != null && attribute.ValidationExpression !== '' && attribute.ValidationExpression !== '{}' ? attribute.ValidationExpression : ''
        }
        const message = (attribute.ValidationMessage && attribute.ValidationMessage !== '') ? attribute.ValidationMessage : t('validations.pattern')

        if (!attribute.messages) {
          attribute.messages = {}
        }

        attribute.messages.pattern = message
      })
    }

    // make self attribute creatable or editable if they are required attributes or creatable and editable respectively
    // and if source is parent disable create and edit
    const attributeSourceParentAttributes = fields.filter(field => utils.isDefined(field.AttributeSource) && field.AttributeSource?.toUpperCase() !== 'SELF')
    if (utils.isDefined(attributeSourceParentAttributes) && attributeSourceParentAttributes.length) {
      attributeSourceParentAttributes.forEach((attribute) => {
        attribute.Editable = false
        attribute.Creatable = false
      })
    }
    const attributeSourceSelfAttributes = fields.filter(field => utils.isDefined(field.AttributeSource) && field.AttributeSource?.toUpperCase() === 'SELF' && field.AttributeType !== AttributeType.Calc)
    if (utils.isDefined(attributeSourceSelfAttributes) && attributeSourceSelfAttributes.length) {
      attributeSourceSelfAttributes.forEach((attribute) => {
        if (attribute.IsRequired) {
          attribute.Editable = true
        }
        if (attribute.IsRequired) {
          attribute.Creatable = true
        }
      })
    }
    return fields
  }

  getDataTableColumns() {
    const dataTableFields: ITxDataTableColumn[] = this.fields.filter(field => field.Visible && !field.IsPersonal)
      .map(o => ({
        property: o.SystemName,
        title: o.DisplayName,
        filterLookup: o.FilterLookup,
        type: o.AttributeType,
        lookupDisplayField: o.lookupDisplayField,
        lookupSource: o.lookupSource,
        lookupIdField: o.lookupIdField,
        useLookupName: o.useLookupName,
      }))
    return dataTableFields
  }

  getDefaultVisibleColumnList() {
    // Default column list
    const defaultColumns = ['ArticleNumber', 'ModelNumber', 'ArticleName', 'Status', 'UpdatedDate']

    const defaultFieldObjects: ITxDataTableVisibleColumn[] = this.fields.filter(field =>
      defaultColumns.includes(field.SystemName) && field.Visible && !field.IsPersonal,
    ).map(o => ({
      property: o.SystemName,
      order: 1,
      width: 0.175,
    }))

    // Filter dynamic attributes that should be visible by default with VisibleInListByDefault as true
    const dynamicAttributes: ITxDataTableVisibleColumn[] = this.fields
      .filter(field => !field.IsStatic && !field.IsPersonal && field.Visible && field.VisibleInListByDefault)
      .slice(0, 5)
      .map(o => ({
        property: o.SystemName,
        order: 1,
        width: 0.175,
      }))

    const updatedDateIndex = defaultFieldObjects.findIndex(field => field.property === 'UpdatedDate')

    // Combine default fields with dynamic attributes before UpdatedDate
    const combinedFields: ITxDataTableVisibleColumn[] = [
      ...defaultFieldObjects.slice(0, updatedDateIndex),
      ...dynamicAttributes,
      ...defaultFieldObjects.slice(updatedDateIndex),
    ]

    return combinedFields
  }

  getImportData(action: string) {
    // TODO: Neethu -> we need to refactor this function similar to 'updateViaImportModel'
    const userStore = useUserStore()
    const { getColorsLookup, getDropReasons, getSizeScalesLookup, getMasterSizeScalesLookup } = useMasterDataLookup()

    let importFields: IField[] = []
    const importInfo = cloneDeep(this.importInfo)
    if (action === 'import') {
      importInfo.uniqueConstraints = [{ name: 'Constraint 1', fields: ['ArticleNumber', 'ModelNumber'] }]
      importFields = cloneDeep(this.fields)
      importFields.filter(field => field.SystemName === 'Status')[0].Creatable = true
      importFields.filter(field => field.SystemName === 'ModelId')[0].Creatable = false
      if (userStore.activeCatalog!.DataSourceTypeId !== 3) {
        // enable Drop Reason column for mapping as an optional field
        const dropReasonField = importFields.filter(field => field.SystemName === 'DropReason')[0]
        dropReasonField.Creatable = true
        // make the DropReason column mandatory while validation and not mandatory while mapping
        dropReasonField.isRequiredWhileValidating = true
        const dataExistsConstraints: IDataExistConstraint = { lookupSource: getDropReasons }
        dataExistsConstraints.model = { lookupDisplayField: 'Reason' }
        dropReasonField.dataExistConstraints = dataExistsConstraints
      }
      else {
        // primary color is not updatable by child catalog
        const colorIdField = importFields.filter(field => field.SystemName === 'ColorId')[0]
        if (utils.isDefined(colorIdField)) {
          colorIdField.Creatable = false
        }
      }
      // if automatic article numbering is enabled remove article number and model number field
      // and add ArticleId and ModelId as optional fields(User should enter the id's in excel file)
      if (userStore.sellerDetails?.AutomaticNumbering === 1) {
        const articleNumberField = importFields.filter(field => field.SystemName === 'ArticleNumber')[0]
        if (utils.isDefined(articleNumberField)) {
          articleNumberField.Creatable = true
          if (userStore.activeCatalog!.DataSourceTypeId === 3) {
            articleNumberField.IsRequired = true
          }
          else {
            articleNumberField.IsRequired = false
            articleNumberField.minLength = 0
          }
        }
        const modelNumberField = importFields.filter(field => field.SystemName === 'ModelNumber')[0]
        if (utils.isDefined(modelNumberField)) {
          modelNumberField.Creatable = true
          modelNumberField.minLength = 0
        }
      }
      // make ParentModelNumber creatable
      const parentModelTypeField = importFields.filter(field => field.SystemName === 'parent_model_type')[0]
      if (parentModelTypeField && parentModelTypeField.Creatable) {
        // make ParentModelNumber creatable
        const parentModelNumberField = importFields.filter(field => field.SystemName === 'ParentModelNumber')[0]
        if (utils.isDefined(parentModelNumberField)) {
          parentModelNumberField.Creatable = true
        }
      }

      // if configured model name length then change the allowed max length to the configured value
      const configuredModelNameLength = userStore.sellerDetails?.ModelNameLength || 100
      if (configuredModelNameLength !== 100) {
        const articleNameField = importFields.filter(field => field.SystemName === 'ArticleName')[0]
        articleNameField.maxLength = configuredModelNameLength
      }

      // make Shipment Range Field creatable when "DummyArticleApplyRetailWindow" privilege is assigned
      if (userStore.userProfile.isValidPrivilege('UpdateArticleShipmentWindowRange') && userStore.activeCatalog?.IsShippingWindowApply === 1) {
        if ((utils.isDefined(userStore.activeCatalog?._RoleRestrictedRetailWindow) && !isEmpty(userStore.activeCatalog?._RoleRestrictedRetailWindow) && !userStore.activeCatalog?._RoleRestrictedRetailWindow.NotEditable) || (isEmpty(userStore.activeCatalog?._RoleRestrictedRetailWindow))) {
          const retailIntroMonthField = importFields.filter(field => field.SystemName === 'RetailIntroMonthId' && field.AttributeType === AttributeType.Lookup)[0]
          retailIntroMonthField.Creatable = true
          const retailExitMonthField = importFields.filter(field => field.SystemName === 'RetailExitMonthId' && field.AttributeType === AttributeType.Lookup)[0]
          retailExitMonthField.Creatable = true
        }
      }

      // make LinkedCatalogArticleNumber and lookuptable type attributes not creatable through import
      const noncreatableFields = importFields.filter(field => field.AttributeType === AttributeType.LinkedCatalogArticleNumber || field.AttributeType === AttributeType.LookupTable)
      noncreatableFields.forEach(field => field.Creatable = false)

      // add lookup source for colorpalatte attributes
      const colorPalatteAttributes = importFields.filter(field => field.AttributeType === AttributeType.ColorPalette && field.Creatable)
      if (utils.isDefined(colorPalatteAttributes) && colorPalatteAttributes.length) {
        colorPalatteAttributes.forEach((attribute) => {
          attribute.lookupSource = getColorsLookup
          attribute.isfilterCriteria = true
          attribute.lookupIdField = 'ColorName'
          attribute.lookupDisplayField = 'ColorId'
        })
      }

      importFields = importFields.filter(field => field.Creatable && field.AttributeType !== AttributeType.Calc)
    }
    else if (action === 'updateViaImport') {
      importFields = cloneDeep(this.fields)
      importInfo.uniqueConstraints = [{ name: 'Constraint 1', fields: ['ArticleNumber'] }]
      importFields.filter(field => field.SystemName === 'ModelNumber')[0].Creatable = false
      importFields.filter(field => field.SystemName === 'ModelId')[0].Creatable = false
      // always make articleNumber field creatable for updateViaImport action so user will be able to map a field from excel file
      importFields.filter(field => field.SystemName === 'ArticleNumber')[0].Creatable = true
      importFields.filter(field => field.SystemName === 'ArticleName')[0].Creatable = false // remove article name field
      // make ParentModelNumber creatable
      const parentModelTypeField = importFields.filter(field => field.SystemName === 'parent_model_type')[0]
      if (parentModelTypeField && parentModelTypeField.Creatable) {
        // make ParentModelNumber creatable
        const parentModelNumberField = importFields.filter(field => field.SystemName === 'ParentModelNumber')[0]
        if (utils.isDefined(parentModelNumberField)) {
          parentModelNumberField.Creatable = true
        }
      }
      // make LinkedCatalogArticleNumber and lookuptable type attributes not creatable through import
      const noncreatableFields = importFields.filter(field => field.AttributeType === AttributeType.LinkedCatalogArticleNumber || field.AttributeType === AttributeType.LookupTable)
      noncreatableFields.forEach(field => field.Creatable = false)
      importFields.forEach((field) => {
        if (!field.IsStatic) {
          field.IsRequired = false
        }
      })

      if (userStore.activeCatalog!.DataSourceTypeId === 3) {
        // primary color is not updatable by child catalog
        const colorIdField = importFields.filter(field => field.SystemName === 'ColorId')[0]
        if (utils.isDefined(colorIdField)) {
          colorIdField.Creatable = false
        }
      }

      // make Shipment Range Field creatable when "DummyArticleApplyRetailWindow" privilege is assigned
      if (userStore.userProfile.isValidPrivilege('UpdateArticleShipmentWindowRange') && userStore.activeCatalog?.IsShippingWindowApply === 1) {
        if ((utils.isDefined(userStore.activeCatalog?._RoleRestrictedRetailWindow) && !isEmpty(userStore.activeCatalog?._RoleRestrictedRetailWindow) && !userStore.activeCatalog?._RoleRestrictedRetailWindow.NotEditable) || (isEmpty(userStore.activeCatalog?._RoleRestrictedRetailWindow))) {
          const retailIntroMonthField = importFields.filter(field => field.SystemName === 'RetailIntroMonthId' && field.AttributeType === AttributeType.Lookup)[0]
          retailIntroMonthField.Creatable = true
          const retailExitMonthField = importFields.filter(field => field.SystemName === 'RetailExitMonthId' && field.AttributeType === AttributeType.Lookup)[0]
          retailExitMonthField.Creatable = true
        }
      }

      // add lookup source for colorpalatte attributes
      const colorPalatteAttributes = importFields.filter(field => field.AttributeType === AttributeType.ColorPalette && field.Creatable)
      if (utils.isDefined(colorPalatteAttributes) && colorPalatteAttributes.length) {
        colorPalatteAttributes.forEach((attribute) => {
          attribute.lookupSource = getColorsLookup
          attribute.isfilterCriteria = true
          attribute.lookupIdField = 'ColorName'
          attribute.lookupDisplayField = 'ColorId'
        })
      }

      importFields = importFields.filter(field => field.Creatable && field.AttributeType !== AttributeType.Calc)
    }
    else if (action === 'updateViaImportModel') {
      // make model number as unique constraint
      importInfo.uniqueConstraints = [{ name: 'Constraint 1', fields: ['ModelNumber'] }]

      for (let i = 0; i < this.fields.length; i++) {
        const field = clone(this.fields[i])

        if (field.AttributeType === AttributeType.LinkedCatalogArticleNumber || field.AttributeType === AttributeType.LookupTable || field.AttributeType === AttributeType.Calc) {
          continue
        }

        if (field.SystemName === 'ModelNumber') {
          field.Editable = true
          field.IsRequired = true
          importFields.push(field)
        }
        else if (field.Editable) {
          if (field.SystemName === 'parent_model_type') {
            // make ParentModelNumber creatable
            const parentModelNumberField = clone(this.fields.find(field => field.SystemName === 'ParentModelNumber'))
            if (parentModelNumberField) {
              parentModelNumberField.Editable = true
              importFields.push(parentModelNumberField)
            }
          }
          else if (field.AttributeType === AttributeType.ColorPalette) {
            field.lookupSource = getColorsLookup
            field.isfilterCriteria = true
            field.lookupIdField = 'ColorName'
            field.lookupDisplayField = 'ColorId'
            importFields.push(field)
          }
          else if (field.IsModelLevel) {
            field.IsRequired = false
            importFields.push(field)
          }
        }
      }
    }
    else if (action === 'importByLicensee') {
      importFields = cloneDeep(this.fields)
      importFields.filter(field => field.SystemName === 'Status')[0].Creatable = true
      if (userStore.activeCatalog!.DataSourceTypeId !== 3) {
        // enable Drop Reason column for mapping as an optional field
        const dropReasonField = importFields.filter(field => field.SystemName === 'DropReason')[0]
        dropReasonField.Creatable = true
        // make the DropReason column mandatory while validation and not mandatory while mapping
        dropReasonField.isRequiredWhileValidating = true
        const dataExistsConstraints: IDataExistConstraint = { lookupSource: getDropReasons }
        dataExistsConstraints.model = { lookupDisplayField: 'Reason' }
        dropReasonField.dataExistConstraints = dataExistsConstraints
      }
      else {
        // primary color is not updatable by child catalog
        const colorIdField = importFields.filter(field => field.SystemName === 'ColorId')[0]
        if (utils.isDefined(colorIdField)) {
          colorIdField.Creatable = false
        }
      }
      // if automatic article numbering is enabled remove article number and model number field
      // and add ArticleId and ModelId as optional fields(User should enter the id's in excel file)
      if (userStore.sellerDetails?.AutomaticNumbering === 1) {
        const articleNumberField = importFields.filter(field => field.SystemName === 'ArticleNumber')[0]
        if (utils.isDefined(articleNumberField)) {
          articleNumberField.Creatable = false
        }
        const modelNumberField = importFields.filter(field => field.SystemName === 'ModelNumber')[0]
        if (utils.isDefined(modelNumberField)) {
          modelNumberField.Creatable = false
        }
        importFields.filter(field => field.SystemName === 'ModelId')[0].Creatable = false
      }
      // make ParentModelNumber creatable
      const parentModelTypeField = importFields.filter(field => field.SystemName === 'parent_model_type')[0]
      if (parentModelTypeField && parentModelTypeField.Creatable) {
        // make ParentModelNumber creatable
        const parentModelNumberField = importFields.filter(field => field.SystemName === 'ParentModelNumber')[0]
        if (utils.isDefined(parentModelNumberField)) {
          parentModelNumberField.Creatable = true
        }
      }

      // if configured model name length then change the allowed max length to the configured value
      const configuredModelNameLength = userStore.sellerDetails?.ModelNameLength || 100
      if (configuredModelNameLength !== 100) {
        const articleNameField = importFields.filter(field => field.SystemName === 'ArticleName')[0]
        articleNameField.maxLength = configuredModelNameLength
      }

      // if Article Attrbute or Model Attribute is assigned at catalog level then make those attributes mandatory
      const articleAttribute = userStore.activeCatalog?.ArticleAttribute
      const modelAttribute = userStore.activeCatalog?.ModelAttribute
      if (utils.isDefined(articleAttribute) && articleAttribute.toString().trim() !== '') {
        const articleAttributeField = importFields.filter(field => field.SystemName.toUpperCase() === articleAttribute.toUpperCase())[0]
        if (utils.isDefined(articleAttributeField)) {
          articleAttributeField.Creatable = true
          articleAttributeField.minLength = 1
          articleAttributeField.IsRequired = true
        }
      }
      if (utils.isDefined(modelAttribute) && modelAttribute.toString().trim() !== '') {
        const modelAttributeField = importFields.filter(field => field.SystemName.toUpperCase() === modelAttribute.toUpperCase())[0]
        if (utils.isDefined(modelAttributeField)) {
          modelAttributeField.Creatable = true
          modelAttributeField.minLength = 1
          modelAttributeField.IsRequired = true
        }
      }

      // add unique constraints
      importInfo.uniqueConstraints = [{ name: 'Constraint 1', fields: [articleAttribute] }]

      // make Shipment Range Field creatable when "DummyArticleApplyRetailWindow" privilege is assigned
      if (userStore.userProfile.isValidPrivilege('UpdateArticleShipmentWindowRange') && userStore.activeCatalog?.IsShippingWindowApply === 1) {
        if ((utils.isDefined(userStore.activeCatalog?._RoleRestrictedRetailWindow) && !isEmpty(userStore.activeCatalog?._RoleRestrictedRetailWindow) && !userStore.activeCatalog?._RoleRestrictedRetailWindow.NotEditable) || (isEmpty(userStore.activeCatalog?._RoleRestrictedRetailWindow))) {
          const retailIntroMonthField = importFields.filter(field => field.SystemName === 'RetailIntroMonthId' && field.AttributeType === AttributeType.Lookup)[0]
          retailIntroMonthField.Creatable = true
          const retailExitMonthField = importFields.filter(field => field.SystemName === 'RetailExitMonthId' && field.AttributeType === AttributeType.Lookup)[0]
          retailExitMonthField.Creatable = true
        }
      }

      // make LinkedCatalogArticleNumber and lookuptable type attributes not creatable through import
      const noncreatableFields = importFields.filter(field => field.AttributeType === AttributeType.LinkedCatalogArticleNumber || field.AttributeType === AttributeType.LookupTable)
      noncreatableFields.forEach(field => field.Creatable = false)

      // add lookup source for colorpalatte attributes
      const colorPalatteAttributes = importFields.filter(field => field.AttributeType === AttributeType.ColorPalette && field.Creatable)
      if (utils.isDefined(colorPalatteAttributes) && colorPalatteAttributes.length) {
        colorPalatteAttributes.forEach((attribute) => {
          attribute.lookupSource = getColorsLookup
          attribute.isfilterCriteria = true
          attribute.lookupIdField = 'ColorName'
          attribute.lookupDisplayField = 'ColorId'
        })
      }

      importFields = importFields.filter(field => field.Creatable && field.AttributeType !== AttributeType.Calc)
    }
    else if (action === 'setSizesByLicenseeModelNumber') {
      importFields = cloneDeep(this.fields)
      const sizeScaleField = importFields.filter(field => field.SystemName === 'SizeScale')[0]
      if (utils.isDefined(sizeScaleField)) {
        sizeScaleField.Editable = true
        sizeScaleField.IsRequired = true
        const dataExistsConstraints: IDataExistConstraint = { lookupSource: getSizeScalesLookup }
        dataExistsConstraints.model = { lookupDisplayField: 'SizeScale' }
        sizeScaleField.dataExistConstraints = dataExistsConstraints
      }
      const masterSizeScaleField = importFields.filter(field => field.SystemName === 'MasterSizeScale')[0]
      if (utils.isDefined(masterSizeScaleField)) {
        masterSizeScaleField.Editable = true
        masterSizeScaleField.IsRequired = true
        const dataExistsConstraints: IDataExistConstraint = { lookupSource: getMasterSizeScalesLookup }
        dataExistsConstraints.model = { lookupDisplayField: 'SizeScale' }
        masterSizeScaleField.dataExistConstraints = dataExistsConstraints
      }
      // make creatable false to all fields except SizeScale and MasterSizeScale
      importFields.forEach((field) => {
        if (field.SystemName !== 'SizeScale' && field.SystemName !== 'MasterSizeScale' && field.Editable) {
          field.Editable = false
        }
      })
      const licenseeModelNumberField = importFields.filter(field => field.SystemName === 'LicenseeModelNumber')[0]
      if (utils.isDefined(licenseeModelNumberField)) {
        licenseeModelNumberField.Editable = true
        licenseeModelNumberField.IsRequired = true
      }

      // add unique constraints
      importInfo.uniqueConstraints = [{ name: 'Constraint 1', fields: ['LicenseeModelNumber'] }]

      importFields = importFields.filter(field => field.Editable)
    }
    else if (action === 'setPricesByLicenseeModelNumber') {
      importFields = cloneDeep(this.fields)
      const priceByLicenseeModelFields: Array<IField> = []
      const licenseeModelNumberField = importFields.filter(field => field.SystemName === 'LicenseeModelNumber')[0]
      if (utils.isDefined(licenseeModelNumberField)) {
        licenseeModelNumberField.Editable = true
        licenseeModelNumberField.IsRequired = true
      }
      priceByLicenseeModelFields.push(licenseeModelNumberField)
      const priceGroupIds: number[] = []
      userStore.activeCatalog!.CatalogPriceGroupList.forEach((priceGroup) => {
        if (priceGroup.Status) {
          priceGroupIds.push(priceGroup.Id)
          const priceField: IField = Object.assign({}, appConstants.staticFieldTemplate, {
            AttributeType: AttributeType.Decimal,
            DisplayName: `P:${priceGroup.Name} (${priceGroup.CurrencyCode})`,
            SystemName: priceGroup.Id,
            Creatable: true,
            column: -1,
            pattern: `^[+]?\\d+([.]\\d{0,${userStore.activeCatalog!.Config.NumberOfDecimalsForPrices}})?$`,
            messages: { pattern: `Invalid price value. It should be a positive number and not contain more than ${userStore.activeCatalog!.Config.NumberOfDecimalsForPrices} decimals.` },
          })
          priceByLicenseeModelFields.push(priceField)
        }
      })
      // add unique constraint
      importInfo.uniqueConstraints = [{ name: 'Constraint 1', fields: ['LicenseeModelNumber'] }]
      importInfo.atLeastOneRequiredConstraints = [
        { name: 'Constraint 1', fields: priceGroupIds },
      ]
      importInfo.validateArticleLocking = true

      importFields = priceByLicenseeModelFields
    }
    return { importInfo, modelPath: this.modelPath, fields: importFields } as IImportModel
  }
}
