import { fabric } from 'fabric'
import { v4 as guid } from 'uuid'
import type { Subscription } from 'dexie'
import { liveQuery } from 'dexie'
import { clone, pick } from 'lodash-es'
import type MyArticle from '@/models/myArticle'
import { useUserStore } from '@/store/userData'
import utils, { CancelToken } from '@/services/utils'
import appConfig from '@/services/appConfig'
import { merchConstants } from '@/models/constants'
import type CatalogDetails from '@/models/catalogDetails'

interface IMbArticleDetailsOptions extends fabric.ITextboxOptions {
  id?: string
  articleId?: number
  objectId?: string
  isRequest?: boolean
  articleNumber?: string
  customOptions: Record<string, any>
  showLabels?: boolean
  locked?: boolean
  templateObject?: boolean
  isOriginTopLeft?: boolean
}
const editableProps: Record<string, IMbObjectProp> = {
  fill: { name: 'text color', type: 'textColor' },
  backgroundColor: { name: 'backgroundColor color', type: 'color' },
  font: { name: 'font', type: 'font' },
  fontSize: { name: 'font size', type: 'fontSize' },
  textAlign: { name: 'align', type: 'textAlign' },
  fontStyle: { name: 'font style', type: 'fontStyle' },
  attributes: { name: 'attributes', type: 'attributes' },
  locked: { name: 'Lock', type: 'lock' },
  showLabels: { name: 'show labels', type: 'showLabels' },
}
// eslint-disable-next-line ts/ban-ts-comment
// @ts-expect-error
export default class MbArticleDetails extends fabric.Textbox implements IMbObject {
  public id: string
  public article?: MyArticle
  public articleId?: number
  public objectId?: string
  public isRequest?: boolean
  public showLabels: boolean
  public customOptions: Record<string, any>
  public articleNumber: string
  public type = merchConstants.objectTypes.articleDetails
  public locked: boolean
  public isOriginTopLeft: boolean
  public templateObject: boolean
  private isLoadingFirstTime: boolean = true
  public editableProps: Record<string, IMbObjectProp> = editableProps

  public actions: Record<string, IWObjectActions> = {
    selectSimilar: { action: 'selectSimilar', label: 'Select Similar', faicon: 'fa-light fa-check-double', showInSubMenu: true },
    bringFront: { action: 'bringFront', label: 'Bring to Front', faicon: 'fa-light fa-bring-front', showInSubMenu: true },
    sendBack: { action: 'sendBack', label: 'Send to Back', faicon: 'fa-light fa-send-back', showInSubMenu: true },
    group: { action: 'group', label: 'Group', faicon: 'fa-light fa-object-group', showInSubMenu: true, multiple: true },
    copy: { action: 'copy', label: 'Copy Selection', faicon: 'fa-light fa-copy', showInSubMenu: true },
    align: { action: 'align', label: 'Align', faicon: 'fa-light fa-align-justify', showInSubMenu: true },
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
  }

  public subscriptions: Array<Subscription>

  constructor(text: string, thumbnailSize: string, opt?: IMbArticleDetailsOptions, isLive: boolean = true) {
    super(text, opt)
    this.id = opt?.id || guid()
    this.articleId = opt?.articleId
    this.objectId = opt?.objectId
    this.isRequest = opt?.isRequest
    this.showLabels = utils.isDefined(opt) && utils.isDefined(opt.showLabels) ? opt.showLabels : false
    this.customOptions = opt?.customOptions ? opt?.customOptions : {}
    this.fontFamily = opt?.fontFamily || 'Times New Roman'
    this.fontSize = opt?.fontSize || merchConstants.slideArticleThumbnailFontSizes[thumbnailSize] || merchConstants.slideArticleThumbnailFontSizes.large
    this.textAlign = opt?.textAlign || 'center'
    this.articleNumber = opt?.articleNumber || ''
    this.locked = opt?.locked || false
    this.templateObject = opt?.templateObject || false
    this.editable = false
    this.subscriptions = []
    if (opt && (opt.hasOwnProperty('top') || opt.hasOwnProperty('left')) && opt.isOriginTopLeft === false) {
      this.migrateObjectTopLeft(opt)
    }
    this.isOriginTopLeft = true
    if (isLive) {
      const userStore = useUserStore()
      const catalogDetails = userStore.activeCatalog!
      if (this.isRequest && this.articleId) {
        const observable = liveQuery(async () => await appConfig.DB!.getRequestArticleById(catalogDetails, this.articleId!, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet))
        const subscription = observable.subscribe(async (article) => {
          // As discussed with Andre we dont need to set article again when we first time create the object as already we are loading the article
          if (!this.isLoadingFirstTime && article) {
            this.set('text', await MbArticleDetails.getText(article, this.showLabels, this.customOptions.articleProps, this.customOptions.separator || '\n', this.customOptions.attributePlaceHolder || '', userStore.myAttributes))
            this.dirty = true
            this.canvas?.requestRenderAll()
          }
          else {
            this.isLoadingFirstTime = false
          }
        })
        this.subscriptions.push(subscription)
      }
      else {
        const observable = liveQuery(async () => await appConfig.DB!.articles.where({ CatalogCode: catalogDetails.CatalogCode, Id: this.articleId }).toArray())
        const subscription = observable.subscribe(async ([article]) => {
          // As discussed with Andre we dont need to set article again when we first time create the object as already we are loading the article
          if (!this.isLoadingFirstTime) {
            const articles = await appConfig.DB!.buildMyArticles([article], catalogDetails, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, true)
            this.set('text', await MbArticleDetails.getText(articles[0], this.showLabels, this.customOptions.articleProps, this.customOptions.separator || '\n', this.customOptions.attributePlaceHolder || '', userStore.myAttributes))
            this.dirty = true
            this.canvas?.requestRenderAll()
          }
          else {
            this.isLoadingFirstTime = false
          }
        })
        this.subscriptions.push(subscription)
      }
    }

    this.set('text', text) // super(text, options) is not setting the text property with the text value rather it display the static value received from server

    this.on('removed', () => {
      // console.log('unsubscribing from article details observables')
      this.subscriptions.forEach((subscription) => {
        if (subscription && !subscription.closed) {
          subscription.unsubscribe()
        }
      })
    })
    this.objectCaching = false
    this.setLock(this.locked)

    this.on('selected', () => {
      this.selected = true
    })

    this.on('deselected', () => {
      this.selected = false
    })
    this.stateProperties = this.stateProperties?.concat(['showLabels', 'lock', 'attributes'])
  }

  async setProp(prop: string, value: any, ignoreHistory: boolean = false) {
    const userStore = useUserStore()
    const catalogDetails = userStore.activeCatalog!
    let customObject: Record<string, any>
    switch (prop) {
      case 'top':
        this.set('top', value.top)
        break
      case 'left':
        this.set('left', value.left)
        break
      case 'fill':
        this.set('fill', value.fill)
        break
      case 'backgroundColor':
        this.set('backgroundColor', value.backgroundColor)
        break
      case 'font':
        this.set('fontFamily', value.font)
        break
      case 'fontSize':
        this.set('fontSize', value.fontSize)
        break
      case 'textAlign':
        this.set('textAlign', value.textAlign)
        break
      case 'bold':
        this.set('fontWeight', value.bold ? 'bold' : '')
        break
      case 'italic':
        this.set('fontStyle', value.italic ? 'italic' : '')
        break
      case 'locked':
        this.set('locked', value.locked)
        this.setLock(value.locked)
        break
      case 'showLabels':
        this.set('showLabels', value.showLabels)
        this.set('text', await this.getAttributesValue(catalogDetails, this.articleId, this.objectId, this.isRequest, this.customOptions.articleProps, value.showLabels))
        break
      case 'attributes':
        customObject = clone(this.customOptions)
        customObject.articleProps = value.attributes
        this.set('customOptions', customObject)
        this.set('text', await this.getAttributesValue(catalogDetails, this.articleId, this.objectId, this.isRequest, value.attributes, this.showLabels))
        break
      case 'text':
        // value in case of set text value should be fetch from article
        this.set('text', await this.getAttributesValue(catalogDetails, this.articleId, this.objectId, this.isRequest, this.customOptions.articleProps, this.showLabels))
        break
      case 'height':
        this.set('height', value)
        break
      default:
        console.warn('Attempting to set unsupported MbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this, ignoreHistory })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'fill':
        result.textColor = this.fill
        break
      case 'backgroundColor':
        result.backgroundColor = this.backgroundColor
        break
      case 'font':
        result.font = this.fontFamily
        break
      case 'fontSize':
        result.fontSize = this.fontSize
        break
      case 'textAlign':
        result.textAlign = this.textAlign
        break
      case 'bold':
        result.bold = !!this.fontWeight
        break
      case 'italic':
        result.italic = !!this.fontStyle
        break
      case 'locked':
        result.lock = this.locked
        break
      case 'showLabels':
        result.showLabels = this.showLabels
        break
      case 'attributes':
        result.attributes = this.customOptions.articleProps
        break
      case 'text':
        result.text = this.text
        break
      default:
        console.warn('Attempting to get unsupported MbObjectProp', prop)
    }
    return result
  }

  setLock(lock: boolean) {
    this.set('lockMovementX', lock)
    this.set('lockMovementY', lock)
    this.set('lockRotation', lock)
    this.set('lockScalingFlip', lock)
    this.set('lockScalingX', lock)
    this.set('lockScalingY', lock)
    this.set('hasControls', !lock)
  }

  override toObject() {
    const customOptions = clone(this.customOptions)
    if (customOptions && customOptions.articleProps && customOptions.articleProps.length) {
      for (let index = 0; index < customOptions.articleProps.length; index++) {
        const articleProp = customOptions.articleProps[index]
        if (MbArticleDetails.customOptionsReverseMap.hasOwnProperty(articleProp)) {
          customOptions.articleProps[index] = MbArticleDetails.customOptionsReverseMap[articleProp]
        }
      }
      if (customOptions.articleProps.includes('ModelName')) {
        const index = customOptions.articleProps.indexOf('ModelName')
        customOptions.articleProps[index] = 'ArticleName'
      }
    }
    const propsToPluck = [
      'id',
      'type',
      'top',
      'left',
      'angle',
      'width',
      'height',
      'scaleX',
      'scaleY',
      'fill',
      'backgroundColor',
      'flipX',
      'flipY',
      'fontFamily',
      'fontSize',
      'fontStyle',
      'fontWeight',
      'textAlign',
      'locked',
      'templateObject',
      'showLabels',
      'articleNumber',
      'isOriginTopLeft',
      'articleId',
      'objectId',
      'isRequest',
    ]
    const returnObject = pick(this, propsToPluck)
    returnObject.customOptions = customOptions
    return returnObject
  }

  static fromObject(object: MbArticleDetails, callback?: Function) {
    // console.log('loading articleDetails fromObject', object)
    // handle existing objects
    if (object.articleId) {
      this.loadArticleDetailsById(object.articleId, object.objectId, object.isRequest, object as IMbArticleDetailsOptions).then((v) => { callback && callback(v) })
    }
  }

  static async getText(article: MyArticle, showLabels: boolean, attributes?: string[], attributesSeparator?: string, attributePlaceHolder?: string, myAttributes?: Record<string, IMyAttribute>) {
    const attributeData: any[] = []
    const userStore = useUserStore()
    if (attributes && attributes.length && utils.isDefined(myAttributes)) {
      for (const attributeSystemName of attributes) {
        const attributeObject = myAttributes[attributeSystemName]
        if (utils.isDefined(attributeObject)) {
          let val = await utils.getAttributeTypeSpecificValue(attributeObject, article, appConfig.DB, userStore.activeCatalog, userStore.priceGroups)
          if (!utils.isValidStringValue(val)) {
            val = attributePlaceHolder
          }
          let label = ''
          if (showLabels && userStore.activeCatalog) {
            const merchLabelAttributesConfigValue = userStore.activeCatalog.Config.MerchLabelAttributes
            if (merchLabelAttributesConfigValue === undefined
              || (utils.isDefined(merchLabelAttributesConfigValue) && Array.isArray(merchLabelAttributesConfigValue) && merchLabelAttributesConfigValue!.includes(attributeObject.SystemName))) {
              label = attributeObject.DisplayName
            }
          }
          attributeData.push(showLabels && label !== '' ? `${label}: ${val}` : val)
        }
      }
    }
    return attributeData.join(attributesSeparator)
  }

  static async loadArticleDetails(article: MyArticle, thumbnailSize: string, myAttributes?: Record<string, IMyAttribute>, opt?: IMbArticleDetailsOptions, isLive: boolean = true) {
    const userStore = useUserStore()
    let customProps = { articleProps: ['ArticleNumber', 'ModelName'] }
    if (userStore.activeCatalog && userStore.activeCatalog.Config.MerchDefaultArticleDetailsAttributes.length) {
      customProps = { articleProps: userStore.activeCatalog.Config.MerchDefaultArticleDetailsAttributes }
    }
    if (opt && opt.customOptions === undefined) {
      opt.customOptions = customProps
    }
    const o: IMbArticleDetailsOptions = opt || { articleId: article.Id, articleNumber: article.ArticleNumber, customOptions: customProps }
    o.articleId = article.Id
    o.objectId = article.CatalogArticleId
    o.isRequest = article._IsRequestArticle
    o.articleNumber = article.ArticleNumber
    o.showLabels = utils.isDefined(opt) && utils.isDefined(opt.showLabels) ? opt.showLabels : false
    // need to convert the article name to model name as t1 studio web support model name only
    if (opt?.customOptions && opt?.customOptions.articleProps.length) {
      for (let index = 0; index < opt.customOptions.articleProps.length; index++) {
        const articleProp = opt.customOptions.articleProps[index]
        if (this.customOptionsMap.hasOwnProperty(articleProp)) {
          opt.customOptions.articleProps[index] = this.customOptionsMap[articleProp]
        }
      }
    }
    let detailsText = ''
    if (utils.isDefined(opt) && utils.isDefined(opt.customOptions)) {
      detailsText = await this.getText(article, o.showLabels, opt.customOptions.articleProps, opt.customOptions.separator || '\n', opt.customOptions.attributePlaceHolder || '', myAttributes)
    }
    const obj = new MbArticleDetails(detailsText, thumbnailSize, o, isLive)
    return obj
  }

  static customOptionsMap = {
    'ArticleName': 'ModelName',
    '_myPrices.wholesale': '_WholesalePrice',
    '_myPrices.retail': '_RetailPrice',
    '_myPrices.outlet': '_OutletPrice',
  }

  static customOptionsReverseMap = {
    ModelName: 'ArticleName',
    _WholesalePrice: '_myPrices.wholesale',
    _RetailPrice: '_myPrices.retail',
    _OutletPrice: '_myPrices.outlet',
  }

  static async loadArticleDetailsById(articleId: number, objectId?: string, isRequest?: boolean, opt?: IMbArticleDetailsOptions, imageSize?: number) {
    const userStore = useUserStore()
    let thumbnailSize = 'large'
    if (imageSize === merchConstants.slideImageDefaultScaleFactors.medium) {
      thumbnailSize = 'medium'
    }
    else if (imageSize === merchConstants.slideImageDefaultScaleFactors.small) {
      thumbnailSize = 'small'
    }
    if (userStore && userStore.activeCatalog) {
      const catalogDetails = userStore.activeCatalog
      let res: MyArticle[] | null = null
      if (isRequest) {
        const requestArticle = await appConfig.DB!.getRequestArticleById(catalogDetails, articleId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
        if (requestArticle) {
          res = [requestArticle]
        }
      }
      else {
        res = await appConfig.DB!.getMyArticles(catalogDetails, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, [articleId], userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, true)
      }
      if (res && !(res instanceof CancelToken) && res.length > 0) {
        return await this.loadArticleDetails(res[0], thumbnailSize, userStore.myAttributes, opt)
      }
      else {
        console.warn('Failed to load article details by Id', res)
      }
    }
    console.warn('Unable to load Article Details by Id')
    return null
  }

  static updateTextPosition(textPosition, selectedObject, articleObject) {
    const groupOffset = { top: 0, left: 0, middle: 0 }
    if (!utils.isDefined(articleObject)) {
      return
    }
    if (utils.isDefined(selectedObject.group)) {
      groupOffset.top = selectedObject.group.top + selectedObject.group.height / 2
      groupOffset.left = selectedObject.group.left + selectedObject.group.width / 2
    }
    switch (textPosition) {
      case 'topLeft':
      case 'topCenter':
      case 'topRight':
        // selected objects coordinates are relative to group coordinates when they form a group, so calculated coordinates should be deducted from group coordinates
        selectedObject.top = (articleObject.aCoords.tl.y - selectedObject.height) - groupOffset.top
        selectedObject.left = articleObject.aCoords.tl.x - groupOffset.left
        if (textPosition === 'topLeft') {
          selectedObject.textAlign = 'left'
        }
        else if (textPosition === 'topCenter') {
          if (utils.isDefined(selectedObject.group)) {
            groupOffset.middle = selectedObject.group.oCoords.mt.x
          }
          // selectedObject.left = ((articleObject.aCoords.br.x + articleObject.aCoords.bl.x) / 2) - groupOffset.middle
          selectedObject.textAlign = 'center'
        }
        else {
          selectedObject.textAlign = 'right'
        }
        break
      case 'rightTop':
      case 'rightMiddle':
      case 'rightBottom':
        selectedObject.left = (articleObject.aCoords.tr.x) - groupOffset.left
        if (textPosition === 'rightTop') {
          if (utils.isDefined(selectedObject.group)) {
            groupOffset.middle = selectedObject.group.oCoords.mr.y
          }
          selectedObject.top = (articleObject.aCoords.tr.y) - groupOffset.middle
        }
        else if (textPosition === 'rightMiddle') {
          selectedObject.top = ((articleObject.aCoords.br.y + articleObject.aCoords.tr.y) / 2 - selectedObject.height / 2) - groupOffset.middle
        }
        else {
          selectedObject.top = (articleObject.aCoords.br.y - selectedObject.height) - groupOffset.top
        }
        selectedObject.textAlign = 'left'
        break
      case 'bottomLeft':
      case 'bottomCenter':
      case 'bottomRight':
        selectedObject.top = articleObject.aCoords.bl.y - groupOffset.top
        selectedObject.left = articleObject.aCoords.bl.x - groupOffset.left
        if (textPosition === 'bottomLeft') {
          selectedObject.textAlign = 'left'
        }
        else if (textPosition === 'bottomCenter') {
          if (utils.isDefined(selectedObject.group)) {
            groupOffset.middle = selectedObject.group.oCoords.mb.x
          }
          // selectedObject.left = ((articleObject.aCoords.bl.x + articleObject.aCoords.br.x) / 2) - groupOffset.middle
          selectedObject.textAlign = 'center'
        }
        else {
          selectedObject.textAlign = 'right'
        }
        break
      case 'leftTop':
      case 'leftMiddle':
      case 'leftBottom':
        selectedObject.left = (articleObject.aCoords.tl.x - selectedObject.width) - groupOffset.left
        if (textPosition === 'leftTop') {
          selectedObject.top = (articleObject.aCoords.tl.y) - groupOffset.top
        }
        else if (textPosition === 'leftMiddle') {
          if (utils.isDefined(selectedObject.group)) {
            groupOffset.middle = selectedObject.group.oCoords.ml.y
          }
          selectedObject.top = ((articleObject.aCoords.bl.y + articleObject.aCoords.tl.y) / 2 - selectedObject.height / 2) - groupOffset.middle
        }
        else {
          selectedObject.top = (articleObject.aCoords.bl.y - selectedObject.height) - groupOffset.top
        }
        selectedObject.textAlign = 'right'
        break
      default:
        // bottom left
        selectedObject.top = articleObject.aCoords.bl.y - groupOffset.top
        selectedObject.left = articleObject.aCoords.bl.x - groupOffset.left
        selectedObject.textAlign = 'left'
        break
    }
    return selectedObject
  }

  private async getAttributesValue(catalogDetails: CatalogDetails, articleId?: number, objectId?: string, isRequest?: boolean, attributes?: string[], showLabels: boolean = false) {
    const userStore = useUserStore()
    let article: MyArticle | undefined
    if (articleId != null) {
      try {
        if (isRequest) {
          article = await appConfig.DB!.getRequestArticleById(catalogDetails, articleId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
        }
        else {
          const articles = await appConfig.DB!.getMyArticles(catalogDetails, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, [articleId], userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, true)
          article = articles[0]
        }
      }
      catch (error) {
        console.warn(error)
        article = this.article
      }
    }
    else if (this.article) {
      article = this.article
    }
    return await MbArticleDetails.getText(article!, showLabels, attributes, '\n', '', userStore.myAttributes)
  }

  // migrate or update the top left of objects that had originX and originY set to center to make the originX to left and originY to top
  migrateObjectTopLeft(options) {
    // temporary fix only for VLP template TODO: find the center coordinate and use the above equation
    if (options.hasOwnProperty('angle') && options.angle === -90) {
      if (options.hasOwnProperty('top')) {
        options.top = options.top + (options.width / 2)
        this.set('top', options.top)
      }
      if (options.hasOwnProperty('left')) {
        options.left = options.left - (options.height / 4)
        this.set('left', options.left)
      }
    }
    else {
      if (options.hasOwnProperty('top')) {
        options.top = options.top - (options.height / 2)
        this.set('top', options.top)
      }
      if (options.hasOwnProperty('left')) {
        options.left = options.left - (options.width / 2)
        this.set('left', options.left)
      }
    }
  }

  static getEditableProps() {
    return editableProps
  }

  override _render(ctx: CanvasRenderingContext2D): void {
    super._render(ctx)
  }
}

const f: any = fabric
f.MbArticleDetails = MbArticleDetails
