import { clone } from 'lodash-es'
import WbTextBox from '../textBox'
import type { FrameSize } from '../frame'
import WbFrame, { frameSizes } from '../frame'
import WbArticleImage from '../articleImage'
import WbArticleDetails from '../articleDetails'
import type { IWhiteboardTemplate, IWhiteboardTemplateFileMapping, IWhiteboardTemplateOption } from './IWhiteboardTemplate'
import type CatalogDetails from '@/models/catalogDetails'
import { whiteboardConstants } from '@/models/constants'
import utils from '@/services/utils'
import type MyArticle from '@/models/myArticle'
import { AttributeType } from '@/models/catalogAttribute'
import appConfig from '@/services/appConfig'
import type CatalogPriceGroup from '@/models/catalogPriceGroup'

/* eslint-disable style/no-mixed-spaces-and-tabs */
/* eslint-disable style/no-tabs */
class Standard implements IWhiteboardTemplate {
  id = whiteboardConstants.frameTemplatesId.standard
  name = 'Standard'
  imgSize: number = 150
  imgHorSpacing: number = 40
  imgVerSpacing: number = 20
  titleHeight: number = 0
  titleFontSize: number = 27
  titlePadding: number = 5
  frameWidth: number = frameSizes.wide.width
  frameHeight: number = frameSizes.wide.height
  articleDetailsHeight: number = 0
  articleAttributes?: string[]
  groupByArticleAttributes?: string[]
  imageHeight: number = 500
  imageWidth: number = 500
  font: string = 'Arial'
  articleAttributeProp = {
    fontWeight: 'normal',
    fontSize: whiteboardConstants.frameArticleThumbnailFontSizes.small,
    textAlign: 'center',
  }

  labelHeightAsPerImageScaleFactor = {
    large: 25,
    medium: 20,
    small: 14,
  }

  imageDefaultScaleFactor: number = whiteboardConstants.generateSlideImageScaleFactor.small
  // merchLabelAttributes = []
  // this.mapOfSelectedArticleAttributesSystemNameToDisplayLabel = new Map()
  fitInOnePageProps = {
    articleAttributeMaxHeight: 0,
    maximumRowInOnePage: 4,
    maximumArticlesToConsiderForOnePage: 250,
    articleMaxHeightText: '',
    scaleX: 1,
    scaleY: 1,
  }

  needOverLapping: boolean = false
  headerMargin: number = 50
  leftMargin: number = 15
  spacingBetweenFrames = 50

  getOptions(catalog: CatalogDetails, myAttributes: Record<string, IMyAttribute>): IWhiteboardTemplateOption[] {
    const attributes: IMyAttribute[] = []
    for (const key in myAttributes) {
      attributes.push(myAttributes[key])
    }

    // TODO: Implement configurable options
    const options: IWhiteboardTemplateOption[] = []

    options.push({
      name: 'frameTitle',
      label: 'generateFrameDialog.steps.options.groupBy',
      type: 'list',
      default: [],
      required: false,
      clearable: true,
      filterable: true,
      multiple: true,
      multipleLimit: 3,
      data: attributes,
      valueProp: 'SystemName',
      displayProp: 'DisplayName',
    })

    // options.push({
    //   name: 'titleOnEachFrame',
    //   label: 'generateFrameDialog.steps.options.titleOnEachFrame',
    //   type: 'bool',
    //   default: false,
    //   required: false
    // })

    options.push({
      name: 'displayAttributes',
      label: 'generateFrameDialog.steps.options.attributesToDisplay',
      type: 'list',
      default: ['ArticleNumber'],
      required: false,
      clearable: true,
      filterable: true,
      multiple: true,
      data: attributes,
      valueProp: 'SystemName',
      displayProp: 'DisplayName',
    })

    options.push({
      name: 'sortAttributes',
      label: 'generateFrameDialog.steps.options.sortAttributes',
      type: 'list',
      default: [],
      required: false,
      clearable: true,
      filterable: true,
      multiple: true,
      data: attributes,
      valueProp: 'SystemName',
      displayProp: 'DisplayName',
    })

    // options.push({
    //   name: 'fitToOneFrame',
    //   label: 'generateFrameDialog.steps.options.fitToOneFrame',
    //   type: 'bool',
    //   default: false,
    //   required: false,
    // })

    const imageScales: IKeyLabel[] = []
    for (const key in whiteboardConstants.generateSlideImageScaleFactor) {
      imageScales.push({ key: whiteboardConstants.generateSlideImageScaleFactor[key], label: utils.capitalizeFirstLetter(key) })
    }

    options.push({
      name: 'imageScaleFactor',
      label: 'generateFrameDialog.steps.options.imageSize',
      type: 'list',
      default: whiteboardConstants.generateSlideImageScaleFactor.small,
      required: true,
      multiple: false,
      data: imageScales,
      valueProp: 'key',
      displayProp: 'label',
    })

    // TODO: Image Type
    // options.push({
    //   name: 'imageType',
    //   label: 'generateFrameDialog.steps.options.imageType',
    //   type: 'list',
    //   default: 'firstImage',
    //   required: false,
    //   multiple: false,
    // })

    const availableFrameSizes: { key: FrameSize, label: string }[] = [
      { key: 'wide', label: 'Wide' },
      { key: 'a4', label: 'A4' },
      { key: 'letter', label: 'Letter' },
    ]

    options.push({
      name: 'frameSize',
      label: 'generateFrameDialog.steps.options.frameSize',
      type: 'list',
      default: 'wide',
      required: true,
      multiple: false,
      data: availableFrameSizes,
      valueProp: 'key',
      displayProp: 'label',
    })

    options.push({
		  name: 'includeInactiveArticles',
		  label: 'generateFrameDialog.steps.options.includeInactiveArticles',
		  type: 'bool',
		  default: false,
		  required: false,
    })

    // if(utils.isValidStringValue(catalog.Config.ArIndicatorAttribute) && utils.isDefined(myAttributes[catalog.Config.ArIndicatorAttribute])) {
    //   options.push({
    //     name: 'addARIndicator',
    //     label: 'generateFrameDialog.steps.options.addARIndicator',
    //     type: 'bool',
    //     default: true,
    //     required: false,
    //   })
    // }

    // if(utils.isValidStringValue(catalog.Config.GhlIndicatorAttribute) && utils.isDefined(myAttributes[catalog.Config.GhlIndicatorAttribute])) {
    //   options.push({
    //     name: 'addGHLIndicator',
    //     label: 'generateFrameDialog.steps.options.addGHLIndicator',
    //     type: 'bool',
    //     default: true,
    //     required: false,
    //   })
    // }

    // if(utils.isValidStringValue(catalog.Config.BAndTIndicatorAttribute) && utils.isDefined(myAttributes[catalog.Config.BAndTIndicatorAttribute])) {
    //   options.push({
    //     name: 'addBAndTIndicator',
    //     label: 'generateFrameDialog.steps.options.addBAndTIndicator',
    //     type: 'bool',
    //     default: true,
    //     required: false,
    //   })
    // }

    return options
  }

  getFileMapping(): IWhiteboardTemplateFileMapping[] {
    const mapping: IWhiteboardTemplateFileMapping[] = []

    mapping.push({
      name: 'articleNumber',
      column: 'ArticleNumber',
      type: 'articleNumber',
      label: 'generateFrameDialog.steps.mapping.articleNumber',
      required: true,
      autoMap: ['article', 'articlenumber', 'article number', 'artnbr', 'style color code'],
    })

    mapping.push({
      name: 'frameTitle',
      column: 'Frame Title',
      label: 'generateFrameDialog.steps.mapping.groupBy',
      type: 'list',
      required: false,
      autoMap: [],
      multiple: true,
      multipleLimit: 3,
    })

    mapping.push({
      name: 'articlePrice',
      column: 'ArticlePrice',
      type: 'list',
      label: 'generateFrameDialog.steps.mapping.articlePrice',
      required: false,
      autoMap: [],
    })

    // mapping.push({
    // 	name: 'fileName',
    // 	column: 'Filename',
    // 	type: 'list',
    // 	label: 'generateFrameDialog.steps.mapping.fileName',
    // 	required: false,
    // 	autoMap: ['filename', 'file name']
    // })

    return mapping
  }

  // TODO: Need to work on loading custom fonts
  async generate(catalog: CatalogDetails, indexedLinkedCatalogDetails: Record<string, CatalogDetails>, articlesData: MyArticle[], options: Record<string, any>, myAttributes: Record<string, IMyAttribute>, myUsername: string, startPosition: IPoint, excelData?: Record<string, Record<string, string[]>>, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup, isExporting: boolean = false): Promise<IWbObject[]> {
    const data = new Array<IGroupItem>()
    const indexedKeys = {} as Record<string, any>
    let isCustomArticlePrice: boolean = false
    if (utils.isDefined(excelData)) {
      articlesData.forEach((article) => {
        data.push({
          group: utils.isDefined(excelData[article.ArticleNumber].frameTitle) && excelData[article.ArticleNumber].frameTitle.length ? (excelData[article.ArticleNumber].frameTitle).join('>') : 'Frame',
          article,
          articlePrice: utils.isDefined(excelData[article.ArticleNumber].articlePrice) && excelData[article.ArticleNumber].articlePrice[0] ? Number(excelData[article.ArticleNumber].articlePrice[0]) : 0,
        })
        if (!isCustomArticlePrice && utils.isDefined(excelData[article.ArticleNumber].articlePrice) && excelData[article.ArticleNumber].articlePrice.length) {
          isCustomArticlePrice = true
        }
      })
    }
    else {
      await articlesData.forEach(async (article) => {
        if (options.frameTitle.length) {
          const keys = await this.getKeys(options.frameTitle, article, catalog, indexedLinkedCatalogDetails, myAttributes, myUsername, retailPg, wholesalePg, outletPg)
          for (let index = 0; index < keys.length; index++) {
            const key = keys[index]
            indexedKeys[key.toLowerCase()] = key
            data.push({ group: key.toLowerCase(), article })
          }
        }
        else {
          indexedKeys.frame = 'frame'
          data.push({ group: 'Frame', article })
        }
      })
    }
    return await this.buildTemplate(catalog, data, options, myAttributes, indexedKeys, startPosition, isExporting, retailPg, wholesalePg, outletPg, isCustomArticlePrice)
  }

  async getKeys(frameTitle: Array<any>, article: MyArticle, catalog: CatalogDetails, indexedLinkedCatalogDetails: Record<string, CatalogDetails>, myAttributes: Record<string, IMyAttribute>, myUsername: string, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup): Promise<any[]> {
    let keys: any[] = []
    if (myAttributes) {
      for (let index = 0; index < frameTitle.length; index++) {
        const key: any[] = []
        const property = frameTitle[index]
        const emptyValuePlaceHolder = '[Blank]'
        const valueAtPath = article[property]
        if (utils.isDefined(valueAtPath)) {
          if (myAttributes[property] && (myAttributes[property].AttributeType === AttributeType.Date || myAttributes[property].AttributeType === AttributeType.DateOption || myAttributes[property].AttributeType === AttributeType.DateTime)) {
            key.push(valueAtPath.toString().trim() !== '' ? utils.formatDate(valueAtPath) : emptyValuePlaceHolder)
          }
          else if (property.toLowerCase() === 'shipmentstartdate' || property.toLowerCase() === 'shipmentenddate') {
            key.push(valueAtPath.toString().trim() !== '' ? utils.formatDateUTC(valueAtPath) : emptyValuePlaceHolder)
          }
          else if (property.toLowerCase() === '_retailprice' || property.toLowerCase() === '_wholesaleprice'
            || property.toLowerCase() === '_outletprice') {
            // find the width of price with label and currency code
            const articleValue = property.toLowerCase() === '_retailprice' ? article?._RetailPrice : property.toLowerCase() === '_wholesaleprice' ? article?._WholeSalePrice : article._OutletPrice
            const valueAtPath = utils.formatPrice(property.toLowerCase() === '_retailprice' ? retailPg : property.toLowerCase() === '_wholesaleprice' ? wholesalePg : outletPg, Number(articleValue), catalog?.Config.ShowPriceThousandsSeparated)
            key.push(utils.isDefined(valueAtPath) ? valueAtPath : emptyValuePlaceHolder)
          }
          else if (myAttributes[property] && myAttributes[property].AttributeType === AttributeType.MultiValue) {
            if (Array.isArray(valueAtPath)) {
              valueAtPath.forEach((value) => {
                key.push(value)
              })
            }
            else {
              key.push(valueAtPath.toString().trim() !== '' ? valueAtPath : emptyValuePlaceHolder)
            }
          }
          else if (property.toLowerCase() === '_favoritetags') {
            article._FavoriteTags.forEach((tagObject) => {
              key.push(tagObject.Tag)
            })
          }
          else if (property.toLowerCase() === '_crds') {
            article._DeliveryDates.forEach((deliveryDate) => {
              if (deliveryDate.Status === 1) {
                // key.push(utils.formatDate(new Date(deliveryDate.CustomerRequiredDate))) //todo where i will find the date
              }
            })
          }
          else if (property.toLowerCase() === '_firstdeliverydate') {
            const date = article.getFirstDeliveryDate(catalog)
            key.push(date && date.toString().trim() !== '' ? utils.formatDate(date) : emptyValuePlaceHolder)
          }
          else if (myAttributes[property] && (myAttributes[property].AttributeType === AttributeType.LinkedCatalogArticleNumber)) {
            const val = await utils.getAttributeTypeSpecificValue(myAttributes[property], article)
            key.push(val.toString() !== '' ? val : emptyValuePlaceHolder)
            // todo need to support ArticleNumber
          }
          else if (myAttributes[property] && (myAttributes[property].AttributeType === AttributeType.ArticleNumber)) {
            const articles = await appConfig.DB!.getMyArticlesById(catalog, indexedLinkedCatalogDetails, myAttributes, myUsername, Number(valueAtPath), retailPg, wholesalePg, outletPg)
            if (articles.length !== 0) {
              key.push(articles[0].ArticleNumber)
            }
            else {
              key.push(emptyValuePlaceHolder)
            }
          }
          else {
            key.push(valueAtPath.toString().trim() !== '' ? valueAtPath : emptyValuePlaceHolder)
          }
        }
        else {
          key.push(emptyValuePlaceHolder)
        }
        if (index > 0) {
          const combined: any[] = []
          for (let i = 0; i < keys.length; i++) {
            for (let l = 0; l < key.length; l++) {
              combined.push(`${keys[i]} > ${key[l]}`)
            }
          }
          keys = combined
        }
        else {
          keys.push(...key)
        }
      }
    }
    return keys
  }

  async buildTemplate(catalog: CatalogDetails, data: Array<IGroupItem>, options: Record<string, any>, myAttributes: Record<string, IMyAttribute>, indexedKeys: Record<string, any>, startPosition: IPoint, isExporting: boolean, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup, isCustomArticlePrice?: boolean): Promise<IWbObject[]> {
    this.resetProps()
    const objects: IWbObject[] = []
    let currentFrameChildren: string[] = []
    const attributes = options.displayAttributes
    const sortAttributes = options.sortAttributes
    const frameSize = options.frameSize
    if (utils.isDefined(options.imageScaleFactor)) {
      this.imageDefaultScaleFactor = options.imageScaleFactor
      this.imgSize = (this.imageWidth * this.imageDefaultScaleFactor) / 100
      if (this.imageDefaultScaleFactor === whiteboardConstants.generateSlideImageScaleFactor.small) {
        this.articleAttributeProp.fontSize = whiteboardConstants.frameArticleThumbnailFontSizes.small
      }
      else if (this.imageDefaultScaleFactor === whiteboardConstants.generateSlideImageScaleFactor.medium) {
        this.articleAttributeProp.fontSize = whiteboardConstants.frameArticleThumbnailFontSizes.medium
      }
      else {
        this.articleAttributeProp.fontSize = whiteboardConstants.frameArticleThumbnailFontSizes.large
      }
    }
    this.changePropsAsPerFrameSize(frameSize)
    const groups = new Map<string, MyArticle[]>()
    let ttlSlides = 0
    let frameLeft = startPosition.x ? startPosition.x : 0
    const frameTop = startPosition.y ? startPosition.y : 0
    // if we have `_favoriteTags` as slide title/group by, then we will not do the sorting by _favoriteTags
    options.frameTitle.forEach((frameTitle) => {
      if ((myAttributes[frameTitle] && myAttributes[frameTitle].AttributeType === AttributeType.MultiValue) || frameTitle === '_favoriteTags') {
        const slideTitleIndex = sortAttributes.indexOf(frameTitle)
        sortAttributes.splice(slideTitleIndex, 1)
      }
    })
    // Parse lines into groups
    for (const dataItem of data) {
      if (!groups.has(dataItem.group)) {
        groups.set(dataItem.group, [])
      }
      const group = groups.get(dataItem.group)!
      const articleClone = clone(dataItem.article)
      // Find max height of attributes of Articles
      const articleArticleHeight = await this.getArticleAttributesHeight(articleClone, attributes, myAttributes, isExporting)
      articleClone._maxHeight = articleArticleHeight.maxHeight ? articleArticleHeight.maxHeight : 0
      articleClone._wbObject = articleArticleHeight.object!
      // if (attributes.length && options.fitToOneFrame && this.fitInOnePageProps.articleAttributeMaxHeight < articleClone._maxHeight) {
      // 	this.fitInOnePageProps.articleAttributeMaxHeight = articleClone._maxHeight
      // 	this.fitInOnePageProps.articleMaxHeightText = articleArticleHeight.textForWidth
      // }
      // for customPrice
      if (isCustomArticlePrice && utils.isDefined(dataItem.articlePrice)) {
			  articleClone._templateCustomPrice = dataItem.articlePrice
        // find height of custom price
        const customPriceHeight = this.getTextBoxHeight(dataItem.articlePrice.toString(), this.articleAttributeProp.fontSize, this.font, this.imgSize)
        articleClone._isCustomPriceHeight = customPriceHeight || 10
      }
      else {
        articleClone._isCustomPriceHeight = 0
      }

      if (options.sortAttributes.length) {
        // add ArticleNumber on top of what user select for sorting
        if (!sortAttributes.includes('ArticleNumber')) {
          sortAttributes.push('ArticleNumber')
        }
        if (articleClone) {
          articleClone._sortOrderString = this.generateSortOrderString(articleClone, options.sortAttributes, myAttributes).toLowerCase()
          utils.insertSorted(articleClone, group, (a, b) =>
            utils.comparer(a, b, ['_sortOrderString']))
        }
      }
      else {
        group.push(articleClone)
      }
    }
    // if (options.fitToOneFrame) {
    // 	//find maximum in all grouos
    // 	let maximumArticlesInGroup = [...groups.entries()].reduce((a, e) => e[1].length > a[1].length ? e : a)
    // 	let eachCellHeight = Math.floor((this.frameHeight - 60) / this.fitInOnePageProps.maximumRowInOnePage)
    // 	this.findTheScalingFactor(maximumArticlesInGroup[1].length > this.fitInOnePageProps.maximumArticlesToConsiderForOnePage ? this.fitInOnePageProps.maximumArticlesToConsiderForOnePage : maximumArticlesInGroup[1].length, this.fitInOnePageProps.scaleX, this.fitInOnePageProps.scaleY, this.frameWidth, this.frameHeight - (titleOnEachPage ? 60 : 0))
    // 	this.imgSize = this.imgSize * this.fitInOnePageProps.scaleX
    // 	//find the fontsize of the articleDetails
    // 	if (attributes.length) {
    // 		// from the cell height - image size and calculate the font size so that article max height can be accomodated in the remaining space
    // 		this.articleAttributeProp.fontSize = this.findFontSizeDependingOnTheHeight(this.fitInOnePageProps.scaleX, this.articleAttributeProp.fontSize, eachCellHeight)
    // 	}
    // }
    if (options.fitToOneFrame) {
      //  // Articles per row
      //  groups.forEach((articleList, slideName) => {
      //    let currentSlideNbr = 1
      //    let currentObjects: IWbObject[] = [] // reassing places
      //    let slideNameValue =  indexedKeys[slideName] ?  indexedKeys[slideName] : slideName
      //    // Add title
      //    if(titleOnEachPage) {
      //      this.titleHeight = this.getTextBoxHeight(slideNameValue , 51, this.font, this.frameWidth)
      //      currentObjects.push(this.createTitleObj(slideNameValue))
      //    } else {
      //      this.titleHeight = 0
      //    }
      //    let remainingHeight = this.frameHeight - this.titleHeight
      //    let currentTop = this.titleHeight
      //    let maximumNumberOCardsInOneRow =  Math.floor(this.frameWidth/ (this.imgSize  + this.imgHorSpacing ))
      //    let maximumNumberOfCardsInOneColumn = Math.floor((remainingHeight)/ (this.imgSize + this.fitInOnePageProps.articleAttributeMaxHeight + this.imgVerSpacing))
      //    let totalArticlesCanAccommodateInOnePage = maximumNumberOCardsInOneRow * maximumNumberOfCardsInOneColumn
      //    //it means number of cards is greater than it can be accommodated in one page - need to change the sizing of the cards to accommodate
      //    let overLappingWidthFactor = 0
      //    let overlappingObject = {canAccommodate: true} as Record<string, any>
      //    let conisderedArticlesLength = articleList.length > this.fitInOnePageProps.maximumArticlesToConsiderForOnePage ? this.fitInOnePageProps.maximumArticlesToConsiderForOnePage : articleList.length
      //    if(conisderedArticlesLength > totalArticlesCanAccommodateInOnePage) {
      //      overlappingObject = this.findNeedsOverLapping(this.frameWidth , remainingHeight, conisderedArticlesLength )
      //      if(overlappingObject.canAccommodate) {
      //        maximumNumberOCardsInOneRow = Math.floor((this.frameWidth)/ (this.imgSize + this.imgHorSpacing))
      //      } else if(overlappingObject.canAccommodateWithoutSpacing) {
      //        maximumNumberOCardsInOneRow = Math.floor((this.frameWidth) / this.imgSize )
      //      } else {
      //        //needs overLapping
      //        overLappingWidthFactor = overlappingObject.overlappingWidthPercentage
      //        maximumNumberOCardsInOneRow = overlappingObject.maxNumberOfCardsCanBeInOneRow
      //      }
      //    }
      //    let currentLeft = 0
      //    articleList = articleList.slice( 0, conisderedArticlesLength)
      //    articleList.forEach( (article,index) => {
      //      if(index === 0) {
      //        currentTop += this.imgSize / 2
      //      }
      //      if(index % maximumNumberOCardsInOneRow === 0  && index !== 0) {
      //        currentTop += this.imgSize + this.fitInOnePageProps.articleAttributeMaxHeight
      //      }
      //      if(index !== 0 && index % maximumNumberOCardsInOneRow !== 0) {
      //        currentLeft = currentLeft - overLappingWidthFactor
      //      }
      //      if(index === 0 || index % maximumNumberOCardsInOneRow === 0) {
      //        currentLeft = 0
      //      }
      //      currentLeft += this.imgSize / 2

      //      // let imageType = options.imageType
      //      // if(article._Assets.length) {
      //      //   if(options.imageType !== 'newestImage' && options.imageType !== 'firstImage') {
      //      //     let imageTypeExists = article._Assets.find(image => image.imageName === options.imageType )
      //      //     if(!utils.isDefined(imageTypeExists)) {
      //      //       imageType = catalogData.defaultImageType
      //      //     }
      //      //   }
      //      // } else {
      //      //   imageType = ''
      //      // }
      //      currentObjects.push(
      //        this.createImage(currentTop, currentLeft, article.ArticleId, imageType, this.imgSize / this.imageWidth, this.imgSize / this.imageHeight)
      //      )
      //      if(attributes.length != 0 ) {
      //        currentObjects.push(
      //          this.createLabel(currentTop + this.imgSize/2 , currentLeft - this.imgSize / 2, this.imgSize, attributes, article)
      //        )
      //      }
      //      if (isCustomArticlePrice) {
      //        currentObjects.push(this.createTextObject(article, currentTop, index % maximumNumberOCardsInOneRow))
      //      }
      //      currentLeft += this.imgSize/2 + (overlappingObject.canAccommodate ? this.imgHorSpacing : 0)
      //    })
      //    ttlSlides++
      //  //   if(folder) {
      //  //     const createSlidePromise = this.createSlide(folder, `${slideNameValue} - ${_.padStart(currentSlideNbr.toString(), 3, '0')}`,
      //  //       currentObjects.slice(), this.imageDefaultScaleFactor, options
      //  //     )
      //  //     .catch(error => {
      //  //       // TODO:Merch@andre ask andre what needs to be done when failed creating slide due to system unable to create slide objects file
      //  //       console.warn(`Create slide failed, unable to create slide object file, \n${error}`)
      //  //     })
      //  //     promises.push(createSlidePromise)
      //  //   }
      //    currentSlideNbr++
      //    currentObjects = []
      //  })
      //  return Promise.all(promises)
      //  .then(() => {
      //      return ttlSlides
      //  })
    }
    else {
      // Articles per row
      const artPerRow = Math.floor(this.frameWidth / (this.imgSize + this.imgHorSpacing))
      for (const groupObject of groups) {
        const articleList = groupObject[1]
        const frameName = groupObject[0]
        let groupArticleCounter = 0
        let rowMaxHeight = 0
        let currentImgInRow = 0
        const frameNameValue = indexedKeys[frameName] ? indexedKeys[frameName] : frameName
        // Add title
        if (options.frameTitle.length) {
				  const titleObject = this.createTitleObj(frameNameValue, frameLeft, frameTop)
				  this.titleHeight = titleObject.height ? titleObject.height : 0
				  objects.push(isExporting ? titleObject.toObject() : titleObject)
				  currentFrameChildren.push(titleObject.id)
        }
        else {
				  this.titleHeight = 0
        }
        let remainingHeight = this.frameHeight - this.titleHeight - this.headerMargin
        let currentTop = this.titleHeight + this.headerMargin + frameTop
        while (articleList.length > groupArticleCounter) {
          remainingHeight = remainingHeight - rowMaxHeight
          currentTop += rowMaxHeight
          const articlesToDraw = articleList.slice(groupArticleCounter, groupArticleCounter + artPerRow)
          groupArticleCounter += artPerRow
          rowMaxHeight = 0
          if (articlesToDraw && articlesToDraw.length) {
            for (let i = 0; i < articlesToDraw.length; i++) {
              const maxHeight = Number(articlesToDraw[i]._maxHeight)
              const isCustomPriceHeight = Number(articlesToDraw[i]._isCustomPriceHeight)
              if (rowMaxHeight < (maxHeight + this.imgSize + this.imgVerSpacing + isCustomPriceHeight)) {
                rowMaxHeight = maxHeight + this.imgSize + this.imgVerSpacing + isCustomPriceHeight
              }
            }
          }
          // move to new page if row cannot accomodate also if row max height is greater thn page then also add in the same page dont create new page
          if (remainingHeight < rowMaxHeight && groupArticleCounter !== artPerRow) {
            remainingHeight = this.frameHeight - this.titleHeight - this.headerMargin
            currentTop = this.titleHeight + this.headerMargin + frameTop
            ttlSlides++
            objects.push(new WbFrame(options.frameSize, { children: currentFrameChildren, name: `${frameNameValue}${options.frameTitle.length === 0 ? ttlSlides : ''}`, left: frameLeft, top: frameTop, sortOrder: ttlSlides }))
            frameLeft += isExporting ? 0 : this.frameWidth + this.spacingBetweenFrames
            currentFrameChildren = []
            if (options.frameTitle.length) {
              const titleObject = this.createTitleObj(frameNameValue, frameLeft, frameTop)
              this.titleHeight = titleObject.height ? titleObject.height : 0
              objects.push(isExporting ? titleObject.toObject() : titleObject)
              currentFrameChildren.push(titleObject.id)
						  }
            else {
              this.titleHeight = 0
            }
          }
          for (const article of articlesToDraw) {
            // let imageType = options.imageType
            // if(article._Assets.length) {
            //     if(options.imageType !== 'newestImage' && options.imageType !== 'firstImage') {
            //     let imageTypeExists = article._images.find(image => image.imageName === options.imageType )
            //     if(!utils.isDefined(imageTypeExists)) {
            //         imageType = catalogData.defaultImageType
            //     }
            //     }
            // } else {
            //     imageType = ''
            // }
            const left = this.leftMargin + frameLeft + currentImgInRow * (this.imgSize + this.imgHorSpacing)
            const articleImageObj = await this.createImage(currentTop, left, article, isExporting, this.imageDefaultScaleFactor)
            objects.push(articleImageObj)
            currentFrameChildren.push(articleImageObj.id)
            if (attributes.length !== 0) {
              const left = this.leftMargin + frameLeft + currentImgInRow * (this.imgSize + this.imgHorSpacing)
              const top = currentTop + this.imgSize + this.imgVerSpacing
              if (article._wbObject instanceof WbArticleDetails) {
                const articleObject: WbArticleDetails = article._wbObject
                articleObject.setProp('top', { top })
                articleObject.setProp('left', { left })
                objects.push(isExporting ? articleObject.toObject() : articleObject)
                currentFrameChildren.push(articleObject.id)
              }
            }
            if (isCustomArticlePrice) {
              const articleCustomPriceObj = this.createTextObject(article, left, currentTop)
              objects.push(articleCustomPriceObj)
              currentFrameChildren.push(articleCustomPriceObj.id)
            }
            currentImgInRow++
            if (currentImgInRow >= artPerRow) {
              currentImgInRow = 0
            }
          }
        }
        if (objects.length > 0) {
          ttlSlides++
          // nme conditional based on title on each slide
          objects.push(new WbFrame(options.frameSize, { children: currentFrameChildren, name: `${frameNameValue}${options.frameTitle.length === 0 ? ttlSlides : ''}`, left: frameLeft, top: frameTop, sortOrder: ttlSlides }))
          frameLeft += isExporting ? 0 : this.frameWidth + this.spacingBetweenFrames
          currentFrameChildren = []
        }
      }
    }
    return objects
  }

  generateSortOrderString(article: MyArticle, sortOrderAttributes: string[], myAttributes: Record<string, IMyAttribute>) {
    let sortOrderString = ''
    sortOrderAttributes.forEach((sortOrderAttribute) => {
      let attributeValue = article[sortOrderAttribute]
      if (sortOrderAttribute.toLowerCase() === '_retailprice'
        || sortOrderAttribute.toLowerCase() === '_wholesaleprice'
        || sortOrderAttribute.toLowerCase() === '_outletprice') {
        const articlePriceDetails = article[sortOrderAttribute]
        attributeValue = articlePriceDetails != null ? articlePriceDetails : ''
      }
      else if (sortOrderAttribute === '_favoriteTags') {
        const tagNames: string[] = []
        article._FavoriteTags.forEach((tagObject) => {
          tagNames.push(tagObject.Tag)
        })
        // we will take the first sorted result in order to maintain the sorting by article number
        const sortedTagNames = tagNames.sort()
        // adding '~' to sort the articles with tags at last
        // TODO: may need to implement separate comparer
        attributeValue = sortedTagNames.length > 0 ? `~${sortedTagNames[0]}` : ''
      }
      else if (myAttributes[sortOrderAttribute] && myAttributes[sortOrderAttribute].AttributeType === AttributeType.MultiValue) {
        const multiValues = article[sortOrderAttribute] || []
        const sortedValues = Array.isArray(multiValues) ? multiValues.sort() : []
        attributeValue = sortedValues.length > 0 ? `~${sortedValues[0]}` : ''
        //   } else if (sortOrderAttribute.toLowerCase() === '_firstdeliverydate') {
        // attributeValue = attributeValue && attributeValue.toString().trim() !== '' ? attributeValue.getTime() : ''
      }
      if (typeof attributeValue === 'number') {
        // for correct comparison of number string
        const numberParts = attributeValue.toString().split('.')
        sortOrderString += numberParts[0].toString().padStart(10, '0')
        if (numberParts[1] != null) {
          sortOrderString += `.${numberParts[1].toString().padEnd(5, '0')}`
        }
      }
      else {
        sortOrderString += attributeValue || ''
      }
    })
    return sortOrderString
  }

  async getArticleAttributesHeight(article: MyArticle, articleAttributes: string[], myAttributes: Record<string, IMyAttribute>, isExporting: boolean = false) {
    // let textForWidth = ''
    // for (let attributeIndex = 0; attributeIndex < articleAttributes.length; attributeIndex++) {
    // 	let attributeLabel = myAttributes ? myAttributes[articleAttributes[attributeIndex]].DisplayName : articleAttributes[attributeIndex]//this.mapOfSelectedArticleAttributesSystemNameToDisplayLabel.get(articleAttributes[attributeIndex]) todo//
    // 	if (utils.isDefined(attributeLabel)) {
    // 		textForWidth += attributeLabel + ': '
    // 	}
    // 	if (utils.isDefined(article[articleAttributes[attributeIndex]])) {
    // 		textForWidth += article[articleAttributes[attributeIndex]]
    // 	} else if (articleAttributes[attributeIndex].toLowerCase() === '_retailprice' || articleAttributes[attributeIndex].toLowerCase() === '_wholesaleprice' ||
    // 		articleAttributes[attributeIndex].toLowerCase() === '_outletprice') {
    // 		//find the width of price with label and currency code
    // 		let articleValue = articleAttributes[attributeIndex].toLowerCase() === '_retailprice' ? article?._RetailPrice : articleAttributes[attributeIndex].toLowerCase() === '_wholesaleprice' ? article?._WholeSalePrice : article._OutletPrice
    // 		const valueAtPath = utils.formatPrice(articleAttributes[attributeIndex].toLowerCase() === '_retailprice' ? retailPg : articleAttributes[attributeIndex].toLowerCase() === '_wholesaleprice' ? wholesalePg : outletPg, Number(articleValue), catalog?.Config.ShowPriceThousandsSeparated)
    // 		textForWidth += utils.isDefined(valueAtPath) ? valueAtPath : 0
    // 	}
    // 	if (attributeIndex !== (articleAttributes.length - 1)) {
    // 		textForWidth += "\n"
    // 	}
    // }
    const opt = {
      left: 0,
      top: 0,
      width: this.imgSize,
      attributes: articleAttributes,
      showLabels: false,
      fontFamily: this.font,
      fontSize: this.articleAttributeProp.fontSize,
      fontWeight: this.articleAttributeProp.fontWeight,
      textAlign: this.articleAttributeProp.textAlign,
      lock: false,
      fill: '#000000',
    }
    const object = await WbArticleDetails.loadArticleDetails(article, myAttributes, opt, !isExporting)
    return { maxHeight: object?.height, object }

    // return {textForWidth , maxHeight: this.getTextBoxHeight(textForWidth, this.articleAttributeProp.fontSize, this.font, this.imgSize)}
  }

  changePropsAsPerFrameSize(frameSize: FrameSize) {
    this.frameWidth = frameSizes[frameSize].width
    this.frameHeight = frameSizes[frameSize].height
  }

  resetProps() {
    this.fitInOnePageProps = {
      articleAttributeMaxHeight: 0,
      maximumRowInOnePage: 4,
      maximumArticlesToConsiderForOnePage: 250,
      articleMaxHeightText: '',
      scaleX: 1,
      scaleY: 1,
    }
  }

  // // fit to one page related
  // findTheScalingFactor(totalCards: number, scaleX: number, scaleY: number, width: number, height: number) {
  // 	const eachCardWidth = this.imgSize
  // 	const eachCardHeight = this.fitInOnePageProps.articleAttributeMaxHeight + this.imgSize
  // 	this.fitInOnePageProps.scaleX = scaleX - 0.02
  // 	this.fitInOnePageProps.scaleY = scaleY - 0.02

  // 	const newWidth = eachCardWidth * this.fitInOnePageProps.scaleX
  // 	const hewHeight = eachCardHeight * this.fitInOnePageProps.scaleY
  // 	const maximumNumberOCardsInOneRow = Math.floor(width / newWidth)
  // 	const maximumNumberOfCardsInOneColumn = Math.floor((height) / hewHeight)
  // 	const totalCardsCanAccommodateInOnePage = maximumNumberOCardsInOneRow * maximumNumberOfCardsInOneColumn
  // 	if (totalCardsCanAccommodateInOnePage >= totalCards || scaleX <= 0.4) {
  // 		if (scaleX < 0.4) {
  // 			this.needOverLapping = true
  // 			this.fitInOnePageProps.scaleX = 0.4
  // 			this.fitInOnePageProps.scaleY = 0.4
  // 		}
  // 	}
  //   else {
  // 		this.findTheScalingFactor(totalCards, this.fitInOnePageProps.scaleX, this.fitInOnePageProps.scaleY, width, height)
  // 	}
  // }

  // findFontSizeDependingOnTheHeight(scaling: number, fontSize: number, maxHeight: number) {
  // 	const textBoxHeight = this.getTextBoxHeight(this.fitInOnePageProps.articleMaxHeightText, fontSize, this.font, this.imgSize)
  // 	if (maxHeight >= textBoxHeight) {
  // 		this.fitInOnePageProps.articleAttributeMaxHeight = textBoxHeight
  // 		return fontSize
  // 	}
  //   else {
  // 		fontSize = fontSize - 1
  // 		return this.findFontSizeDependingOnTheHeight(scaling, fontSize, maxHeight)
  // 	}
  // }

  // findNeedsOverLapping(width: number, height: number, numberOfCards: number) {
  // 	const overlappingObject = {} as Record<string, any>
  // 	overlappingObject.canAccommodate = false
  // 	overlappingObject.canAccommodateWithoutSpacing = false
  // 	overlappingObject.overlappingWidthPercentage = 0
  // 	overlappingObject.maxNumberOfRowsCanBe = 0
  // 	overlappingObject.maxNumberOfCardsCanBeInOneRow = 0
  // 	const articleMaxHeight = this.fitInOnePageProps.articleAttributeMaxHeight + this.imgSize
  // 	const numberOfROws = Math.floor(height / articleMaxHeight)
  // 	const numbersOfCardsInEachROw = Math.floor(width / (this.imgSize + this.imgHorSpacing))
  // 	const numbersOfCardsInEachROwWithoutSpacing = Math.floor(width / this.imgSize)
  // 	if (numberOfROws * numbersOfCardsInEachROw >= numberOfCards) {
  // 		overlappingObject.canAccommodate = true
  // 	}
  //   else if (numberOfROws * numbersOfCardsInEachROwWithoutSpacing >= numberOfCards) {
  // 		overlappingObject.canAccommodateWithoutSpacing = true
  // 	}
  //   else {
  // 		// find the overlapping
  // 		overlappingObject.maxNumberOfRowsCanBe = Math.round(height / articleMaxHeight)
  // 		overlappingObject.maxNumberOfCardsCanBeInOneRow = Math.ceil(numberOfCards / overlappingObject.maxNumberOfRowsCanBe)
  // 		overlappingObject.overlappingWidthPercentage = this.imgSize - ((width - this.imgSize) / (overlappingObject.maxNumberOfCardsCanBeInOneRow <= 1 ? 1 : overlappingObject.maxNumberOfCardsCanBeInOneRow - 1))
  // 	}
  // 	return overlappingObject
  // }

  getTextBoxHeight(text: string, fontSize: number, font: string, width: number) {
    const textBox = new WbTextBox(text, {
      top: 0,
      left: 0,
      width,
      fontFamily: font,
      fontSize,
    })
    return textBox.height
  }

  // objects functions
  createTitleObj(title, left, top) {
    return new WbTextBox(title, {
      top: Number(this.titlePadding) + top,
      left,
      width: this.frameWidth,
      fill: '#000000',
      fontFamily: this.font,
      fontSize: this.titleFontSize,
      fontStyle: 'normal',
      fontWeight: 'normal',
      textAlign: 'center',
      lock: false,
    })
  }

  async createImage(top, left, article, isExporting, imageScaleFactor) {
    const scaleValue = { scale: imageScaleFactor }
    if (isExporting) {
      return await WbArticleImage.getJsonObject(article, 500, 500, scaleValue, { top, left, catalogCode: article.CatalogCode, articleId: article.Id, objectId: article.CatalogArticleId, isRequest: article._IsRequestArticle })
    }
    else {
      return await WbArticleImage.loadArticleImage(article, 500, 500, { top, left, catalogCode: article.CatalogCode, articleId: article.Id, objectId: article.CatalogArticleId, isRequest: article._IsRequestArticle }).then((newImg) => {
        newImg.setProp('scale', scaleValue)
        return newImg
      })
    }
  }

  createTextObject(article: MyArticle, left: number, currentTop: number) {
    const text = article._templateCustomPrice != null ? article._templateCustomPrice.toString() : ''
    return new WbTextBox(text, {
      top: Number(currentTop)
      + Number(this.imgSize) + Number(this.imgVerSpacing)
      + Number(article._maxHeight),
      left,
      width: this.imgSize,
      height: Number(article._isCustomPriceHeight),
      fill: '#000000',
      fontFamily: this.font,
      fontSize: this.articleAttributeProp.fontSize,
      fontStyle: 'normal',
      fontWeight: 'normal',
      textAlign: 'center',
      lock: false,
    })
  }
}

interface IGroupItem {
  group: string
  article: MyArticle
  articlePrice?: number
}

export default new Standard()
