<template>
  <div class="container relative flex flex-col w-full h-full overflow-hidden">
    <loader v-if="loading" />
    <div v-else class="container flex flex-col w-full h-full">
      <!-- HEADER -->
      <tx-form-header class="flex flex-col mx-2 my-[30px] grow-0 shrink-0 justify-center header" :title="t('articleDetails.actions.editSizes')" :show-header="showHeader" />

      <!-- BODY -->
      <div class="px-10 mt-4 alerts">
        <tx-alert :show="uniqueModelArticles.length > 5 && !noCommonSizeScales" :text="t('editSizes.backgroundJobMessage')" />
        <tx-alert :show="noCommonSizeScales" type="error" :text="t('editSizes.noCommonSizeScalesAvailable')" />
        <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
      </div>
      <div v-if="totalUpdateSizesRequest > 0 && !hasError" class="px-10 mt-4 mb-2">
        <tx-progress v-model="updateSizesProgress" :title="updateSizesProgressTitle" />
      </div>

      <!-- FORM -->
      <div class="w-full h-full px-10 overflow-auto">
        <div v-for="attribute in visibleAttributes" :key="attribute.SystemName" class="mb-4">
          <attribute-editor
            v-model="modelFormModel[attribute.SystemName]"
            :form="modelFormModel"
            :articles="props.articles"
            :attribute="attribute"
            :disabled="attribute.ReadOnly"
            :required="attribute.IsRequired"
            :should-consider-obsolete-model-value="true"
            :show-external-change-management-info="attribute.ExternalChangeManagementURL != null"
            :show-update-seasonless-attribute-warning="false"
            :should-allow-reset-existing-value="attribute.SystemName !== 'MasterSizeScaleId'"
            @reset-size-scale="resetSizeScaleAndSizes"
            @change="onAttributeEditorChange(attribute, $event)"
          />
        </div>
      </div>

      <!-- FOOTER -->
      <tx-form-footer
        class="flex flex-row justify-end flex-shrink-0 flex-nowrap"
        :primary-text="t('general.update')" :primary-disabled="isUpdateButtonDisabled || totalUpdateSizesRequest > 0"
        @primary-click="onUpdate" @secondary-click="onCancel"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import useArticleLocalDataUpdater from '../composables/articleLocalDataUpdater'
import AttributeEditor from '@/shared/components/AttributeEditor.vue'
import Loader from '@/shared/components/Loader.vue'
import type MyArticle from '@/models/myArticle'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'
import appConfig from '@/services/appConfig'
import useErrorMessage from '@/shared/composables/errorMessage'
import { AttributeType } from '@/models/catalogAttribute'
import { appConstants } from '@/models/constants'
import { updateModelSizeScale, updateModelSizeScaleBgJob } from '@/api/t1/size'
import { useArticleFormHelper } from '@/shared/composables/articleFormHelper'
import { useUserStore } from '@/store/userData'
import useEventsBus from '@/shared/composables/eventBus'
import TxFormHeader from '@/shared/components/forms/TxFormHeader.vue'
import utils from '@/services/utils'
import Job from '@/models/job'
import { useNotificationStore } from '@/store/notification'
import TxProgress from '@/shared/components/TxProgress.vue'

interface IFormItem {
  MasterSizeScaleId: number | null | undefined
  SizeScaleId: number | null | undefined
  Sizes: string | ''
}

interface IProps {
  showHeader?: boolean
  articles: MyArticle[]
}
const props = withDefaults(defineProps<IProps>(), { showHeader: true })

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated', articles: MyArticle[]): void
}>()

const { t } = useI18n()
const { errorMessage, hasError } = useErrorMessage()
const userStore = useUserStore()
const { getArticlesMaxStateDetails, skipRestrictingPropertyUpdateBasedOnArticleState } = useArticleFormHelper()
const notificationStore = useNotificationStore()
const { refreshLocalArticlesData } = useArticleLocalDataUpdater()
const { emitter } = useEventsBus()

const loading = ref<boolean>(false)
const modelFormModel = reactive<IFormItem>({
  MasterSizeScaleId: null,
  SizeScaleId: null,
  Sizes: '',
})
const updateSizeScaleBgFailed = ref('')
interface BulkSizeScaleRequest {
  ModelId: number
  SizeScaleId: number
}
const totalUpdateSizesRequest = ref(0)
const updatedSizesCount = ref(0)
const uniqueModelArticleModelNumberArray: Array<string> = []
const uniqueModelArticles: MyArticle[] = []
const bulkSizeScaleRequest: BulkSizeScaleRequest[] = []
const noCommonSizeScales = ref(false)

const updateSizesProgress = computed(() => Math.round((updatedSizesCount.value / totalUpdateSizesRequest.value) * 100))
const updateSizesProgressTitle = computed(() => t('editSizes.updatedSizesProgressTitle') + (totalUpdateSizesRequest.value ? ` (${updatedSizesCount.value} / ${totalUpdateSizesRequest.value})` : ''))
const doesMasterSizeScaleExists = props.articles.some(article => article.MasterSizeScaleId !== null)
const visibleAttributes = computed(() => {
  const maxArticleStateRank = getArticlesMaxStateDetails(props.articles, !!userStore.sellerDetails?.IsSizeScaleByStyle)
  const visibleAttributes: IMyAttribute[] = []
  const lockedBasedOnArticleState = (maxArticleStateRank == null || !maxArticleStateRank.IsSizeScaleEditable) && !skipRestrictingPropertyUpdateBasedOnArticleState
  const masterSizeScaleField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
    SystemName: 'MasterSizeScaleId',
    DisplayName: t('general.masterSizeScale'),
    Creatable: true,
    AttributeType: AttributeType.MasterSizeScale,
    ReadOnly: lockedBasedOnArticleState || noCommonSizeScales.value || doesMasterSizeScaleExists,
    ExternalChangeManagementURL: lockedBasedOnArticleState && appConfig.stateLockingExternalChangeManagementURLForSizeScale != null && appConfig.stateLockingExternalChangeManagementURLForSizeScale.toString().trim().length
      ? appConfig.stateLockingExternalChangeManagementURLForSizeScale
      : undefined,
  })
  const sizeScaleField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
    SystemName: 'SizeScaleId',
    DisplayName: t('general.sizeScale'),
    Creatable: true,
    AttributeType: AttributeType.SizeScale,
    ReadOnly: lockedBasedOnArticleState,
    ExternalChangeManagementURL: lockedBasedOnArticleState && appConfig.stateLockingExternalChangeManagementURLForSizeScale != null && appConfig.stateLockingExternalChangeManagementURLForSizeScale.toString().trim().length
      ? appConfig.stateLockingExternalChangeManagementURLForSizeScale
      : undefined,
  })
  const sizeField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
    SystemName: 'Sizes',
    DisplayName: t('general.sizes', 2),
    Creatable: true,
    AttributeType: AttributeType.MultiValue,
    ReadOnly: true,
  })
  visibleAttributes.push(masterSizeScaleField)
  visibleAttributes.push(sizeScaleField)
  visibleAttributes.push(sizeField)
  return visibleAttributes
})

const isUpdateButtonDisabled = computed(() => (modelFormModel.SizeScaleId == null || visibleAttributes.value.every(visibleAttribute => visibleAttribute.ReadOnly)))

// WATCH
watch(() => props.articles, init)

// LIFECYCLE HOOKS
onMounted(() => init())

// METHODS
async function init() {
  // pre fill the form
  let haveCommonMasterSizeScale = true
  let haveCommonSizeScale = true
  const commonMasterSizeScaleId = props.articles[0].MasterSizeScaleId
  const commonSizeScaleId = props.articles[0].SizeScaleId
  for (let i = 1; i < props.articles.length; i++) {
    if (!utils.haveEqualStringValue(commonMasterSizeScaleId, props.articles[i].MasterSizeScaleId)) {
      haveCommonMasterSizeScale = false
    }
    if (!utils.haveEqualStringValue(commonSizeScaleId, props.articles[i].SizeScaleId)) {
      haveCommonSizeScale = false
    }
    if (!haveCommonMasterSizeScale) {
      noCommonSizeScales.value = true
      break
    }
  }
  if (haveCommonMasterSizeScale) {
    modelFormModel.MasterSizeScaleId = commonMasterSizeScaleId
    if (haveCommonSizeScale) {
      modelFormModel.SizeScaleId = commonSizeScaleId
    }
  }
  modelFormModel.Sizes = modelFormModel.SizeScaleId ? userStore.sizeScales[modelFormModel.SizeScaleId]?.Sizes || '' : ''
  // find unique Model Articles and remove them from articles array
  props.articles.forEach((selectedArticle) => {
    if (!uniqueModelArticleModelNumberArray.includes(selectedArticle.ModelNumber)) {
      uniqueModelArticleModelNumberArray.push(selectedArticle.ModelNumber)
      uniqueModelArticles.push(selectedArticle)
    }
  })
}

function resetSizeScaleAndSizes() {
  modelFormModel.SizeScaleId = null
  modelFormModel.Sizes = ''
}

function onAttributeEditorChange(attribute: IMyAttribute, val) {
  if (attribute.SystemName === 'SizeScaleId') {
    modelFormModel.Sizes = ''
    const assignedSizeScale = userStore.sizeScales[val]
    if (assignedSizeScale != null) {
      modelFormModel.Sizes = assignedSizeScale.Sizes
    }
  }
}

async function onUpdate() {
  if (userStore.activeCatalog?.CatalogCode && modelFormModel.SizeScaleId) {
    // if uniqueModelArticle length is less than or equal to 5 then we will call single API to update size scale
    if (uniqueModelArticles.length <= 5) {
      totalUpdateSizesRequest.value = uniqueModelArticles.length
      updatedSizesCount.value = 0
      const updatedArticles: MyArticle[] = []
      const failedArticles: string[] = []
      // call API one by one after the API call is completed to avoid deadlock at API server side
      let processedArticleCount = 0
      const processNextArticle = async () => {
        if (processedArticleCount < uniqueModelArticles.length) {
          const article = uniqueModelArticles[processedArticleCount]
          try {
            await updateModelSizeScale(userStore.activeCatalog!.CatalogCode, article.ModelId, { SizeScaleId: modelFormModel.SizeScaleId! })
            updatedArticles.push(article)
            updatedSizesCount.value++
          }
          catch (error) {
            console.error(error)
            failedArticles.push(article.ModelNumber)
          }
          finally {
            processedArticleCount++
            await processNextArticle()
          }
        }
        else {
          // once sizes updated
          if (updatedArticles.length) {
            const updatePromises: Promise<any>[] = []
            updatedArticles.forEach((article) => {
              updatePromises.push(refreshLocalArticlesData(true, article.ModelId, undefined, false))
            })
            await Promise.all(updatePromises)
            emitter('catalogDataUpdated', { source: 'SizeScale' })
            emit('updated', updatedArticles)
          }
          if (failedArticles.length) {
            errorMessage.value = t('editSizes.failedUpdateSizeScales', { models: failedArticles.join(', ') })
          }
        }
      }
      processNextArticle() // recursive call
    }
    else {
      // if uniqueModelArticle length more than 5 then we will call background job
      uniqueModelArticles.forEach((selectedArticle) => {
        bulkSizeScaleRequest.push({ ModelId: selectedArticle.ModelId, SizeScaleId: modelFormModel.SizeScaleId! })
      })
      updateSizeScaleBgFailed.value = ''
      await updateModelSizeScaleBgJob(userStore.activeCatalog!.AccountId, userStore.activeCatalog!.AttributeGroupId, bulkSizeScaleRequest)
        .then(async (res) => {
          if (userStore.activeCatalog && res.data) {
            const job = new Job({
              Id: res.data.Id,
              CatalogCode: userStore.activeCatalog.CatalogCode,
              Type: 'editSizes',
              Status: res.data.Status,
              UserId: userStore.userProfile.Id,
              CreatedDate: new Date(),
            })
            await appConfig.DB!.jobs.put(job)
            const message = t('editSizes.jobSuccess')
            notificationStore.addNotification({ message, type: 'Success' })
            onCancel() // close
          }
        })
        .catch((e) => {
          console.error(e)
          updateSizeScaleBgFailed.value = t('general.unexpectedError')
        })
        .finally()
    }
  }
}

function onCancel() {
  emit('cancel')
}
</script>
