<template>
  <div v-for="option in selectedTemplateOptions" :key="option.name" class="w-full my-2">
    <tx-switch
      v-if="option.type === 'bool'" v-model="modelValue[option.name]" :label="t(option.label)" :required="option.required"
      :disabled="disableOptions[option.name]" :errors="v$[option.name]?.$errors" @change="$event => onOptionChange(option, $event)"
    />
    <tx-input
      v-else-if="option.type === 'number'" v-model="modelValue[option.name]" type="number" :label="t(option.label)" :required="option.required"
      :clearable="option.clearable" :min="option.min" :max="option.max" :disabled="disableOptions[option.name]" :errors="v$[option.name]?.$errors" @change="$event => onOptionChange(option, $event)"
    />
    <tx-select
      v-else-if="option.type === 'list'" v-model="modelValue[option.name]" :label="t(option.label)" :data="getListData(option)"
      :value-prop="option.valueProp" :display-prop="option.displayProp" :required="option.required" :clearable="option.clearable"
      :filterable="option.filterable" :multiple-values="option.multiple" :multiple-limit="option.multipleLimit" :disabled="disableOptions[option.name]"
      :errors="v$[option.name]?.$errors" @change="$event => onOptionChange(option, $event)"
    />
    <tx-input
      v-else v-model="modelValue[option.name]" :label="t(option.label)" :required="option.required" :clearable="option.clearable"
      :disabled="disableOptions[option.name]" :errors="v$[option.name]?.$errors" @change="$event => onOptionChange(option, $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { clone } from 'lodash-es'
import useVuelidate from '@vuelidate/core'
import { createI18nMessage, helpers, maxValue, minValue, required, requiredUnless } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxSwitch from '@/shared/components/TxSwitch.vue'
import type { IWhiteboardTemplateOption } from '@/modules/whiteboard/services/templates/IWhiteboardTemplate'
import type { IMerchTemplateOption } from '@/modules/merch/services/templates/IMerchTemplate'
import templates from '@/modules/whiteboard/services/templates'
import merchTemplates from '@/modules/merch/services/templates'
import pdfTemplates from '@/modules/export/services/pdfTemplates'
import { merchConstants, whiteboardConstants } from '@/models/constants'
import utils from '@/services/utils'
import { useUserStore } from '@/store/userData'

import type { IPdfTemplateOption } from '@/modules/export/services/pdfTemplates/IPdfTemplate'

const props = defineProps<{
  templateId: number
  showIncludeInactiveArticles?: boolean
  currentStep?: number
  validationStep?: number
  format: string
  isMerch?: boolean
  showOneSlidePerStyle?: boolean
  source?: string
}>()

const emit = defineEmits<{
  (e: 'update', val: Record<string, any>): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const withI18nMessage = createI18nMessage({ t })

const disableOptions = ref<Record<string, boolean>>({})
const modelValue = ref<Record<string, any>>({})
const selectedTemplateOptions = computed<(IWhiteboardTemplateOption | IPdfTemplateOption | IMerchTemplateOption)[]>(() => {
  let options: (IWhiteboardTemplateOption | IPdfTemplateOption | IMerchTemplateOption)[] = []
  if (props.format === 'ppt') {
    const currentTemplates = props.isMerch ? merchTemplates : templates
    if (currentTemplates && currentTemplates[props.templateId] && userStore.activeCatalog && userStore.myAttributes) {
      options = currentTemplates[props.templateId].getOptions(userStore.activeCatalog, userStore.myAttributes)
      if (!props.showIncludeInactiveArticles) {
        options = options.filter(o => o.name !== 'includeInactiveArticles')
      }
      if (!props.showOneSlidePerStyle) {
        options = options.filter(o => o.name !== 'generateOneSlidePerStyle')
      }
      if (props.source && props.source !== 'excel') {
        options = options.filter(o => o.name !== 'sortByExcelSequence')
      }
      if (props.source && props.source !== 'list' && props.source !== 'modelList') {
        options = options.filter(o => o.name !== 'sortByListSequence')
      }
      setOptionDefaultValues(options)
    }
  }
  else {
    if (pdfTemplates[props.templateId]) {
      options = pdfTemplates[props.templateId].getOptions()
    }
    setOptionDefaultValues(options)
  }

  return options
})

const rules = computed(() => {
  const result: Record<string, any> = {}
  if (props.currentStep === props.validationStep) {
    for (const option of selectedTemplateOptions.value) {
      result[option.name] = {}
      if (option.required) {
        result[option.name].required = withI18nMessage(required)
      }
      if (option.min) {
        result[option.name].minValue = withI18nMessage(minValue(option.min))
      }
      if (option.max) {
        result[option.name].maxValue = withI18nMessage(maxValue(option.max))
      }
    }
    if (props.format === 'ppt' && props.templateId === whiteboardConstants.frameTemplatesId.visualLineBuilder) {
      result.verticalAttributes.requiredUnless = helpers.withMessage(t('generateFrameDialog.steps.options.selectRowOrColumn'), requiredUnless(
        (modelValue.value.horizontalAttributes && modelValue.value.horizontalAttributes.length > 0)))

      result.horizontalAttributes.requiredUnless = helpers.withMessage(t('generateFrameDialog.steps.options.selectRowOrColumn'), requiredUnless(
        (modelValue.value.verticalAttributes && modelValue.value.verticalAttributes.length > 0)))
    }
  }
  return result
})

const v$ = useVuelidate(rules, modelValue.value)

function reset() {
  for (const option of selectedTemplateOptions.value) {
    if (utils.isDefined(option.default)) {
      modelValue.value[option.name] = clone(option.default)
    }
  }
  emit('update', modelValue.value)
}

function setOptionDefaultValues(options: (IWhiteboardTemplateOption | IPdfTemplateOption | IMerchTemplateOption)[]) {
  for (const option of options) {
    if (utils.isDefined(option.default)) {
      modelValue.value[option.name] = clone(option.default)
      if (option.disabled) {
        disableOptions.value[option.name] = true
      }
    }
  }
  emit('update', modelValue.value)
}

function getListData(option: IWhiteboardTemplateOption | IPdfTemplateOption | IMerchTemplateOption) {
  if (props.format === 'ppt' && props.templateId === whiteboardConstants.frameTemplatesId.visualLineBuilder && option.name === 'displayAttributes') {
    if (modelValue.value && modelValue.value.groupByModel) {
      return option.data ? option.data.filter(data => data.IsModelLevel) : []
    }
    else {
      return option.data ? option.data.filter(data => !data.IsSKU) : []
    }
  }
  else {
    return option.data ? option.data.filter(data => !data.IsSKU) : []
  }
}

function onOptionChange(option: IWhiteboardTemplateOption | IPdfTemplateOption | IMerchTemplateOption, val: any) {
  if (props.format === 'ppt' && option.name === 'fitToOneFrame') {
    disableOptions.value.imageScaleFactor = val === true
  }
  if (props.format === 'ppt' && props.templateId === whiteboardConstants.frameTemplatesId.visualLineBuilder) {
    if (option.name === 'horizontalAttributes') {
      disableOptions.value.numberOfColumns = !(val && val.length > 0)
    }
    else if (option.name === 'groupBy') {
      disableOptions.value.frameTitle = val && val.length > 0
    }
    else if (option.name === 'frameTitle') {
      disableOptions.value.groupBy = utils.isValidStringValue(val)
    }
    else if (option.name === 'sortAttributes') {
      disableOptions.value.sortOrder = !(val && val.length > 0)
    }
    else if (option.name === 'groupByModel') {
      !val ? modelValue.value.displayAttributes = clone(option.default) : modelValue.value.displayAttributes = []
    }
  }
  else if (props.templateId === merchConstants.slideGenTemplatesId.uaStandard) {
    if (option.name === 'isOneModelPerPage' && val) {
      disableOptions.value.includeAllVRAngles = false
    }
    else {
      disableOptions.value.includeAllVRAngles = true
    }
  }
  if (v$.value[option.name]) {
    v$.value[option.name].$touch()
  }
  emit('update', modelValue.value)
}

defineExpose({
  disableOptions,
  reset,
})
</script>
