import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Chart, registerables } from 'chart.js'
import axios from 'axios'
import VueResizeObserver from 'vue-resize-observer'
import { faAlignCenter, faAlignJustify, faAlignLeft, faAlignRight, faAngleDoubleRight, faAngleDown, faArrowLeftLong, faArrowPointer, faArrowRightFromBracket, faArrowsMaximize, faBars, faBarsSort, faBell, faBinoculars, faBookOpenCover, faBringFront, faCalendar, faCartShopping, faChalkboard, faCheck, faCheckDouble, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faCircle, faCirclePlus, faClapperboardPlay, faCloudArrowDown, faCloudArrowUp, faColumns, faComment, faComments, faCopy, faDatabase, faDistributeSpacingHorizontal, faDistributeSpacingVertical, faDownload, faEdit, faEllipsisStroke, faEllipsisStrokeVertical, faEllipsisVertical, faExclamation, faExclamationTriangle, faFile, faFileArrowUp, faFileDoc, faFileExcel, faFileExport, faFileImport, faFilePdf, faFilePen, faFilePowerpoint, faFileText, faFileWord, faFileXmark, faFileZipper, faFilter, faFilterList, faFloppyDisk, faFolder, faFolderPlus, faFrame, faGalleryThumbnails, faGear, faGroupArrowsRotate, faHandPointer, faHorizontalRule, faImage, faImages, faInfoCircle, faLayerGroup, faLink, faLock, faLockOpen, faMagnifyingGlass, faMagnifyingGlassMinus, faMagnifyingGlassPlus, faMinimize, faMinus, faMoneyCheckDollarPen, faNoteSticky, faObjectGroup, faObjectUngroup, faObjectsAlignBottom, faObjectsAlignTop, faPaperPlane, faPaperPlaneTop, faPaste, faPenCircle, faPenSquare, faPencil, faPlus, faRectangle, faRectangleXmark, faReflectHorizontal, faRefresh, faRotateLeft, faRotateRight, faRows, faSendBack, faShapes, faShare, faShareFromSquare, faShirt, faSidebar, faSitemap, faSort, faSortDown, faSortUp, faSquareMinus, faSquarePlus, faSquareSliders, faSquareSlidersVertical, faStar, faTable, faTelescope, faText, faThumbsDown, faThumbsUp, faTrash, faTrashCan, faTriangle, faUpload, faUser, faUserChart, faUserGroup, faUsersRays, faVideo, faWarning, faWaveSine, faXmark } from '@fortawesome/pro-light-svg-icons'
import onVisible from './directives/onVisible'
import tooltip from './directives/tooltip'
import dragScroll from './directives/dragscroll'
import router from './router'
import net from './services/net'
import workerWrapper from './services/workerWrapper'
import auth from './services/auth'
import i18n from './i18n'
import type { AppConfig } from './services/appConfig'
import appConfig from './services/appConfig'
import '@google/model-viewer/dist/model-viewer'
import './styles/index.css'
import './styles/tooltip.css'
import App from './App.vue'

/* import specific icons */

/* add icons to the library */
library.add(faSort, faSortUp, faSortDown, faFilter, faTable, faEllipsisVertical, faRotateLeft, faRotateRight, faFileExport, faFilterList, faXmark, faDatabase, faBookOpenCover, faGroupArrowsRotate, faBell, faMagnifyingGlass, faShirt, faTelescope, faArrowRightFromBracket, faChevronUp, faChevronDown, faChevronLeft, faChevronRight, faUsersRays, faChalkboard, faPlus, faMinus, faSquarePlus, faSquareMinus, faFolderPlus, faTrash, faTrashCan, faCheck, faEdit, faPencil, faPaperPlaneTop, faRefresh, faBinoculars, faAngleDown, faArrowsMaximize, faMinimize, faRectangleXmark, faCopy, faDownload, faStar, faImage, faImages, faInfoCircle, faBarsSort, faSitemap, faColumns, faRows, faGalleryThumbnails, faVideo, faFile, faFilePdf, faFileExcel, faFileWord, faFileDoc, faFilePowerpoint, faFileText, faFileZipper, faSidebar, faGear, faShare, faShareFromSquare, faFileXmark, faAlignLeft, faAlignCenter, faAlignRight, faAlignJustify, faComment, faComments, faLock, faLockOpen, faEllipsisStrokeVertical, faEllipsisStroke, faBringFront, faSendBack, faCheckDouble, faLink, faArrowPointer, faHandPointer, faText, faNoteSticky, faShapes, faFrame, faUpload, faClapperboardPlay, faCloudArrowUp, faCloudArrowDown, faCirclePlus, faAngleDoubleRight, faArrowLeftLong, faCartShopping, faFloppyDisk, faBars, faExclamation, faPaperPlane, faCalendar, faExclamationTriangle, faFolder, faFloppyDisk, faUserChart, faObjectGroup, faObjectUngroup, faUserGroup, faRectangle, faTriangle, faCircle, faHorizontalRule, faSquareSliders, faObjectsAlignTop, faReflectHorizontal, faObjectsAlignBottom, faDistributeSpacingHorizontal, faDistributeSpacingVertical, faSquareSlidersVertical, faPenSquare, faPenCircle, faUser, faPaste, faShare, faMagnifyingGlassPlus, faMagnifyingGlassMinus, faLayerGroup, faThumbsUp, faThumbsDown, faFileImport, faWarning, faFilePen, faFileArrowUp, faWaveSine, faMoneyCheckDollarPen)

// Load App Config
let config = {} as AppConfig

const p = import.meta.env.DEV
  ? axios.get('/config.local.json').catch(() => {
    return axios.get('/config.json')
  })
  : axios.get('/config.json')

p
  .then((res) => {
    config = res.data
  })
  .catch((err) => {
    console.warn('Failed to load configuration, attempting to get from local storage', err)
    const res = localStorage.getItem('config')
    if (!res) {
      console.error('Unable to load configuration')
    }
    else {
      config = JSON.parse(res)
    }
  })
  .finally(() => {
    if (config && config.AuthUrl) {
      // Set the config in localStorage
      localStorage.setItem('config', JSON.stringify(config))
      // Initialize AppConfig
      appConfig.init(config)
      // Initialize Net
      net.init(config)
      // Initialize DataWorker
      workerWrapper.sendAsync({ id: '', action: 'Init', content: config })
      // Initialize Auth
      auth.init(config)

      // Start App
      const app = createApp(App)

      app.use(i18n)
      if (config.I18nLocale !== i18n.global.locale.value) {
        i18n.global.locale.value = config.I18nLocale
      }
      app.use(router)
      app.use(createPinia())
      app.use(VueResizeObserver)
      app.directive('onVisible', onVisible)
      app.directive('tooltip', tooltip)
      app.component('font-awesome-icon', FontAwesomeIcon)
      app.directive('dragScroll', dragScroll)

      Chart.register(...registerables)

      app.mount('#app')
    }
  })
