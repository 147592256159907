<template>
  <div v-show="modelValue" ref="refFindAndReplaceDialog" class="absolute z-[2000] w-96 top-2 right-2 bg-grey-light rounded-lg shadow-toolbar overflow-hidden">
    <div class="flex w-full px-4 pt-2 border-b bg-primary">
      <div class="text-xl font-medium leading-6 text-center text-white cursor-move grow" @mousedown="onMouseDown">
        {{ t('findAndReplaceDialog.title') }}
      </div>
      <tx-button class="text-white" type="icon" faicon="fa-light fa-xmark" @click="doClose" />
    </div>
    <div class="flex w-full p-4">
      <!-- Inputs -->
      <div class="w-full mr-1">
        <tx-input v-model="form.findWhat" class="my-1" :label="t('findAndReplaceDialog.findWhat')" clearable />
        <tx-input v-model="form.replaceWith" class="my-1" :label="t('findAndReplaceDialog.replaceWith')" clearable />
        <!-- Match Objects -->
        <div class="w-64 h-32 p-1 overflow-y-auto border rounded">
          <p v-if="!matchObjects.length">
            {{ t('general.emptyList') }}
          </p>
          <ul v-else tabindex="-1" role="listbox" class="w-full leading-8">
            <li
              v-for="(obj, index) in matchObjects" :key="obj.id" tabindex="0"
              :class="{ 'bg-primary text-on-primary': selectedMatchObjectIndex === index }" class="relative flex items-center h-8 pl-1 cursor-pointer hover:bg-primary hover:text-on-primary"
              @click="onMatchObjectSelected(index)"
            >
              <div class="text-base truncate border-b grow border-grey">
                <span>{{ obj.type.substring(2) }}</span>
                <span> -> </span>
                <span>{{ obj.getProp('text').text }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- Actions -->
      <div class="flex flex-col w-32 p-1 ml-1 border rounded">
        <tx-button class="my-1" type="default" :text="t('findAndReplaceDialog.find')" height="30px" @click="doFind" />
        <tx-button class="my-1" type="default" :text="t('findAndReplaceDialog.findNext')" :disabled="!matchObjects.length" height="30px" @click="doFindNext" />
        <tx-button class="my-1" type="default" :text="t('findAndReplaceDialog.replace')" :disabled="!matchObjects.length || disabledReplaceBtn" height="30px" @click="doReplace" />
        <tx-button class="my-1" type="default" :text="t('findAndReplaceDialog.replaceAll')" :disabled="!matchObjects.length" height="30px" @click="doReplaceAll" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { onKeyStroke } from '@vueuse/core'
import { computed, reactive, ref, watch } from 'vue'
import WbTextBox from '../services/textBox'
import WbArticleDetails from '../services/articleDetails'
import WbSticky from '../services/sticky'
import type Whiteboard from '../services/whiteboard'
import WbModelDetails from '../services/modelDetails'
import TxInput from '@/shared/components/TxInput.vue'
import TxButton from '@/shared/components/TxButton.vue'
import { whiteboardConstants } from '@/models/constants'
import utils from '@/services/utils'

interface IProps {
  modelValue: boolean
  whiteboard?: Whiteboard
  loading?: boolean
}
const props = withDefaults(defineProps<IProps>(), { loading: false })

const emit = defineEmits<{
  (e: 'update:modelValue', val: boolean): void
}>()

let pos1 = 0
let pos2 = 0
let pos3 = 0
let pos4 = 0
const refFindAndReplaceDialog = ref<HTMLElement>()
const form = reactive<{ findWhat: string, replaceWith: string }>({
  findWhat: '',
  replaceWith: '',
})
const matchObjects = ref<IWbObject[]>([])
const selectedMatchObjectIndex = ref<number>(0)
const selectedMatchObjectTextStartIndex = ref<number>(0)

const { t } = useI18n()

const disabledReplaceBtn = computed(() => selectedMatchObjectIndex.value >= matchObjects.value.length
  || matchObjects.value[selectedMatchObjectIndex.value].type === whiteboardConstants.objectTypes.articleDetails
  || matchObjects.value[selectedMatchObjectIndex.value].type === whiteboardConstants.objectTypes.modelDetails,
)

function onMouseDown(e: MouseEvent) {
  pos3 = e.clientX
  pos4 = e.clientY
  document.addEventListener('mouseup', stopDrag)
  document.addEventListener('mousemove', doDrag)
}

function doDrag(e: MouseEvent) {
  e.preventDefault()

  pos1 = pos3 - e.clientX
  pos2 = pos4 - e.clientY

  const newTop = refFindAndReplaceDialog.value!.offsetTop - (pos2)
  const newLeft = refFindAndReplaceDialog.value!.offsetLeft - (pos1)

  pos3 = e.clientX
  pos4 = e.clientY

  // Get the dialog dimensions
  const dialogRect = refFindAndReplaceDialog.value!.getBoundingClientRect()
  const dialogWidth = dialogRect.width
  const dialogHeight = dialogRect.height

  // Get viewport dimensions
  const viewportWidth = window.innerWidth
  const viewportHeight = window.innerHeight

  // Calculate boundaries
  const minTop = 0 // Header should remain visible, so top should not go below 0
  const maxTop = viewportHeight - dialogHeight
  const minLeft = 0
  const maxLeft = viewportWidth - dialogWidth

  // new position within boundaries
  const topWithInViewport = Math.min(Math.max(newTop, minTop), maxTop)
  const leftWithInViewport = Math.min(Math.max(newLeft, minLeft), maxLeft)

  refFindAndReplaceDialog.value!.style.top = `${topWithInViewport}px`
  refFindAndReplaceDialog.value!.style.left = `${leftWithInViewport}px`
}

function stopDrag() {
  document.removeEventListener('mouseup', stopDrag)
  document.removeEventListener('mousemove', doDrag)
}

onKeyStroke('Escape', () => {
  if (props.modelValue) {
    doClose()
  }
})

function doClose() {
  resetMatchObjects()
  emit('update:modelValue', false)
}

function doFind() {
  resetMatchObjects()
  if (props.whiteboard && utils.isDefined(form.findWhat) && utils.isValidStringValue(form.findWhat)) {
    const objs = props.whiteboard.canvas.getObjects() as Array<IWbObject>
    for (let i = 0; i < objs.length; i++) {
      if (objs[i].type === whiteboardConstants.objectTypes.textBox || objs[i].type === whiteboardConstants.objectTypes.articleDetails
        || objs[i].type === whiteboardConstants.objectTypes.modelDetails || objs[i].type === whiteboardConstants.objectTypes.sticky) {
        const content: string | undefined = objs[i].getProp('text').text
        if (utils.isDefined(content) && content.toLowerCase().includes(form.findWhat.toLowerCase())) {
          matchObjects.value.push(objs[i])
        }
      }
    }
    doFindNext()
  }
}

function onMatchObjectSelected(index: number) {
  resetSelectionStyles(matchObjects.value[selectedMatchObjectIndex.value])
  selectedMatchObjectTextStartIndex.value = 0
  selectedMatchObjectIndex.value = index
  doFindNext()
}

function doFindNext() {
  while (selectedMatchObjectIndex.value < matchObjects.value.length) {
    const obj = matchObjects.value[selectedMatchObjectIndex.value]
    const text: string = obj.getProp('text').text
    let found = false
    let i = selectedMatchObjectTextStartIndex.value
    for (; i < text.length; ++i) {
      if (text.substring(i, i + form.findWhat.length).toLowerCase() === form.findWhat.toLowerCase()) {
        found = true
        selectedMatchObjectTextStartIndex.value = i
        break
      }
    }

    resetSelectionStyles(obj)
    if (found && props.whiteboard) {
      const start = selectedMatchObjectTextStartIndex.value
      const end = start + form.findWhat.length
      if (obj instanceof WbTextBox || obj instanceof WbArticleDetails || obj instanceof WbModelDetails) {
        obj.setSelectionStyles({ fill: '#2196f3', fontStyle: 'italic' }, start, end)
      }
      selectedMatchObjectTextStartIndex.value++
      props.whiteboard.canvas.discardActiveObject()
      const zoomLevel = props.whiteboard.canvas.getZoom()
      const objWidth = (obj.width || 0) * (obj.scaleX || 0)
      const objHeight = (obj.width || 0) * (obj.scaleY || 0)
      const newLeft = (-(obj.left || 0) * zoomLevel) - (objWidth / 2) + (props.whiteboard.canvas.width || 0) / 2
      const newTop = (-(obj.top || 0) * zoomLevel) - (objHeight / 2) + (props.whiteboard.canvas.height || 0) / 2
      props.whiteboard.canvas.setViewportTransform([zoomLevel, 0, 0, zoomLevel, newLeft, newTop])
      props.whiteboard.canvas.setActiveObject(obj)
      props.whiteboard.canvas.requestRenderAll()
      break
    }

    selectedMatchObjectTextStartIndex.value = 0
    selectedMatchObjectIndex.value++
    if (selectedMatchObjectIndex.value >= matchObjects.value.length) {
      resetSelectionStyles(obj)
    }
    props.whiteboard?.canvas.requestRenderAll()
  }
}

function replaceObjText(obj: IWbObject, replaceAll: boolean) {
  const text: string = obj.getProp('text').text
  const regEx = new RegExp(form.findWhat, 'gi')
  if (obj instanceof WbTextBox) {
    if (replaceAll) {
      const modifiedText = text.replace(regEx, form.replaceWith)
      obj.setProp('text', { text: modifiedText })
    }
    else {
      const index = selectedMatchObjectTextStartIndex.value - 1
      const pos = text.substring(index).toLowerCase().indexOf(form.findWhat.toLowerCase())
      if (pos >= 0) {
        const modifiedText = form.replaceWith + text.substring(index).substring(pos + form.findWhat.length)
        obj.setProp('text', { text: text.substring(0, index) + modifiedText })
      }
    }
  }
  else if (obj instanceof WbSticky) {
    const modifiedText = text.replace(regEx, form.replaceWith)
    obj.setProp('text', { text: modifiedText })
  }
}

function doReplace() {
  const obj = matchObjects.value[selectedMatchObjectIndex.value]
  if (utils.isDefined(obj)) {
    replaceObjText(obj, false)
    resetSelectionStyles(obj)
    doFindNext()
  }
}

function doReplaceAll() {
  if (matchObjects.value.length) {
    matchObjects.value.forEach((obj) => {
      replaceObjText(obj, true)
      resetSelectionStyles(obj)
    })
    resetMatchObjects()
  }
}

function resetSelectionStyles(obj: IWbObject) {
  if (obj instanceof WbTextBox || obj instanceof WbArticleDetails || obj instanceof WbModelDetails) {
    obj.setSelectionStyles({ fill: obj.fill, fontStyle: obj.fontStyle }, 0, obj.text?.length)
  }
}

function resetMatchObjects() {
  matchObjects.value.forEach(obj => resetSelectionStyles(obj))
  selectedMatchObjectTextStartIndex.value = 0
  selectedMatchObjectIndex.value = 0
  props.whiteboard?.canvas.discardActiveObject()
  props.whiteboard?.canvas.requestRenderAll()
  matchObjects.value = []
}

watch(() => form.findWhat, resetMatchObjects)

watch(() => props.modelValue, () => {
  if (props.modelValue) {
    form.findWhat = ''
    form.replaceWith = ''
    resetMatchObjects()
  }
})
</script>
