import type { Collection, IndexableType, Table } from 'dexie'
import Dexie from 'dexie'
import axios from 'axios'
import { assign, isObject, uniqBy } from 'lodash-es'
import Net from './net'
import utils from './utils'
import LinkedCustomer, { LinkedCustomerAllocationGroup } from '@/models/linkedCustomer'
import Article from '@/models/article'
import RequestModel from '@/models/request'
import ArticleDeliverDate from '@/models/articleDeliveryDate'
import type { Price } from '@/models/articlePrice'
import ArticlePrice from '@/models/articlePrice'
import ArticleSize from '@/models/articleSize'
import ArticleSegmentation from '@/models/articleSegmentation'
import Resource from '@/models/resource'
import DropReason from '@/models/dropReason'
import type { Segmentation } from '@/models/customerSegmentation'
import CustomerSegmentation from '@/models/customerSegmentation'
import CatalogTreeDef from '@/models/catalogTreeDef'
import CatalogDetails from '@/models/catalogDetails'
import MyArticle from '@/models/myArticle'
import type CatalogPriceGroup from '@/models/catalogPriceGroup'
import FavoriteTag from '@/models/favoriteTag'
import SimpleFavoriteTag from '@/models/simpleFavoriteTag'
import ResourceType from '@/models/resourceType'
import RelationType from '@/models/objectRelation/relationType'
import ObjectRelation from '@/models/objectRelation/objectRelation'
import Allocation from '@/models/allocation'
import SavedView from '@/models/savedView'
import ColorPallet from '@/models/colorPallet'
import SellerDetails from '@/models/sellerDetails'
import Whiteboard from '@/models/whiteboard'
import Job from '@/models/job'
import { getResourceTypes } from '@/api/t1/resource'
import { getObjectRelations, getRelationTypes } from '@/api/t1/objectRelation'
import { getCustomerAllocationGroups } from '@/api/t1/customer'
import { getArticleDropReasons } from '@/api/t1/article'
import { createUpdateFavorite, updateFavoritesStatus } from '@/api/t1/favorite'
import type { UpdateFavoritesStatusModel } from '@/api/t1/model/favoriteModel'
import type DuneAsset from '@/models/duneAsset'
import { appConstants, requestConstants } from '@/models/constants'
import type { FilterCriteria } from '@/models/filterCriteria'
import type CatalogAttribute from '@/models/catalogAttribute'
import { AttributeType } from '@/models/catalogAttribute'
import type { ArticleModel, ArticleStateModel } from '@/api/t1/model/articleModel'
import type { ICustomersLocations } from '@/api/t1/model/orderModel'
import CustomerLocation from '@/models/CustomerLocation'
import { getCatalogDetails } from '@/api/t1/catalog'

export type Entity = 'Articles' | 'Requests' | 'Prices' | 'Sizes' | 'DeliveryDates' | 'Segmentations' | 'Resources' | 'MyFavoriteTags' | 'SharedFavoriteTags' | 'Allocations' | 'SavedViews' | 'ColorPallets' | 'Whiteboards' | 'Locations' | 'CustomerSegmentations'
// type DBTable = 'catalogs' | 'singleton' | 'catalogDetails' | 'catalogTreeDef' | 'linkedCustomers' | 'articles' | 'resources' | 'dropReasons' | 'favoriteTags' | 'allocations' | 'savedViews' | 'colorPallets' | 'whiteboards' | 'locations'

interface ArticleEntitiesParams { prices: ArticlePrice[], sizes: ArticleSize[], segmentations: ArticleSegmentation[], deliveryDates: ArticleDeliverDate[] }

export const ARTICLES_LIMIT = 2000
export const REQUESTS_LIMIT = 1000
const BLANK_TREE_NODE_LABEL = '(Others)'
export class DB extends Dexie {
  catalogs: Table<ICatalog, IndexableType>
  singleton: Table<any, IndexableType>
  catalogDetails: Table<any, IndexableType>
  sellerDetails: Table<any, IndexableType>
  catalogTreeDef: Table<CatalogTreeDef, IndexableType>
  linkedCustomers: Table<LinkedCustomer, IndexableType>
  articles: Table<Article, IndexableType>
  requests: Table<RequestModel, IndexableType>
  // prices: Table<ArticlePrice, IndexableType>
  // sizes: Table<ArticleSize, IndexableType>
  // deliveryDates: Table<ArticleDeliverDate, IndexableType>
  // segmentations: Table<ArticleSegmentation, IndexableType>
  resources: Table<Resource, IndexableType>
  dropReasons: Table<DropReason, IndexableType>
  resourceTypes: Table<ResourceType, IndexableType>
  relationTypes: Table<RelationType, IndexableType>
  objectRelations: Table<ObjectRelation, IndexableType>
  customerSegmentations: Table<CustomerSegmentation, IndexableType>
  favoriteTags: Table<FavoriteTag, IndexableType>
  allocations: Table<Allocation, IndexableType>
  savedViews: Table<SavedView, IndexableType>
  colorPallets: Table<ColorPallet, IndexableType>
  whiteboards: Table<Whiteboard, IndexableType>
  jobs: Table<Job, IndexableType>
  locations: Table<CustomerLocation, IndexableType>
  trackChanges: boolean

  private getUrl(entity: Entity, catalog: CatalogDetails, since?: string) {
    const catalogCode = catalog.CatalogCode
    const u = since ? `u=${since}` : ''
    switch (entity) {
      case 'Articles': {
        let url = `/catalogs/${catalogCode}/articles?${u}`
        // if child catalog get the assortable articles list
        if (catalog.DataSourceTypeId === 3) {
          url = `/catalogs/${catalog.CatalogCode}/assortablearticles?${u}`
        }
        return url
      }
      case 'Requests':
        return `/catalogs/${catalogCode}/requests?requestversion=${requestConstants.requestVersion}&extendedresults=true&${u}`
      case 'DeliveryDates':
        return `/livefeed/catalogs/${catalogCode}/articlecrds?${u}`
      case 'Prices':
        return `/catalogs/${catalogCode}/articleprices?${u}`
      case 'Sizes':
        return `/catalogs/${catalogCode}/articlesizes?${u}`
      case 'Segmentations':
        return `/catalogs/${catalogCode}/articlesegmentations?${u}`
      case 'Resources':
        return `/livefeed/catalogs/${catalogCode}/resources?${u}`
      case 'MyFavoriteTags':
        return `/catalogs/${catalogCode}/myfavorites?type=my&${u}`
      case 'SharedFavoriteTags':
        return `/catalogs/${catalogCode}/myfavorites?type=shared&${u}`
      case 'Allocations':
        return `/livefeed/catalogs/${catalogCode}/allocations?${u}`
      case 'SavedViews':
        return `/catalogs/${catalogCode}/mysavedviews?${u}`
      case 'ColorPallets':
        return `/catalogs/${catalogCode}/colors?${u}`
      case 'Whiteboards':
        return `/catalogs/${catalogCode}/mywhiteboards?${u}`
      case 'Locations':
        return `/livefeed/catalogs/${catalogCode}/customerlocations?${u}`
      case 'CustomerSegmentations':
        return `/livefeed/catalogs/${catalogCode}/customersegmentations?${u}`
    }
  }

  constructor(databaseName: string) {
    super(databaseName)
    this.version(28).stores({
      sellerDetails: 'AccountId',
      catalogs: 'Id, CatalogCode, Status, Visibility',
      singleton: 'name',
      catalogDetails: 'CatalogCode, UpdatedDate',
      catalogTreeDef: '[CatalogCode+Id], CatalogCode, Status',
      linkedCustomers: '[CatalogCode+CustomerId], CatalogCode, Status',
      articles: '[CatalogCode+Id], [CatalogCode+Status], [CatalogCode+ArticleNumber], [CatalogCode+ModelNumber], [CatalogCode+Status+ModelNumber], CatalogCode, Id, ArticleNumber, ModelNumber, Status',
      // prices: '[CatalogCode+Id], CatalogCode, Id',
      // sizes: '[CatalogCode+Id], CatalogCode',
      // deliveryDates: '[CatalogCode+Id], CatalogCode, Id',
      // segmentations: '[CatalogCode+Id], CatalogCode, Id',
      resources: 'Id, CatalogCode, [CatalogCode+ResourceId], *Articles',
      dropReasons: '[SellerAccountId+Id], SellerAccountId, Id',
      resourceTypes: 'Id',
      relationTypes: 'Id, CatalogCode, Status',
      objectRelations: 'Id, CatalogCode, [CatalogCode+ObjectId+EntityId+IsRequestArticle]',
      customerSegmentations: '[CatalogCode+Id], CatalogCode, Id',
      favoriteTags: '&[CatalogCode+CreatedByUserName+Tag], [CatalogCode+CreatedByUserName+Status], [CatalogCode+Tag+CreatedByUserName+Status], [CatalogCode+Status], Id, *Articles',
      allocations: '[CatalogCode+Id], CatalogCode, Status, *Articles',
      savedViews: '[CatalogCode+Id], Id, CatalogCode, Status, [CatalogCode+CreatedBy+IsCatalogLevel+Status], [CatalogCode+IsCatalogLevel+Status]',
      colorPallets: '[CatalogCode+Id], [CatalogCode+Name], Id, CatalogCode, Name, Status',
      whiteboards: '[CatalogCode+Id], Id, CatalogCode, Status',
      jobs: '[CatalogCode+Id], [CatalogCode+UserId], Id, CatalogCode, Type, UserId, Status, CreatedDate',
    }).upgrade((tx) => {
      return Promise.all([
        tx.table('singleton').clear(),
        tx.table('catalogs').clear(),
        tx.table('catalogDetails').clear(),
        tx.table('sellerDetails').clear(),
        tx.table('catalogTreeDef').clear(),
        tx.table('linkedCustomers').clear(),
        tx.table('articles').clear(),
        tx.table('resources').clear(),
        tx.table('dropReasons').clear(),
        tx.table('resourceTypes').clear(),
        tx.table('relationTypes').clear(),
        tx.table('objectRelations').clear(),
        tx.table('customerSegmentations').clear(),
        tx.table('favoriteTags').clear(),
        tx.table('allocations').clear(),
        tx.table('savedViews').clear(),
        tx.table('colorPallets').clear(),
        tx.table('whiteboards').clear(),
        tx.table('jobs').clear(),
      ])
    })
    this.version(39).stores({
      catalogs: 'Id, CatalogCode, Status, Visibility, DataSourceTypeId',
      articles: '[CatalogCode+Id], [CatalogCode+Status], [CatalogCode+ArticleNumber], [CatalogCode+ModelNumber], [CatalogCode+Status+ModelNumber], CatalogCode, Id, ArticleNumber, ModelNumber, Status, [CatalogCode+ModelId]',
      // as discussed with Qureshi locationId and CustomerId is unique in T1 for all the sellers/catalogs
      locations: 'Id, CustomerId, Status, AccountId, [AccountId+CustomerId]',
      linkedCustomers: '[CatalogCode+CustomerId], CatalogCode, Status',
      colorPallets: '[CatalogCode+Id], Id, CatalogCode, Name, Status',
      whiteboards: '[CatalogCode+Id], [CatalogCode+CreatedBy], Id, CatalogCode, Status',
      requests: '[CatalogCode+Id], [CatalogCode+SourceModelId], [CatalogCode+ObjectId], [CatalogCode+Status], [CatalogCode+Status+IsCreateArticleRequest], [CatalogCode+IsCreateArticleRequest], CatalogCode, Id',
    }).upgrade((tx) => {
      return Promise.all([
        tx.table('singleton').clear(),
        tx.table('articles').clear(),
        tx.table('resources').clear(),
        tx.table('requests').clear(),
        tx.table('allocations').clear(),
        tx.table('linkedCustomers').clear(),
        tx.table('favoriteTags').clear(),
        tx.table('allocations').clear(),
        tx.table('savedViews').clear(),
        tx.table('colorPallets').clear(),
        tx.table('whiteboards').clear(),
        tx.table('locations').clear(),
      ])
    })
    this.version(45).stores({
      articles: '[CatalogCode+Id], [CatalogCode+Status], [CatalogCode+ArticleNumber], [CatalogCode+ModelNumber], [CatalogCode+Status+ModelNumber], CatalogCode, Id, ArticleNumber, ModelNumber, Status, [CatalogCode+ModelId]',
      requests: '[CatalogCode+Id], [CatalogCode+SourceModelId], [CatalogCode+ObjectId], [CatalogCode+Status], [CatalogCode+Status+IsCreateArticleRequest], [CatalogCode+IsCreateArticleRequest], CatalogCode, Id',
    }).upgrade((tx) => {
      return Promise.all([
        tx.table('singleton').filter(s => s.name.endsWith('Articles') || s.name.endsWith('Prices') || s.name.endsWith('Sizes') || s.name.endsWith('DeliveryDates') || s.name.endsWith('Segmentations' || s.name.endsWith('Requests'))).delete(),
        tx.table('articles').clear(),
        tx.table('requests').clear(),
      ])
    })
    this.version(47).stores({
      articles: '[CatalogCode+Id], [CatalogCode+Status], [CatalogCode+ArticleNumber], [CatalogCode+ModelNumber], [CatalogCode+Status+ModelNumber], CatalogCode, Id, ModelId, ArticleNumber, ModelNumber, Status, [CatalogCode+ModelId]',
      colorPallets: '[CatalogCode+Id], Id, CatalogCode, Name, Status, *ColorIds',
    }).upgrade((tx) => {
      return Promise.all([
        tx.table('singleton').filter(s => s.name.endsWith('ColorPallets')).delete(),
        tx.table('colorPallets').clear(),
      ])
    })
    this.version(48).stores({
    }).upgrade((tx) => {
      return Promise.all([
        tx.table('customerSegmentations').clear(),
      ])
    })

    this.catalogs = this.table('catalogs')
    this.singleton = this.table('singleton')
    this.catalogDetails = this.table('catalogDetails')
    this.sellerDetails = this.table('sellerDetails')
    this.catalogTreeDef = this.table('catalogTreeDef')
    this.linkedCustomers = this.table('linkedCustomers')
    this.articles = this.table('articles')
    this.requests = this.table('requests')
    // this.prices = this.table('prices')
    // this.sizes = this.table('sizes')
    // this.deliveryDates = this.table('deliveryDates')
    // this.segmentations = this.table('segmentations')
    this.resources = this.table('resources')
    this.dropReasons = this.table('dropReasons')
    this.resourceTypes = this.table('resourceTypes')
    this.relationTypes = this.table('relationTypes')
    this.objectRelations = this.table('objectRelations')
    this.customerSegmentations = this.table('customerSegmentations')
    this.favoriteTags = this.table('favoriteTags')
    this.allocations = this.table('allocations')
    this.savedViews = this.table('savedViews')
    this.colorPallets = this.table('colorPallets')
    this.whiteboards = this.table('whiteboards')
    this.jobs = this.table('jobs')
    this.locations = this.table('locations')
    this.trackChanges = false

    this.catalogDetails.mapToClass(CatalogDetails)
    this.favoriteTags.mapToClass(FavoriteTag)
    this.savedViews.mapToClass(SavedView)
    this.colorPallets.mapToClass(ColorPallet)
    this.whiteboards.mapToClass(Whiteboard)
    this.jobs.mapToClass(Job)
    this.requests.mapToClass(RequestModel)
  }

  async loadEntitiesFirstTime(entitiesToLoad: Entity[], catalog: CatalogDetails, userId?: number, isViewUnAssortedArticlesRestricted?: boolean, progress?: (entity: Entity, progress: number) => void) {
    // Load articles
    let url = this.getUrl('Articles', catalog)
    if (!url) {
      console.warn('Unable to get URL for entity Articles')
      return
    }

    console.log('Loading entities for first time', catalog.CatalogCode)

    const lastCallRecords: { name: string, dt: string }[] = []
    const promises: Promise<void | number>[] = []

    console.time(`Articles-${catalog.CatalogCode}-API`)

    promises.push(Net.t1.get(url).then(async (res) => {
      const artPromises: Promise<void | number>[] = []

      if (!res.data || !res.data.length) {
        console.warn('No articles found', catalog.CatalogCode)
        return
      }

      let articleSince: string = new Date(res.data[0].UpdatedDate).toISOString()
      let articlesData = res.data.map((itm) => {
        const updatedDate = new Date(itm.UpdatedDate).toISOString()
        if (updatedDate > articleSince) {
          articleSince = updatedDate
        }
        return new Article(catalog.CatalogCode, catalog.AssignedCatalogAttributes, itm, catalog.DataSourceTypeId)
      })
      articlesData.LocalUpdatedDate = new Date()
      // for Inherited Catalog dont show not assorted articles articles if the UI_ViewUnAssortedArticles is not assigned.
      // filter unassorted articles
      if (isViewUnAssortedArticlesRestricted) {
        articlesData = articlesData.filter(itm => itm.Status !== 2)
      }
      const articlesDataById: Record<number, Article> = articlesData.reduce((acu, art) => (acu[art.Id] = art) && acu, {})
      console.timeEnd(`Articles-${catalog.CatalogCode}-API`)
      await this.articles.where('CatalogCode').equals(catalog.CatalogCode).delete()
      lastCallRecords.push({ name: `lc-${catalog.CatalogCode}-Articles`, dt: articleSince })

      if (entitiesToLoad.includes('DeliveryDates')) {
        url = this.getUrl('DeliveryDates', catalog)
        if (!url) {
          console.warn('Unable to get URL for entity DeliveryDates')
          return
        }
        artPromises.push(Net.t1.get(url).then(async (res) => {
          let deliveryDateSince: string | null = null
          if (res.data && res.data.length) {
            deliveryDateSince = utils.getMaxUpdatedDate(res.data, undefined, 'CrdList')
            for (const itm of res.data) {
              const deliveryDate = new ArticleDeliverDate(catalog.CatalogCode, itm)
              if (utils.isDefined(articlesDataById[deliveryDate.Id])) {
                articlesDataById[deliveryDate.Id]._DeliveryDates = deliveryDate.Crds
              }
            }
          }
          if (progress) { progress('DeliveryDates', 100) }
          if (deliveryDateSince !== null) {
            lastCallRecords.push({ name: `lc-${catalog.CatalogCode}-DeliveryDates`, dt: deliveryDateSince })
          }
        }))
      }

      if (entitiesToLoad.includes('Prices')) {
        url = this.getUrl('Prices', catalog)
        if (!url) {
          console.warn('Unable to get URL for entity Prices')
          return
        }
        artPromises.push(Net.t1.get(url).then(async (res) => {
          let pricesSince: string | null = null
          if (res.data && res.data.length) {
            pricesSince = utils.getMaxUpdatedDate(res.data, undefined, 'Prices')
            for (const itm of res.data) {
              const articlePrice = new ArticlePrice(catalog.CatalogCode, itm)
              if (utils.isDefined(articlesDataById[articlePrice.Id])) {
                articlesDataById[articlePrice.Id]._Prices = utils.toRecord(articlePrice.Prices, 'PriceGroupId')
              }
            }
          }
          if (progress) { progress('Prices', 100) }
          if (pricesSince !== null) {
            lastCallRecords.push({ name: `lc-${catalog.CatalogCode}-Prices`, dt: pricesSince })
          }
        }))
      }

      if (entitiesToLoad.includes('Sizes')) {
        url = this.getUrl('Sizes', catalog)
        if (!url) {
          console.warn('Unable to get URL for entity Sizes')
          return
        }
        artPromises.push(Net.t1.get(url).then(async (res) => {
          let sizesSince: string | null = null
          if (res.data && res.data.length) {
            sizesSince = utils.getMaxUpdatedDate(res.data, undefined, 'Sizes')
            for (const itm of res.data) {
              const articleSize = new ArticleSize(catalog.CatalogCode, itm)
              if (utils.isDefined(articlesDataById[articleSize.Id])) {
                articlesDataById[articleSize.Id]._Sizes = articleSize.Sizes
              }
            }
          }
          if (progress) { progress('Sizes', 100) }
          if (sizesSince !== null) {
            lastCallRecords.push({ name: `lc-${catalog.CatalogCode}-Sizes`, dt: sizesSince })
          }
        }))
      }

      if (entitiesToLoad.includes('Segmentations')) {
        url = this.getUrl('Segmentations', catalog)
        if (!url) {
          console.warn('Unable to get URL for entity Segmentations')
          return
        }
        artPromises.push(Net.t1.get(url).then(async (res) => {
          let segmentationsSince: string | null = null
          if (res.data && res.data.length) {
            segmentationsSince = utils.getMaxUpdatedDate(res.data, 'LastUpdatedOn')
            for (const itm of res.data) {
              const articleSeg = new ArticleSegmentation(catalog.CatalogCode, itm)
              if (utils.isDefined(articlesDataById[articleSeg.Id])) {
                articlesDataById[articleSeg.Id]._Segmentations = utils.toRecord(articleSeg.Segmentations, 'Id')
              }
            }
          }
          if (progress) { progress('Segmentations', 100) }
          if (segmentationsSince !== null) {
            lastCallRecords.push({ name: `lc-${catalog.CatalogCode}-Segmentations`, dt: segmentationsSince })
          }
        }))
      }

      // Save Articles
      await Promise.all(artPromises)
      console.log('Adding articles', articlesData.length)
      console.time(`Articles-${catalog.CatalogCode}-DB`)
      await this.transaction('rw', this.articles, async () => {
        const chunkSize = 2000
        for (let i = 0; i < articlesData.length; i += chunkSize) {
          const chunk = articlesData.slice(i, i + chunkSize)
          await this.articles.bulkAdd(chunk)
          if (progress) { progress('Articles', Math.min(100, (i + chunkSize) * 100 / articlesData.length)) }
        }
      }).then(() => {
        if (progress) { progress('Articles', 100) }
        console.timeEnd(`Articles-${catalog.CatalogCode}-DB`)
        // Set lastCall
        this.singleton.bulkPut(lastCallRecords)
      })
    }))

    console.log('loading other entities')
    // Load other entities
    entitiesToLoad.forEach((entity) => {
      switch (entity) {
        case 'Articles':
        case 'DeliveryDates':
        case 'Prices':
        case 'Sizes':
        case 'Segmentations':
          return

        default:
          promises.push(this.loadEntitySinceLastCall(entity, catalog, userId, isViewUnAssortedArticlesRestricted, (ip) => {
            if (progress) { progress(entity, ip) }
          }))
      }
    })

    await Promise.all(promises)
  }

  async loadEntitySinceLastCall(entity: Entity, catalog: CatalogDetails, userId?: number, isViewUnAssortedArticlesRestricted?: boolean, progress?: (progress: number) => void): Promise<void> {
    const nameKey = utils.isDefined(userId) && entity === 'SavedViews'
      ? `lc-${catalog.CatalogCode}-u-${userId}-${entity}`
      : `lc-${catalog.CatalogCode}-${entity}`
    let lastCall = await this.singleton.get(nameKey)
    let since: string | undefined
    let firstTime = true
    if (lastCall && lastCall.dt) {
      firstTime = false
      since = lastCall.dt
    }
    else {
      lastCall = { name: nameKey }
    }
    const url = this.getUrl(entity, catalog, since)
    if (!url) {
      console.warn('Unable to get URL for entity', entity)
      return
    }

    const res = await Net.t1.get(url)
    console.time(`${entity}-${catalog.CatalogCode}`)

    switch (entity) {
      case 'Articles': {
        // fetch the existing data
        if (firstTime) {
          console.log('Deleting Articles', catalog.CatalogCode)
          await this.articles.where('CatalogCode').equals(catalog.CatalogCode).delete()
          if (res.data && res.data.length) {
            let articleSince: string = new Date(res.data[0].UpdatedDate).toISOString()
            await this.articles.bulkAdd(res.data.map((itm) => {
              const updatedDate = new Date(itm.UpdatedDate).toISOString()
              if (updatedDate > articleSince) {
                articleSince = updatedDate
              }
              return new Article(catalog.CatalogCode, catalog.AssignedCatalogAttributes, itm, catalog.DataSourceTypeId)
            }))
            if (articleSince !== null) {
              lastCall.dt = articleSince
            }
          }
        }
        else if (res.data && res.data.length) {
          const articleSince: string = utils.getMaxUpdatedDate(res.data)
          if (articleSince !== null) {
            lastCall.dt = articleSince
          }
          await this.bulkUpdateArticlesFromAPI(catalog, res.data)
        }
        // if privilege is changed from last update
        // @aanchal @saad This is the only way I can think of doing it right now.
        // If you think we can implement it diffrently we can try it out.
        // when user is logged in after changing the privilege this is to update local data
        if (catalog.DataSourceTypeId === 3) {
          // TODO: We need to change the implementation its very important
          const articlesCount = await this.articles.where({ CatalogCode: catalog.CatalogCode, Status: 2 }).count()
          const hasNonAssortedArticles = articlesCount > 0
          if (isViewUnAssortedArticlesRestricted) { // remove not assorted articles
            if (hasNonAssortedArticles) { // if the local data have not assorted articles means now the user has changed the privilege
              try {
                const entitiesToLoad: Array<Entity> = ['Articles', 'Prices', 'DeliveryDates', 'Segmentations', 'Resources', 'MyFavoriteTags', 'SavedViews', 'ColorPallets', 'Sizes']
                if (catalog.RequestsEnabled > 0) {
                  entitiesToLoad.push('Requests')
                }
                this.loadEntitiesFirstTime(entitiesToLoad, catalog, undefined, isViewUnAssortedArticlesRestricted)
              }
              catch (error) {
                console.warn('Unable to reload articles', error)
              }
            }
          }
          else {
            if (!hasNonAssortedArticles) { // get the assorted articles back
              try {
                const entitiesToLoad: Array<Entity> = ['Articles', 'Prices', 'DeliveryDates', 'Segmentations', 'Resources', 'MyFavoriteTags', 'SavedViews', 'ColorPallets', 'Sizes']
                if (catalog.RequestsEnabled > 0) {
                  entitiesToLoad.push('Requests')
                }
                this.loadEntitiesFirstTime(entitiesToLoad, catalog, undefined, isViewUnAssortedArticlesRestricted)
              }
              catch (error) {
                console.warn('Unable to reload articles', error)
              }
            }
          }
        }
        break
      }

      case 'Requests': {
        if (res.data && res.data.length) {
          const requestsSince: string = utils.getMaxUpdatedDate(res.data)
          if (requestsSince !== null) {
            lastCall.dt = requestsSince
          }
          const data = res.data.map((al: any) => new RequestModel(catalog.CatalogCode, al))
          await this.requests.bulkPut(data)
        }
        break
      }

      case 'DeliveryDates': {
        if (res.data && res.data.length) {
          const deliveryDateSince: string = utils.getMaxUpdatedDate(res.data, undefined, 'CrdList')
          if (deliveryDateSince !== null) {
            lastCall.dt = deliveryDateSince
          }
          const data = res.data.map((dd: any) => new ArticleDeliverDate(catalog.CatalogCode, dd))
          await this.bulkUpdateArticles(catalog.CatalogCode, 'deliveryDates', data, firstTime)
          // await this.deliveryDates.bulkPut(data)
        }
        break
      }

      case 'CustomerSegmentations': {
        if (res.data && res.data.length) {
          const CustomerSegmentationsSince: string = utils.getMaxUpdatedDate(res.data, 'UpdatedDate', 'SegmentationList')
          if (CustomerSegmentationsSince !== null) {
            lastCall.dt = CustomerSegmentationsSince
          }
          const data = res.data.map((cs: any) => new CustomerSegmentation(catalog.CatalogCode, cs.CustomerId, cs.SegmentationList))
          if (firstTime) {
            await this.customerSegmentations.bulkPut(data)
          }
          else {
            const dbCustomerSegmentations = await this.customerSegmentations.where('[CatalogCode+Id]').anyOf(data.map((itm: any) => [catalog.CatalogCode, itm.Id])).toArray()
            const dbCustomerSegmentationsByCustomerId = dbCustomerSegmentations.reduce((acu, art) => (acu[art.Id] = art) && acu, {})
            for (const itm of data as CustomerSegmentation[]) {
              if (utils.isDefined(dbCustomerSegmentationsByCustomerId[itm.Id])) {
                // If new segmentations exist in the response, reset existing ones and add the new ones
                const dbSegmentations = dbCustomerSegmentationsByCustomerId[itm.Id].Segmentations
                if (itm.Segmentations && Object.keys(itm.Segmentations).length > 0) {
                  itm.Segmentations.forEach((segmentation) => {
                    const indexValue = dbSegmentations.findIndex(dbSeg => dbSeg.Id === segmentation.Id)
                    if (indexValue !== -1) {
                      dbSegmentations[indexValue] = segmentation
                    }
                    else {
                      dbSegmentations.push(segmentation)
                    }
                  })
                  await this.customerSegmentations.update([catalog.CatalogCode, itm.Id], { Segmentations: dbSegmentations })
                }
              }
              else {
                // If it's the first time or no existing segmentations, assign the new segmentations'
                await this.customerSegmentations.put(itm)
              }
            }
          }
        }
        break
      }

      case 'Prices': {
        if (res.data && res.data.length) {
          const pricesSince: string = utils.getMaxUpdatedDate(res.data, undefined, 'Prices')
          if (pricesSince !== null) {
            lastCall.dt = pricesSince
          }
          const data = res.data.map((itm: any) => new ArticlePrice(catalog.CatalogCode, itm))
          await this.bulkUpdateArticles(catalog.CatalogCode, 'prices', data, firstTime)
          // await this.prices.bulkPut(data)
        }
        break
      }

      case 'Sizes': {
        if (res.data && res.data.length) {
          const sizesSince: string = utils.getMaxUpdatedDate(res.data, undefined, 'Sizes')
          if (sizesSince !== null) {
            lastCall.dt = sizesSince
          }
          const data = res.data.map((itm: any) => new ArticleSize(catalog.CatalogCode, itm))
          await this.bulkUpdateArticles(catalog.CatalogCode, 'sizes', data, firstTime)
          // await this.sizes.bulkPut(data)
        }
        break
      }

      case 'Segmentations': {
        if (res.data && res.data.length) {
          const segmentationsSince: string = utils.getMaxUpdatedDate(res.data, 'LastUpdatedOn')
          if (segmentationsSince !== null) {
            lastCall.dt = segmentationsSince
          }
          const data = res.data.map((itm: any) => new ArticleSegmentation(catalog.CatalogCode, itm))
          await this.bulkUpdateArticles(catalog.CatalogCode, 'segmentations', data, firstTime)
          // await this.segmentations.bulkPut(data)
        }
        break
      }

      case 'Resources': {
        if (res.data && res.data.length) {
          const resourcesSince: string = utils.getMaxUpdatedDate(res.data)
          if (resourcesSince !== null) {
            lastCall.dt = resourcesSince
          }
          const data = res.data.map((itm: any) => new Resource(catalog.CatalogCode, itm))
          if (firstTime) {
            await this.resources.where('CatalogCode').equals(catalog.CatalogCode).delete()
            await this.resources.bulkAdd(data)
          }
          else {
            await this.resources.bulkPut(data)
          }
        }
        break
      }

      case 'MyFavoriteTags':
      case 'SharedFavoriteTags': {
        if (res.data && res.data.length) {
          const favoritesSince: string = utils.getMaxUpdatedDate(res.data)
          if (favoritesSince !== null) {
            lastCall.dt = favoritesSince
          }
          Promise.all((res.data as Array<any>).map(async (itm) => {
            const remoteTag = new FavoriteTag(catalog.CatalogCode, itm)
            const localTag = await this.favoriteTags.get({ CatalogCode: catalog.CatalogCode, Tag: itm.Tag, CreatedByUserName: itm.CreatedByUserName })
            if (!localTag || localTag.LocalUpdatedDate < remoteTag.UpdatedDate!) {
              this.favoriteTags.put(remoteTag)
            }
          }))
        }
        break
      }

      case 'Allocations': {
        if (res.data && res.data.length) {
          const allocationsSince: string = utils.getMaxUpdatedDate(res.data)
          if (allocationsSince !== null) {
            lastCall.dt = allocationsSince
          }
          const data = res.data.map((al: any) => new Allocation(catalog.CatalogCode, al))
          await this.allocations.bulkPut(data)
          await this.allocations.where('Status').equals(0).delete()
        }
        break
      }

      case 'SavedViews': {
        if (res.data && res.data.length) {
          const savedViewsSince: string = utils.getMaxUpdatedDate(res.data)
          if (savedViewsSince !== null) {
            lastCall.dt = savedViewsSince
          }
          const data = res.data.map((sv: any) => new SavedView(catalog.CatalogCode, sv))
          await this.savedViews.bulkPut(data)
          await this.savedViews.where('Status').equals(0).delete()
        }
        break
      }

      case 'ColorPallets': {
        if (res.data && res.data.length) {
          const colorPalettesSince: string = utils.getMaxUpdatedDate(res.data)
          if (colorPalettesSince !== null) {
            lastCall.dt = colorPalettesSince
          }
          const data = res.data.map((cp: any) => new ColorPallet(catalog.CatalogCode, cp))
          await this.colorPallets.bulkPut(data)
          await this.colorPallets.where('Status').equals(0).delete()
        }
        break
      }

      case 'Whiteboards': {
        if (res.data && res.data.length) {
          const whiteboardsSince: string = utils.getMaxUpdatedDate(res.data)
          if (whiteboardsSince !== null) {
            lastCall.dt = whiteboardsSince
          }
          const data = res.data.map((wb: any) => new Whiteboard(catalog.CatalogCode, wb))
          await this.whiteboards.bulkPut(data)
          await this.whiteboards.where('Status').equals(0).delete()
        }
        break
      }

      case 'Locations': {
        if (res.data && res.data.length) {
          const locationsSince: string = utils.getMaxUpdatedDate(res.data, undefined, 'LocationList')
          if (locationsSince !== null) {
            lastCall.dt = locationsSince
          }
          const customersToDelete: Array<number> = []
          const data: Array<CustomerLocation> = [];
          (res.data as Array<ICustomersLocations>).forEach((customerLocations) => {
            if (customerLocations.LocationList == null || !customerLocations.LocationList.length) {
              customersToDelete.push(customerLocations.CustomerId)
            }
            customerLocations.LocationList.forEach((location) => {
              data.push(new CustomerLocation(catalog.AccountId, { ...location, CustomerNumber: customerLocations.CustomerNumber, CustomerId: customerLocations.CustomerId }))
            })
          })
          await this.locations.bulkPut(data)
          await this.locations.where('Status').equals(0).delete()
          // customerId is unique in our system across all sellers and location and customers are tied to seller (not catalog)
          await this.locations.where('CustomerId').anyOf(customersToDelete).delete()
        }
        break
      }
    }
    console.timeEnd(`${entity}-${catalog.CatalogCode}`)
    await this.singleton.put(lastCall)
    if (progress) { progress(100) }
  }

  async bulkUpdateArticlesFromAPI(catalog: CatalogDetails, articles: ArticleModel[]) {
    await this.transaction('rw', this.articles, async () => {
      const dbArticles = await this.articles.where('[CatalogCode+Id]').anyOf(articles.map(art => [catalog.CatalogCode, art.Id])).toArray()
      const dbArticlesById: Record<number, Article> = dbArticles.reduce((acu, art) => (acu[art.Id] = art) && acu, {})
      const articleData = articles.map((itm: any) => {
        const dbArticle = dbArticlesById[itm.Id]
        if (utils.isDefined(dbArticle)) {
          if (catalog.DataSourceTypeId === 3) {
            itm.Status = itm.PS === 0 ? 3 : (itm.PS === 1 && itm.Status === 0) ? 2 : itm.Status
          }
          itm.LocalUpdatedDate = new Date()
          assign(dbArticle, itm)
          return dbArticle
        }
        return new Article(catalog.CatalogCode, catalog.AssignedCatalogAttributes, itm, catalog.DataSourceTypeId)
      })
      await this.articles.bulkPut(articleData)
    })
  }

  async bulkUpdateArticles<T extends keyof ArticleEntitiesParams>(catalogCode: number, entity: T, data: ArticleEntitiesParams[T], firstTime: boolean) {
    await this.transaction('rw', this.articles, async () => {
      switch (entity) {
        case 'deliveryDates':
          for (const itm of data as ArticleDeliverDate[]) {
            await this.articles.update([catalogCode, itm.Id], { LocalUpdatedDate: new Date(), _DeliveryDates: itm.Crds })
          }
          break
        case 'prices':
        {
          let dbArticlesById: Record<number, Article> = {}

          if (!firstTime) {
            const dbArticles = await this.articles.where('[CatalogCode+Id]').anyOf(data.map((itm: any) => [catalogCode, itm.Id])).toArray()
            dbArticlesById = dbArticles.reduce((acu, art) => (acu[art.Id] = art) && acu, {})
          }

          for (const itm of data as ArticlePrice[]) {
            const prices = utils.isDefined(dbArticlesById[itm.Id]) ? dbArticlesById[itm.Id]._Prices : {}
            assign(prices, utils.toRecord(itm.Prices, 'PriceGroupId'))
            await this.articles.update([catalogCode, itm.Id], { LocalUpdatedDate: new Date(), _Prices: prices })
          }

          break
        }
        case 'segmentations':
        {
          let dbArticlesById: Record<number, Article> = {}

          if (!firstTime) {
            const dbArticles = await this.articles.where('[CatalogCode+Id]').anyOf(data.map((itm: any) => [catalogCode, itm.Id])).toArray()
            dbArticlesById = dbArticles.reduce((acu, art) => (acu[art.Id] = art) && acu, {})
          }

          for (const itm of data as ArticleSegmentation[]) {
            let segs = {}

            if (!firstTime && utils.isDefined(dbArticlesById[itm.Id])) {
              // If new segmentations exist in the response, reset existing ones and add the new ones
              if (itm.Segmentations && Object.keys(itm.Segmentations).length > 0) {
                segs = utils.toRecord(itm.Segmentations, 'Id')
              }
            }
            else {
              // If it's the first time or no existing segmentations, assign the new segmentations
              if (itm.Segmentations && Object.keys(itm.Segmentations).length > 0) {
                segs = utils.toRecord(itm.Segmentations, 'Id')
              }
            }

            // Update the article in the database with the new or empty segmentations
            await this.articles.update([catalogCode, itm.Id], { LocalUpdatedDate: new Date(), _Segmentations: segs })
          }
          break
        }
        case 'sizes':
          for (const itm of data as ArticleSize[]) {
            await this.articles.update([catalogCode, itm.Id], { LocalUpdatedDate: new Date(), _Sizes: itm.Sizes })
          }
          break
        default:
          console.warn('Attempting to update invalid entity of articles', entity)
          break
      }
    })
  }

  async getCatalogsList(sortBy?: string, desc?: boolean) {
    // Try to call API
    try {
      const res = await Net.t1.get<ICatalog[]>('/catalogs')
      await this.catalogs.clear()
      await this.catalogs.bulkPut(res.data)
      await this.catalogs.where('Status').equals(0).delete() // Delete inactive catalogs
    }
    catch (error) {
      console.warn('Unable to fetch catalogs list', error)
    }
    const catalogCollection = this.catalogs.where('Visibility').anyOf([1, 3])
    if (sortBy) {
      if (desc) {
        return await catalogCollection.reverse().sortBy(sortBy)
      }
      else {
        return await catalogCollection.sortBy(sortBy)
      }
    }
    else {
      return await catalogCollection.toArray()
    }
  }

  async getLinkedCustomers(catalog: CatalogDetails, fetchLive: boolean = false, sortBy?: string) {
    try {
      if (fetchLive) {
        const res = await Net.t1.get(`/catalogs/${catalog.CatalogCode}/customers?status=1`)
        const data = res.data.map((lc: any) => new LinkedCustomer(catalog.CatalogCode, lc))
        await this.linkedCustomers.clear()
        await this.linkedCustomers.bulkPut(data)
      }
    }
    catch (e) {
      if (axios.isAxiosError(e) && e.response && e.response.status === 403) {
        await this.linkedCustomers.delete(catalog.CatalogCode)
      }
      console.warn('Unable to fetch linked customers from API', e)
    }
    if (sortBy) {
      return await this.linkedCustomers.where({ CatalogCode: catalog.CatalogCode }).sortBy(sortBy)
    }
    else {
      return await this.linkedCustomers.where({ CatalogCode: catalog.CatalogCode }).toArray()
    }
  }

  async getCustomerSegmentations(catalog: CatalogDetails, customerId: number, updateDBData: boolean = true) {
    if (updateDBData) {
      try {
        await this.loadEntitySinceLastCall('CustomerSegmentations', catalog)
      }
      catch (error) {
        console.warn('Unable to fetch linked customers from API', error)
      }
    }
    return await this.customerSegmentations.where({ CatalogCode: catalog.CatalogCode, Id: customerId }).first()
  }

  async getCatalogDetails(catalogCode: number, fetchLive = true) {
    try {
      if (fetchLive) {
        const res = await getCatalogDetails(catalogCode)
        // removing since we already has vetting list in catalog details
        // if (res.data.Status > 0) {
        //   res.data.AttributeVettingList = await Net.t1.get(`/catalogs/${catalogCode}/attributevalues`)
        // }
        await this.catalogDetails.put(new CatalogDetails(res.data, []))
      }
    }
    catch (e) {
      if (axios.isAxiosError(e) && e.response && e.response.status === 403) {
        await this.catalogDetails.delete(catalogCode)
      }
      console.warn('Unable to fetch catalog details from API', e)
    }
    return await this.catalogDetails.get(catalogCode) as CatalogDetails
  }

  async getSellerDetails(accountId: number) {
    try {
      const res = await Net.t1.get(`/sellers/${accountId}?status=0`)
      await this.sellerDetails.put(new SellerDetails(res.data))
    }
    catch (e) {
      if (axios.isAxiosError(e) && e.response && e.response.status === 403) {
        await this.sellerDetails.delete(accountId)
      }
      console.warn('Unable to fetch catalog details from API', e)
    }
    return await this.sellerDetails.get(accountId) as SellerDetails
  }

  async getCatalogTreeDefinition(catalogCode: number) {
    try {
      const res = await Net.t1.get(`/catalogs/${catalogCode}/trees`)
      const data = res.data.map((itm: any) => new CatalogTreeDef(catalogCode, itm))
      await this.catalogTreeDef.bulkPut(data)
      await this.catalogTreeDef.where('Status').equals(0).delete()
    }
    catch (error) {
      console.warn('Unable to get catalog tree definition', error)
    }
    return await this.catalogTreeDef.where({ CatalogCode: catalogCode }).toArray()
  }

  async getLinkedCustomer(catalogCode: number, customerId: number) {
    const customer = await this.linkedCustomers.get([catalogCode, customerId])
    try {
      if (utils.isDefined(customer)) {
        const res = await getCustomerAllocationGroups(catalogCode, customerId)
        customer.AllocationGroups = res.data.AllocationGroups.filter(x => x.Status > 0).map(itm => new LinkedCustomerAllocationGroup(itm))
        await this.linkedCustomers.put(customer)
      }
    }
    catch (error) {
      console.warn('Unable to fetch customer allocations from API', error)
    }
    return customer
  }

  async getCurrentCustomerSegmentations(sellerId: number, catalogCode: number, customerId: number) {
    try {
      const res = await Net.t1.get(`/sellers/${sellerId}/catalogs/${catalogCode}/customers/${customerId}/customersegmentations`)
      return new CustomerSegmentation(catalogCode, customerId, res.data.filter(segmentation => segmentation.Status === 1))
    }
    catch (error) {
      console.warn('Unable to get customer segmentations', error)
    }
  }

  async getArticleDropReasons(sellerId: number, status?: number) {
    try {
      const res = await getArticleDropReasons(sellerId)
      const data = res.data.map(itm => new DropReason(sellerId, itm))
      await this.dropReasons.bulkPut(data)
    }
    catch (error) {
      console.warn('Unable to get article drop reasons', error)
    }
    const query = this.dropReasons.where({ SellerAccountId: sellerId })
    if (utils.isDefined(status)) {
      query.and(x => x.Status === status)
    }
    return await query.toArray()
  }

  // async buildFlatTree(catalogCode: number, id: number): Promise<ITreeNode[]> {
  //   const treeDef = await this.catalogTreeDef.get([catalogCode, id])
  //   if (!treeDef) throw new Error('Tree Def not found!')
  //   const nodes: ITreeNode[] = []
  //   const nodeId: any = {}
  //   let parentId = ''
  //   await this.articles.where({ CatalogCode: catalogCode, Status: 1 }).each(art => {
  //     parentId = ''
  //     for (let index = 0; index < treeDef.Attributes.length; index++) {
  //       const att = treeDef.Attributes[index]
  //       if (art[att] == null || art[att] === '') {
  //         break
  //       }
  //       const id = parentId + '_' + art[att]
  //       if (!Object.prototype.hasOwnProperty.call(nodeId, id)) {
  //         nodes.push({ id: nodes.length + 1, text: art[att]?.toString() || '', parentId: parentId === '' ? undefined : nodeId[parentId] + 1, arts: [] })
  //         nodeId[id] = nodes.length - 1
  //       }
  //       nodes[nodeId[id]].arts.push(art.Id)
  //       parentId = id
  //     }
  //   })
  //   return nodes
  // }

  async buildTree(catalog: CatalogDetails, id: number, customerId: number, customerSegmentations: undefined | CustomerSegmentation, myAttributes?: Record<string, IMyAttribute>, ActiveArticlesOnly: boolean = true): Promise<ITreeNode[]> {
    const treeDef = await this.catalogTreeDef.get([catalog.CatalogCode, id])
    if (!treeDef) { throw new Error('Tree Def not found!') }
    const nodes: ITreeNode[] = []

    const filter = { CatalogCode: catalog.CatalogCode } as { [key: string]: any }
    if (ActiveArticlesOnly) { filter.Status = 1 }

    let filteredArticles = this.articles.where(filter)

    if (customerId && customerId > 0) {
      // Filter segmented articles based on the current customer
      filteredArticles = await this.filterSegmentedArticlesOfCurrentCustomer(filteredArticles, catalog, customerSegmentations)
    }

    await filteredArticles.each((art) => {
      this.addArticleToTreeDef(treeDef, art, nodes)
    })

    if (catalog.RequestsEnabled > 0) {
      filter.IsCreateArticleRequest = 1
      const requests = await this.requests.where(filter).toArray()
      for (const request of requests) {
        if (request.Content) {
          const requestArticle = await utils.getRequestArticle(catalog, request, this)
          this.addArticleToTreeDef(treeDef, requestArticle, nodes)
        }
      }
    }

    // Sort Tree
    // For now we will sort alphabetically
    const sortTree = function (nodes: ITreeNode[]) {
      nodes.sort((a, b) => {
        if (a.label === BLANK_TREE_NODE_LABEL) { return 1 }
        if (b.label === BLANK_TREE_NODE_LABEL) { return -1 }

        const la = a.label.toLowerCase()
        const lb = b.label.toLowerCase()
        if (la < lb) { return -1 }
        if (la > lb) { return 1 }
        return 0
      })
      nodes.forEach((child) => {
        if (child.children && child.children.length > 0) {
          sortTree(child.children)
        }
      })
    }
    sortTree(nodes)
    return nodes
  }

  addArticleToTreeDef(treeDef: CatalogTreeDef, art: Article, nodes: ITreeNode[]) {
    let currentNode = undefined as ITreeNode | undefined
    let parentNode = nodes
    const path = [] as { a: string, v?: string }[]
    let pathStr = ''

    for (let index = 0; index < treeDef.Attributes.length; index++) {
      const att = treeDef.Attributes[index]
      const attVals: { key?: string, label: string }[] = []
      if (utils.isDefined(art[att])) {
        if (Array.isArray(art[att])) {
          const multiValues = art[att] as string[]
          multiValues.forEach(v => attVals.push({ key: v, label: v }))
        }
        else {
          const value = art[att]!.toString()
          attVals.push({ key: value, label: value })
        }
      }
      if (attVals.length === 0) {
        attVals.push({ key: undefined, label: BLANK_TREE_NODE_LABEL })
      }
      attVals.forEach((itm) => {
        currentNode = parentNode.find(n => n.label === itm.label)
        path.push({ a: att, v: itm.key })
        pathStr += `\\${itm.key}`
        if (!currentNode) {
          currentNode = {
            label: itm.label,
            key: utils.hashCode(pathStr),
            path: [...path],
            checked: false,
            expanded: false,
            sortOrder: 0,
            children: [] as ITreeNode[],
            actions: [],
          }
          parentNode.push(currentNode)
        }

        parentNode = currentNode.children
      })
    }
  }

  async getCatalogMyArticles(catalog: CatalogDetails, myAttributes: Record<string, IMyAttribute>, myUsername: string, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup) {
    const articles = await this.articles.where({ CatalogCode: catalog.CatalogCode }).toArray()
    return await this.buildMyArticles(articles, catalog, {}, myAttributes, myUsername, retailPg, wholesalePg, outletPg)
  }

  /**
   * @description query and return sorted list of MyArticles based on catalog and articleIds passed to this method
   * @param { CatalogDetails } catalog CatalogDetails from which the articles will be fetched
   * @param { Record<string, CatalogDetails> } indexedLinkedCatalogDetails indexed linked catalogs by catalog code
   * @param { Record<string, IMyAttribute> } myAttributes indexed IMyAttribute
   * @param { string } myUsername
   * @param { Array<number> } articleIds list of article ids to query
   * @param { CatalogPriceGroup } retailPg selected retail price group from active catalog
   * @param { CatalogPriceGroup } wholesalePg selected wholesale price group from active catalog
   * @param { CatalogPriceGroup } outletPg selected outlet price group from active catalog
   * @param { boolean } findOnlyPrices if only prices are needed in the required objects
   * @returns { Promise<Array<MyArticle>> } promise which resolve to list of sorted MyArticles
   */
  async getMyArticles(catalog: CatalogDetails, indexedLinkedCatalogDetails: Record<string, CatalogDetails>, myAttributes: Record<string, IMyAttribute>, myUsername: string, articleIds: number[], retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup, findOnlyPrices?: boolean, currentCustomer?: LinkedCustomer | undefined, customerSegmentations?: undefined | CustomerSegmentation, returnNonSegmentedArticles: boolean = false) {
    let articles: Article[] = []
    if (utils.isDefined(currentCustomer) && currentCustomer!.CustomerId !== -1) {
      const filteredArticleCollection = await this.filterSegmentedArticlesOfCurrentCustomer(
        this.articles.where('[CatalogCode+Id]').anyOf(articleIds.map((itm: number) => [catalog.CatalogCode, itm])),
        catalog,
        customerSegmentations,
        returnNonSegmentedArticles,
      )
      articles = await filteredArticleCollection.toArray()
    }
    else {
      articles = await this.articles.where('[CatalogCode+Id]').anyOf(articleIds.map((itm: number) => [catalog.CatalogCode, itm])).toArray()
    }

    const res = await this.buildMyArticles(articles, catalog, indexedLinkedCatalogDetails, myAttributes, myUsername, retailPg, wholesalePg, outletPg, findOnlyPrices)
    return this.sortArticles(res)
  }

  /**
   * @description query and return list of MyArticles based on catalog and articleId passed to this method
   * @param { CatalogDetails } catalog CatalogDetails from which the article will be fetched
   * @param { Record<string, CatalogDetails> } indexedLinkedCatalogDetails indexed linked catalogs by catalog code
   * @param { Record<string, IMyAttribute> } myAttributes indexed IMyAttribute
   * @param { string } myUsername
   * @param { number } id article id to query
   * @param { CatalogPriceGroup } retailPg selected retail price group from active catalog
   * @param { CatalogPriceGroup } wholesalePg selected wholesale price group from active catalog
   * @param { CatalogPriceGroup } outletPg selected outlet price group from active catalog
   * @returns { Promise<Array<MyArticle>> } promise which resolve to list of MyArticles
   */
  async getMyArticlesById(catalog: CatalogDetails, indexedLinkedCatalogDetails: Record<string, CatalogDetails>, myAttributes: Record<string, IMyAttribute>, myUsername: string, id: number, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup) {
    const article = await this.articles.get({ CatalogCode: catalog.CatalogCode, Id: id })
    if (article) {
      return await this.buildMyArticles([article], catalog, indexedLinkedCatalogDetails, myAttributes, myUsername, retailPg, wholesalePg, outletPg)
    }
    return [] as MyArticle[]
  }

  async getRequestArticleById(catalog: CatalogDetails, requestId: number, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup) {
    const request = await this.requests.where({ CatalogCode: catalog.CatalogCode, Id: requestId }).first()
    if (request && request.Content) {
      const requestArticle = await utils.getRequestArticle(catalog, request, this)
      const retailPrice = retailPg ? requestArticle._Prices[retailPg.Id]?.Price : 0
      const wholesalePrice = wholesalePg ? requestArticle._Prices[wholesalePg.Id]?.Price : 0
      const outletPrice = outletPg ? requestArticle._Prices[outletPg.Id]?.Price : 0
      return new MyArticle(requestArticle, catalog.AssignedCatalogAttributes, retailPrice, wholesalePrice, outletPrice, [], true, [], [], [], catalog.Season, catalog.DataSourceTypeId, catalog!.Config.ArticlesCustomSortOrder)
    }
  }

  /**
   * @description query and return list of MyArticles based on catalog and articleNumber passed to this method
   * @param { CatalogDetails } catalog CatalogDetails from which the article will be fetched
   * @param { Record<string, CatalogDetails> } indexedLinkedCatalogDetails indexed linked catalogs by catalog code
   * @param { Record<string, IMyAttribute> } myAttributes indexed IMyAttribute
   * @param { string } myUsername
   * @param { string } articleNumbers article number to query
   * @param { CatalogPriceGroup } retailPg selected retail price group from active catalog
   * @param { CatalogPriceGroup } wholesalePg selected wholesale price group from active catalog
   * @param { CatalogPriceGroup } outletPg selected outlet price group from active catalog
   * @param { LinkedCustomer } currentCustomer current customer
   * @returns { Promise<Array<MyArticle>> } promise which resolve to list of MyArticles
   */
  async getMyArticlesByArticleNumbers(catalog: CatalogDetails, indexedLinkedCatalogDetails: Record<string, CatalogDetails>, myAttributes: Record<string, IMyAttribute>, myUsername: string, articleNumbers: string[], retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup, currentCustomer: LinkedCustomer | undefined = undefined, customerSegmentations?: undefined | CustomerSegmentation, returnNonSegmentedArticles: boolean = false) {
    const queryCriterion: Array<[number, string]> = []
    articleNumbers.forEach((articleNumber) => {
      queryCriterion.push([+catalog.CatalogCode, articleNumber])
    })
    let articles: Article[] = []
    if (utils.isDefined(currentCustomer)) {
      const filteredArticleCollection = await this.filterSegmentedArticlesOfCurrentCustomer(
        this.articles.where('[CatalogCode+ArticleNumber]').anyOf(queryCriterion),
        catalog,
        customerSegmentations,
        returnNonSegmentedArticles,
      )
      articles = await filteredArticleCollection.toArray()
    }
    else {
      articles = await this.articles.where('[CatalogCode+ArticleNumber]').anyOf(queryCriterion).toArray()
    }

    return await this.buildMyArticles(articles, catalog, indexedLinkedCatalogDetails, myAttributes, myUsername, retailPg, wholesalePg, outletPg)
  }

  /**
   * @description query and return list articles (MyArticles) that belongs to a model based on catalog and modelNumber passed to this method
   * @param { CatalogDetails } catalog CatalogDetails from which the article will be fetched
   * @param { Record<string, CatalogDetails> } indexedLinkedCatalogDetails indexed linked catalogs by catalog code
   * @param { Record<string, IMyAttribute> } myAttributes indexed IMyAttribute
   * @param { string } myUsername
   * @param { string } modelNumber model number to query
   * @param { boolean } activeOnly a boolean indicating weather to consider inactive articles or not
   * @param { CatalogPriceGroup } retailPg selected retail price group from active catalog
   * @param { CatalogPriceGroup } wholesalePg selected wholesale price group from active catalog
   * @param { CatalogPriceGroup } outletPg selected outlet price group from active catalog
   * @returns { Promise<Array<MyArticle>> } promise which resolve to list of MyArticles
   */
  async getMyArticlesByModelNumber(catalog: CatalogDetails, indexedLinkedCatalogDetails: Record<string, CatalogDetails>, myAttributes: Record<string, IMyAttribute>, myUsername: string, modelNumber: string, activeOnly = true, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup) {
    const arts: Article[] = await this.articles.where({ CatalogCode: catalog.CatalogCode, ModelNumber: modelNumber })
      .filter(art => !activeOnly || art.Status === 1).toArray()

    return await this.buildMyArticles(arts, catalog, indexedLinkedCatalogDetails, myAttributes, myUsername, retailPg, wholesalePg, outletPg)
  }

  /**
   * @description query and return active articles that represent the model
   * @param { Array<[string]> } modelNumbers model number array to query
   * @param { CatalogDetails } catalog CatalogDetails from which the article will be fetched
   * @param { Record<string, CatalogDetails> } indexedLinkedCatalogDetails indexed linked catalogs by catalog code
   * @param { Record<string, IMyAttribute> } myAttributes indexed IMyAttribute
   * @param { string } myUsername
   * @param { CatalogPriceGroup } retailPg selected retail price group from active catalog
   * @param { CatalogPriceGroup } wholesalePg selected wholesale price group from active catalog
   * @param { CatalogPriceGroup } outletPg selected outlet price group from active catalog
   * @returns { Promise<Array<MyArticle>> } promise which resolve to list of MyArticles
   */
  async getMyArticlesByModelNumbers(modelNumbers: string[], catalog: CatalogDetails, indexedLinkedCatalogDetails: Record<string, CatalogDetails>, myAttributes: Record<string, IMyAttribute>, myUsername: string, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup) {
    const queryCriterion: Array<[number, number, string]> = []
    modelNumbers.forEach((modelNumber) => {
      queryCriterion.push([+catalog.CatalogCode, 1, modelNumber])
    })
    const articles: Article[] = await this.articles.where('[CatalogCode+Status+ModelNumber]').anyOf(queryCriterion).toArray()

    return await this.buildMyArticles(articles.length ? articles : [], catalog, indexedLinkedCatalogDetails, myAttributes, myUsername, retailPg, wholesalePg, outletPg)
  }

  /**
   * @description query and return article that represent the model
   * @param { string } modelNumber model number to query
   * @param { CatalogDetails } catalog CatalogDetails from which the article will be fetched
   * @param { Record<string, CatalogDetails> } indexedLinkedCatalogDetails indexed linked catalogs by catalog code
   * @param { Record<string, IMyAttribute> } myAttributes indexed IMyAttribute
   * @param { string } myUsername
   * @param { CatalogPriceGroup } retailPg selected retail price group from active catalog
   * @param { CatalogPriceGroup } wholesalePg selected wholesale price group from active catalog
   * @param { CatalogPriceGroup } outletPg selected outlet price group from active catalog
   * @param { boolean } findOnlyPrices if only prices are needed in the required objects
   * @returns { Promise<Array<MyArticle>> } promise which resolve to list of MyArticles
   */
  async getMyArticleByModelNumber(modelNumber: string, catalog: CatalogDetails, indexedLinkedCatalogDetails: Record<string, CatalogDetails>, myAttributes: Record<string, IMyAttribute>, myUsername: string, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup, findOnlyPrices?: boolean) {
    const articles: Article[] = await this.articles.where({ CatalogCode: catalog.CatalogCode, Status: 1, ModelNumber: modelNumber }).sortBy('ArticleNumber')

    return await this.buildMyArticles(articles.length ? [articles[0]] : [], catalog, indexedLinkedCatalogDetails, myAttributes, myUsername, retailPg, wholesalePg, outletPg, findOnlyPrices)
  }

  /**
   * @description query and return article that represent the model
   * @param { Array<[string]> } modelNumbers model number array to query
   * @param { CatalogDetails } catalog CatalogDetails from which the article will be fetched
   * @param { Record<string, CatalogDetails> } indexedLinkedCatalogDetails indexed linked catalogs by catalog code
   * @param { Record<string, IMyAttribute> } myAttributes indexed IMyAttribute
   * @param { string } myUsername
   * @param { CatalogPriceGroup } retailPg selected retail price group from active catalog
   * @param { CatalogPriceGroup } wholesalePg selected wholesale price group from active catalog
   * @param { CatalogPriceGroup } outletPg selected outlet price group from active catalog
   * @returns { Promise<Array<MyArticle>> } promise which resolve to list of MyArticles
   */
  async getFirstActiveMyArticleByModelNumber(modelNumbers: string[], catalog: CatalogDetails, indexedLinkedCatalogDetails: Record<string, CatalogDetails>, myAttributes: Record<string, IMyAttribute>, myUsername: string, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup) {
    const queryCriterion: Array<[number, number, string]> = []
    modelNumbers.forEach((modelNumber) => {
      queryCriterion.push([+catalog.CatalogCode, 1, modelNumber])
    })
    let articles: Article[] = await this.articles.where('[CatalogCode+Status+ModelNumber]').anyOf(queryCriterion).sortBy('ArticleNumber')
    articles = uniqBy(articles, 'ModelNumber') as Array<Article>

    return await this.buildMyArticles(articles.length ? articles : [], catalog, indexedLinkedCatalogDetails, myAttributes, myUsername, retailPg, wholesalePg, outletPg)
  }

  /**
   * @description a method that return count of active article for a model based on catalog code and modelNumber passed to this method
   * @param modelNumber model number to query
   * @param catalogCode catalog code of catalog from which the article will be fetched
   * @returns { Promise<number> } promise which resolve to a number
   */
  async getModelSKUCount(modelNumber: string, catalogCode: number) {
    return await this.articles.where({ CatalogCode: catalogCode, Status: 1, ModelNumber: modelNumber }).count()
  }

  /**
   * @description query and return list articles (MyArticles) that belongs to models based on catalog code, status and model numbers passed to this method as queryCriterion
   * @param { Array<[number, number, string]> } queryCriterion 2D array with inner array containing catalogCode, status and modelNumber
   * @returns { Promise<Array<Article>> } promise which resolve to list of MyArticles
   */
  async getArticlesByCatalogAndModelCriterion(queryCriterion: Array<[number, number, string]>) {
    return await this.articles.where('[CatalogCode+Status+ModelNumber]').anyOf(queryCriterion).toArray()
  }

  /**
   * @description checks if an article with provided article id exists in specific catalog or not
   * @param {number} articleId
   * @param {number} catalogCode
   * @returns {Promise<boolean>} boolean
   */
  async doesArticleIdExistInCatalog(articleId: number, catalogCode: number) {
    if (await this.articles.get({ CatalogCode: catalogCode, Id: articleId })) {
      return true
    }
    else {
      return false
    }
  }

  /**
   * @description checks if an article with provided model number exists in specific catalog or not
   * @param {string} modelNumber
   * @param {number} catalogCode
   * @returns {Promise<boolean>} boolean
   */
  async doesModelNumberExistInCatalog(modelNumber: string, catalogCode: number) {
    if (await this.articles.get({ CatalogCode: catalogCode, ModelNumber: modelNumber })) {
      return true
    }
    else {
      return false
    }
  }

  async getArticlesByCriteria(catalog: CatalogDetails, myAttributes: Record<string, IMyAttribute>, criteria: FilterCriteria[], matchAll: boolean, userName: string, currentCustomer?: LinkedCustomer, customerSegmentations?: undefined | CustomerSegmentation, matchCurrentCatalogOnlyFromCriteria = false, loadRequests = false, since: Date | null = null, eventSource: string = '') {
    let statusIndex = -1
    let seasonIndex = -1
    for (let i = 0; i < criteria.length; i++) {
      if (criteria[i].attribute === 'Status') {
        statusIndex = i
      }
      else if (criteria[i].attribute === '_Seasons') {
        seasonIndex = i
      }
    }
    const catalogCodes: Array<number> = []
    if (!matchCurrentCatalogOnlyFromCriteria) {
      catalogCodes.push(catalog.CatalogCode)
    }
    if (seasonIndex >= 0 && utils.isDefined(criteria[seasonIndex]) && utils.isDefined(criteria[seasonIndex].multipleVals)) {
      criteria[seasonIndex].multipleVals!.forEach((val) => {
        catalogCodes.push(Number(val))
      })
    }

    // if status is not part of filter criteria fetch active articles
    const statusVal = statusIndex >= 0 && criteria[statusIndex]?.statusVal != null ? criteria[statusIndex]?.statusVal as number : 1

    console.debug('Fetching articles by criteria', criteria)

    // TODO: Keep an eye on this approach as it is loading all articles in memory then filtering them, might become a memory issue
    let articleCollection = statusVal === -1
      ? this.articles.where('CatalogCode').anyOf(catalogCodes)
      : this.articles.where('[CatalogCode+Status]').anyOf(catalogCodes.map(catalogCode => [catalogCode, statusVal]))

    const favoriteTags = await this.favoriteTags.where('[CatalogCode+CreatedByUserName+Status]').anyOf(catalogCodes.map(catalogCode => [catalogCode, userName, 1])).toArray()
    const indexedFavoriteTags: Record<string, FavoriteTag[]> = {}
    favoriteTags.forEach((favoriteTag) => {
      if (favoriteTag.Articles && favoriteTag.Articles.length) {
        favoriteTag.Articles.forEach((articleId) => {
          const key = `${favoriteTag.CatalogCode}//${articleId}`
          if (!indexedFavoriteTags.hasOwnProperty(key)) {
            indexedFavoriteTags[key] = []
          }
          indexedFavoriteTags[key].push(favoriteTag)
        })
      }
    })

    if (utils.isDefined(currentCustomer)) { // not fullrange filter only segmentaed articles
      articleCollection = await this.filterSegmentedArticlesOfCurrentCustomer(articleCollection, catalog, customerSegmentations)
    }

    const articles = since !== null
      ? await articleCollection.filter((art) => {
        const favoriteTagKey = `${art.CatalogCode}//${art.Id}`
        art._FavoriteTags = utils.isDefined(indexedFavoriteTags[favoriteTagKey]) ? indexedFavoriteTags[favoriteTagKey].map(itm => new SimpleFavoriteTag(itm.Id, itm.Tag, itm.Color)) : []
        return this.articleMatchesCriteria(art, myAttributes, criteria, matchAll)
      }).and((art) => {
        // when updating favorites
        if (eventSource !== '' && eventSource === 'Favorite') {
          return true
        }
        else {
          return art.LocalUpdatedDate !== null && art.LocalUpdatedDate >= since
        }
      })
        .limit(ARTICLES_LIMIT).toArray()
      : await articleCollection.filter((art) => {
        const favoriteTagKey = `${art.CatalogCode}//${art.Id}`
        art._FavoriteTags = utils.isDefined(indexedFavoriteTags[favoriteTagKey]) ? indexedFavoriteTags[favoriteTagKey].map(itm => new SimpleFavoriteTag(itm.Id, itm.Tag, itm.Color)) : []
        return this.articleMatchesCriteria(art, myAttributes, criteria, matchAll)
      }).limit(ARTICLES_LIMIT).toArray()

    if (loadRequests) {
      const requests = await (statusVal === -1
        ? this.requests.where({ CatalogCode: catalog.CatalogCode, IsCreateArticleRequest: 1 })
        : this.requests.where({ CatalogCode: catalog.CatalogCode, Status: statusVal, IsCreateArticleRequest: 1 })
      ).limit(REQUESTS_LIMIT).toArray()

      for (const request of requests) {
        if (request.Content) {
          const requestArticle = await utils.getRequestArticle(catalog, request, this)
          if (this.articleMatchesCriteria(requestArticle, myAttributes, criteria, matchAll)) {
            articles.push(requestArticle)
          }
        }
      }
    }

    return articles
  }

  async getModelsByCriteria(catalog: CatalogDetails, myAttributes: Record<string, IMyAttribute>, criteria: FilterCriteria[], matchAll: boolean, userName: string, currentCustomer: LinkedCustomer | undefined, customerSegmentations?: undefined | CustomerSegmentation, ignoreAttributeCriteria = false, since: Date | null = null) {
    let statusIndex = -1
    let seasonIndex = -1
    const visitedModel = {}
    for (let i = 0; i < criteria.length; i++) {
      if (criteria[i].attribute === 'Status') {
        statusIndex = i
      }
      else if (criteria[i].attribute === '_Seasons') {
        seasonIndex = i
      }
    }
    const catalogCodes = [catalog.CatalogCode]
    if (seasonIndex >= 0 && utils.isDefined(criteria[seasonIndex]) && utils.isDefined(criteria[seasonIndex].multipleVals)) {
      criteria[seasonIndex].multipleVals!.forEach((val) => {
        catalogCodes.push(val)
      })
    }

    // if status is not part of filter criteria fetch active articles
    const statusVal = statusIndex >= 0 && criteria[statusIndex]?.statusVal != null ? criteria[statusIndex]?.statusVal as number : 1

    console.debug('Fetching models by criteria', criteria)

    let articleCollection: Collection<Article, IndexableType>
    if (statusVal === -1) {
      articleCollection = this.articles.where('CatalogCode').anyOf(catalogCodes)
    }
    else {
      articleCollection = this.articles.where('[CatalogCode+Status]').anyOf(catalogCodes.map(catalogCode => [catalogCode, statusVal]))
    }
    if (utils.isDefined(currentCustomer)) { // not fullrange filter only segmentaed articles
      //  method read customer segmentation and passarticles segmentation
      // check only for current catalog and not request
      // if browse by model when updating articles we will update all the articles irrespective of issegmented
      articleCollection = await this.filterSegmentedArticlesOfCurrentCustomer(articleCollection, catalog, customerSegmentations)
    }

    const favoriteTags = await this.favoriteTags.where('[CatalogCode+CreatedByUserName+Status]').anyOf(catalogCodes.map(catalogCode => [catalogCode, userName, 1])).toArray()
    const indexedFavoriteTags: Record<string, FavoriteTag[]> = {}
    favoriteTags.forEach((favoriteTag) => {
      if (favoriteTag.Articles && favoriteTag.Articles.length) {
        favoriteTag.Articles.forEach((articleId) => {
          const key = `${favoriteTag.CatalogCode}//${articleId}`
          if (!indexedFavoriteTags.hasOwnProperty(key)) {
            indexedFavoriteTags[key] = []
          }
          indexedFavoriteTags[key].push(favoriteTag)
        })
      }
    })

    let articles = since !== null ? await articleCollection.and(itm => itm.LocalUpdatedDate !== null && itm.LocalUpdatedDate > since).sortBy('ArticleNumber') : await articleCollection.sortBy('ArticleNumber')

    const activeModelIds = new Set<number>()
    articles = articles.filter((article) => {
      const catalogModelIdKey = `${article.CatalogCode}//${article.ModelId}`
      const favoriteTagKey = `${article.CatalogCode}//${article.Id}`
      article._FavoriteTags = utils.isDefined(indexedFavoriteTags[favoriteTagKey])
        ? indexedFavoriteTags[favoriteTagKey].map(itm => new SimpleFavoriteTag(itm.Id, itm.Tag, itm.Color))
        : []
      const matched = !visitedModel.hasOwnProperty(catalogModelIdKey) && (ignoreAttributeCriteria || this.articleMatchesCriteria(article, myAttributes, criteria, matchAll))
      if (matched) {
        visitedModel[catalogModelIdKey] = 1
      }
      if (statusVal === 0 && article.Status === 1) {
        activeModelIds.add(article.ModelId)
      }
      return matched
    })

    if (activeModelIds.size > 0) {
      for (let i = articles.length - 1; i >= 0; i--) {
        const article = articles[i]
        if (activeModelIds.has(article.ModelId)) {
          articles.splice(i, 1)
        }
      }
    }

    if (!ignoreAttributeCriteria) {
      articles = articles.slice(0, ARTICLES_LIMIT)
    }
    return articles
  }

  /**
   * @description query and return list of MyArticles based on catalog and CatalogArticleId passed to this method
   * @param { CatalogDetails } catalog CatalogDetails from which the article will be fetched
   * @param { Record<string, CatalogDetails> } indexedLinkedCatalogDetails indexed linked catalogs by catalog code
   * @param { Record<string, IMyAttribute> } myAttributes indexed IMyAttribute
   * @param { string } myUsername
   * @param { number } catalogArticleId article id to query
   * @param { CatalogPriceGroup } retailPg selected retail price group from active catalog
   * @param { CatalogPriceGroup } wholesalePg selected wholesale price group from active catalog
   * @param { CatalogPriceGroup } outletPg selected outlet price group from active catalog
   * @returns { Promise<Array<MyArticle>> } promise which resolve to list of MyArticles
   */
  async getMyArticlesByCatalogArticleId(catalog: CatalogDetails, indexedLinkedCatalogDetails: Record<string, CatalogDetails>, myAttributes: Record<string, IMyAttribute>, myUsername: string, catalogArticleId: string, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup) {
    const article = await this.articles.get({ CatalogArticleId: catalogArticleId.toString() })
    if (article) {
      return await this.buildMyArticles([article], catalog, indexedLinkedCatalogDetails, myAttributes, myUsername, retailPg, wholesalePg, outletPg)
    }
    return [] as MyArticle[]
  }

  // this will return the article object
  async getArticle(catalog: CatalogDetails, articleId: number, currentCustomer: LinkedCustomer | undefined = undefined, customerSegmentations?: undefined | CustomerSegmentation) {
    if (utils.isDefined(currentCustomer)) {
      const filteredArticles = await this.filterSegmentedArticlesOfCurrentCustomer(this.articles, catalog, customerSegmentations)
      // return the specific article by Id
      return filteredArticles.find(article => article.Id === articleId)
    }
    else {
      return await this.articles.where({ CatalogCode: catalog.CatalogCode, Id: articleId }).first()
    }
  }

  async getArticlesOrMyArticlesByCatalogCodeModelId(catalog: CatalogDetails, indexedLinkedCatalogDetails: Record<string, CatalogDetails>, myAttributes: Record<string, IMyAttribute>, myUsername: string, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup, queryCriterion?: Array<[number, number]>, modelId?: number, isMyArticle: boolean = false, currentCustomer?: LinkedCustomer | undefined, customerSegmentations?: undefined | CustomerSegmentation, returnNonSegmentedArticles: boolean = false) {
    let articles: Article[] = []

    if (queryCriterion && queryCriterion.length > 0) {
      articles = await this.articles.where('[CatalogCode+ModelId]').anyOf(queryCriterion).toArray()
    }
    else {
      articles = await this.articles.where({ CatalogCode: catalog.CatalogCode, ModelId: modelId }).toArray()
    }

    if (utils.isDefined(currentCustomer)) {
      const filteredArticleCollection = await this.filterSegmentedArticlesOfCurrentCustomer(
        articles,
        catalog,
        customerSegmentations,
        returnNonSegmentedArticles,
      )
      articles = await filteredArticleCollection
    }

    if (!isMyArticle) {
      return articles
    }
    else {
      return await this.buildMyArticles(articles, catalog, indexedLinkedCatalogDetails, myAttributes, myUsername, retailPg, wholesalePg, outletPg)
    }
  }

  async getArticleCollectionByCatalogCodeArticleIds(catalog: CatalogDetails, articleIds: Set<number>, includeInactiveArticles: boolean = false, currentCustomer: LinkedCustomer | undefined, customerSegmentations?: undefined | CustomerSegmentation, returnNonSegmentedArticles: boolean = false) {
    let articleCollection = this.articles.where('[CatalogCode+Id]')
      .anyOf(Array.from(articleIds).map(articleId => [catalog.CatalogCode, articleId]))
      .filter(art => includeInactiveArticles || art.Status === 1)

    if (utils.isDefined(currentCustomer)) { // not fullrange filter only segmentaed articles
      articleCollection = await this.filterSegmentedArticlesOfCurrentCustomer(articleCollection, catalog, customerSegmentations, returnNonSegmentedArticles)
    }

    return articleCollection
  }

  // from export
  async getArticleCollectionByCatalogCode(catalog: CatalogDetails, includeInactiveArticles: boolean = false, currentCustomer: LinkedCustomer | undefined, customerSegmentations: undefined | CustomerSegmentation) {
    let articleCollection = this.articles.where('CatalogCode').equals(catalog.CatalogCode)
      .filter(art => includeInactiveArticles || art.Status === 1)

    if (!includeInactiveArticles && utils.isDefined(currentCustomer)) { // not fullrange filter only segmentaed articles
      articleCollection = await this.filterSegmentedArticlesOfCurrentCustomer(articleCollection, catalog, customerSegmentations)
    }

    return articleCollection
  }

  async getArticlesByCatalogCodeArticleNumbers(catalog: CatalogDetails, queryCriterion: Array<[number, string]>, includeInactiveArticles: boolean = false, currentCustomer: LinkedCustomer | undefined, customerSegmentations: undefined | CustomerSegmentation) {
    let articleCollection = this.articles.where('[CatalogCode+ArticleNumber]').anyOf(queryCriterion)
      .filter(art => includeInactiveArticles || art.Status === 1)

    if (!includeInactiveArticles && utils.isDefined(currentCustomer)) { // not fullrange filter only segmentaed articles
      articleCollection = await this.filterSegmentedArticlesOfCurrentCustomer(articleCollection, catalog, customerSegmentations)
    }

    return articleCollection.toArray()
  }

  async getArticlesByCatalogCodeStatusModelNumbers(catalog: CatalogDetails, queryCriterion: Array<[number, number, string]>, includeInactiveArticles: boolean = false, currentCustomer: LinkedCustomer | undefined, customerSegmentations: undefined | CustomerSegmentation) {
    let articleCollection = this.articles.where('[CatalogCode+Status+ModelNumber]').anyOf(queryCriterion)
      .filter(art => includeInactiveArticles || art.Status === 1)

    if (!includeInactiveArticles && utils.isDefined(currentCustomer)) { // not fullrange filter only segmentaed articles
      articleCollection = await this.filterSegmentedArticlesOfCurrentCustomer(articleCollection, catalog, customerSegmentations)
    }

    return articleCollection.toArray()
  }

  async getArticlesByCatalogCodeStatus(catalog: CatalogDetails, statusVal: number, currentCustomer: LinkedCustomer | undefined, customerSegmentations: undefined | CustomerSegmentation, limit?: number | undefined) {
    let articleCollection = this.articles.where('[CatalogCode+Status]').equals([catalog.CatalogCode, statusVal])

    if (utils.isDefined(currentCustomer)) { // not fullrange filter only segmentaed articles
      articleCollection = await this.filterSegmentedArticlesOfCurrentCustomer(articleCollection, catalog, customerSegmentations)
    }

    if (limit) {
      return articleCollection.limit(limit).toArray()
    }
    return articleCollection.toArray()
  }

  async getArticlesByCatalogCodeModelNumber(catalog: CatalogDetails, modelNumber: string, currentCustomer: LinkedCustomer | undefined, customerSegmentations: undefined | CustomerSegmentation) {
    let articleCollection = this.articles.where('[CatalogCode+ModelNumber]').equals([catalog.CatalogCode, modelNumber])

    if (utils.isDefined(currentCustomer)) {
      articleCollection = await this.filterSegmentedArticlesOfCurrentCustomer(articleCollection, catalog, customerSegmentations)
    }

    return articleCollection
  }

  // Helper method
  async filterSegmentedArticlesOfCurrentCustomer(articleCollection, catalog: CatalogDetails, customerSegmentations: undefined | CustomerSegmentation, returnNonSegmentedArticles: boolean = false) {
    const currentCustomerSegmentations = customerSegmentations && customerSegmentations.Segmentations && customerSegmentations.Segmentations.length ? customerSegmentations.Segmentations : []
    return articleCollection.filter((article) => {
    // Check if the article is segmented for current catalog
      let isSegmented = true
      if (article.CatalogCode === catalog.CatalogCode) {
        isSegmented = this.isArticleSegmented(
          article._Segmentations,
          currentCustomerSegmentations,
          catalog._IndexedCatalogSegmentation,
        )
      }

      // If the article is not segmented and the flag is true, return the article and set _IsNonSegmented to true
      if (!isSegmented && returnNonSegmentedArticles) {
        article._IsNonSegmented = true
        return true
      }

      return isSegmented
    })
  }

  isArticleSegmented(articleSegmentations, currentCustomerSegmentations: Segmentation[], indexedCatalogSegmentation) {
    for (const segmentationId in articleSegmentations) {
      if (
        indexedCatalogSegmentation[segmentationId] && indexedCatalogSegmentation[segmentationId].Status === 1
        && currentCustomerSegmentations.some(segmentation => segmentation.SegmentationId === Number.parseInt(segmentationId))
      ) {
        return true
      }
    }
    return false
  }

  // Removing this function, we should use liveArticles instead
  // async getMyArticlesByCriteria(catalog: CatalogDetails, myAttributes: Record<string, MyAttribute>, myUsername: string, criteria: FilterCriteria[], matchAll: boolean, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup) {
  //   console.time('getMyArticlesByCriteria')
  //   const arts = await this.getArticlesByCriteria(catalog, myAttributes, criteria, matchAll)

  //   console.debug('Done fetching, found', arts.length)

  //   let res = await this.buildMyArticles(arts, catalog, myAttributes, myUsername, retailPg, wholesalePg, outletPg)
  //   console.debug('Built all articles')
  //   res = this.sortArticles(res)
  //   console.timeEnd('getMyArticlesByCriteria')
  //   return res

  // }

  /**
   * @description takes articles and assign user data to them and return list of MyArticles
   * @param  { Array<Article> } articles list of articles
   * @param { CatalogDetails } catalog catalog that the articles belongs to
   * @param { Record<string, CatalogDetails> } indexedLinkedCatalogDetails indexed linked catalogs by catalog code
   * @param { Record<string, IMyAttribute> } myAttributes indexed IMyAttribute
   * @param { string } myUsername
   * @param { CatalogPriceGroup } retailPg selected retail price group from  active catalog
   * @param { CatalogPriceGroup } wholesalePg selected wholesale price group from active catalog
   * @param { CatalogPriceGroup } outletPg selected outlet price group from active catalog
   * @param { boolean } findOnlyPrices if only prices are needed in the required objects
   * @returns { Array<MyArticle> } Array of MyArticle
   */
  async buildMyArticles(articles: Article[], catalog: CatalogDetails, indexedLinkedCatalogDetails: Record<string, CatalogDetails>, myAttributes: Record<string, IMyAttribute>, myUsername: string, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup, findOnlyPrices?: boolean) {
    const res = [] as MyArticle[]

    let allResources: Resource[] = []
    let allFavs: FavoriteTag[] = []
    const allAssets: DuneAsset[] = []
    let allAllocations: Allocation[] = []
    if (articles.length > 0 && !findOnlyPrices) {
      await this.transaction('r', this.favoriteTags, this.allocations, this.resources, async () => {
        // console.debug('Loading all prices')
        // allPrices = utils.arrayToNumberDictionary(await this.prices.where('[CatalogCode+Id]').anyOf(articles.map(art => [catalog.CatalogCode, art.Id])).toArray(), 'Id')
        // console.debug('Loading all delivery dates')
        // allDeliveryDates = utils.arrayToNumberDictionary(await this.deliveryDates.where('[CatalogCode+Id]').anyOf(articles.map(art => [catalog.CatalogCode, art.Id])).toArray(), 'Id')
        // console.debug('Loading all segmentations')
        // allSegmentations = utils.arrayToNumberDictionary(await this.segmentations.where('[CatalogCode+Id]').anyOf(articles.map(art => [catalog.CatalogCode, art.Id])).toArray(), 'Id')
        // console.debug('Loading all sizes')
        // allSizes = utils.arrayToNumberDictionary(await this.sizes.where('[CatalogCode+Id]').anyOf(articles.map(art => [catalog.CatalogCode, art.Id])).toArray(), 'Id')
        console.debug('Loading all resources')
        allResources = (await this.resources.where('Articles').anyOf(articles.map(art => art.Id))
          .filter(itm => itm.CatalogCode === catalog.CatalogCode && itm.Status === 1)
          .toArray())
          .sort((a, b) => {
            const result = a.SortOrder - b.SortOrder
            if (result !== 0) {
              return result
            }
            else if (a.ResourceCategory.toString().trim().toLowerCase() + a.ResourceName.toString().trim().toLowerCase() < b.ResourceCategory.toString().trim().toLowerCase() + b.ResourceName.toString().trim().toLowerCase()) {
              return -1
            }
            else if (a.ResourceCategory.toString().trim().toLowerCase() + a.ResourceName.toString().trim().toLowerCase() > b.ResourceCategory.toString().trim().toLowerCase() + b.ResourceName.toString().trim().toLowerCase()) {
              return 1
            }
            else {
              return 0
            }
          })
        console.debug('Loading all Favs')
        // allFavs = await this.favoriteTags.where('Articles').anyOf(articles.map(art => art.Id)).filter(itm => itm.CatalogCode === catalog.CatalogCode && itm.Status === 1 && itm.CreatedByUserName === myUsername).toArray()
        allFavs = await this.favoriteTags.where('[CatalogCode+CreatedByUserName+Status]').equals([catalog.CatalogCode, myUsername, 1]).toArray()

        console.debug('Loading all Allocations')
        allAllocations = await this.allocations.where('Articles').anyOf(articles.map(art => art.Id)).toArray()
      })
    }

    console.debug('Processing articles')
    for (let index = 0; index < articles.length; index++) {
      const article = articles[index]
      let retailPrice: Price | null = null
      let wholesalePrice: Price | null = null
      let outletPrice: Price | null = null

      // article belong to active catalog
      if (!indexedLinkedCatalogDetails.hasOwnProperty(article.CatalogCode)) {
        if (retailPg != null) {
          retailPrice = article._Prices[retailPg.Id]
        }
        if (wholesalePg != null) {
          wholesalePrice = article._Prices[wholesalePg.Id]
        }
        if (outletPg != null) {
          outletPrice = article._Prices[outletPg.Id]
        }
      }
      else { // article belongs to linked catalog
        const linkedCatalog = indexedLinkedCatalogDetails[article.CatalogCode]
        if (retailPg != null) {
          const linkedPriceGroup = linkedCatalog._IndexedCatalogPriceGroupByLowercaseName[retailPg.Name.toString().trim().toLowerCase()]
          if (linkedPriceGroup) {
            retailPrice = article._Prices[linkedPriceGroup.Id]
          }
        }
        if (wholesalePg != null) {
          const linkedPriceGroup = linkedCatalog._IndexedCatalogPriceGroupByLowercaseName[wholesalePg.Name.toString().trim().toLowerCase()]
          if (linkedPriceGroup) {
            wholesalePrice = article._Prices[linkedPriceGroup.Id]
          }
        }
        if (outletPg != null) {
          const linkedPriceGroup = linkedCatalog?._IndexedCatalogPriceGroupByLowercaseName[outletPg.Name.toString().trim().toLowerCase()]
          if (linkedPriceGroup) {
            outletPrice = article._Prices[linkedPriceGroup.Id]
          }
        }
      }

      // Get Resources
      const resources = allResources.filter(r => r.Articles.includes(article.Id))
      // Get Favorites
      const myFavs = allFavs.filter(fav => fav.Articles.includes(article.Id))
      // Get assets
      const assets = allAssets.filter(itm => itm.ImageSet === article.ArticleNumber).sort((a, b) => a.SortOrder - b.SortOrder)
      // Get Allocations
      const allocations = allAllocations.filter(r => r.Articles.includes(article.Id))

      const simpleFavTags = myFavs.map(itm => new SimpleFavoriteTag(itm.Id, itm.Tag, itm.Color))
      const newArt = new MyArticle(article, catalog.AssignedCatalogAttributes, retailPrice?.Price || 0, wholesalePrice?.Price || 0, outletPrice?.Price || 0, resources, true, assets, simpleFavTags, allocations, catalog.Season, catalog.DataSourceTypeId, catalog!.Config.ArticlesCustomSortOrder)
      res.push(newArt)
    }
    return res
  }

  /**
   * @description takes articles and assign user data to them and return list of MyArticles
   * @param  { Array<Article> } articles list of articles
   * @param { Array<CatalogAttribute> } assignedCatalogAttributes catalog that the articles belongs to
   * @param { Record<string, IMyAttribute> } myAttributes indexed IMyAttribute
   * @param { string } catalogSeason
   * @param { number } catalogDataSourceTypeId
   * @param { Record<string, number>, orderedAttributeSystemName: string[] | undefined } catalogCOnfigSortOrder
   * @param { Record<string, number> } indexedCatalogPriceGroupByLowercaseName
   * @param { CatalogPriceGroup } retailPg selected retail price group from  active catalog
   * @param { CatalogPriceGroup } wholesalePg selected wholesale price group from active catalog
   * @param { CatalogPriceGroup } outletPg selected outlet price group from active catalog
   * @returns { Array<MyArticle> } Array of MyArticle
   */
  buildMyArticlesForLinkedCatalogArticleForDetails(articles: Article[], assignedCatalogAttributes: CatalogAttribute[], catalogSeason: string, catalogDataSourceTypeId: number, catalogCOnfigSortOrder: { sortOrder: Record<string, number>, orderedAttributeSystemName: string[] } | undefined, indexedCatalogPriceGroupByLowercaseName: Record<string, number>, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup) {
    const res = [] as MyArticle[]
    console.debug('Processing linked catalog articles')
    for (let index = 0; index < articles.length; index++) {
      const article = articles[index]
      let retailPrice: Price | null = null
      let wholesalePrice: Price | null = null
      let outletPrice: Price | null = null

      if (retailPg != null) {
        const linkedPriceGroup = indexedCatalogPriceGroupByLowercaseName[retailPg.Name.toString().trim().toLowerCase()]
        if (linkedPriceGroup) {
          retailPrice = article._Prices[linkedPriceGroup]
        }
      }
      if (wholesalePg != null) {
        const linkedPriceGroup = indexedCatalogPriceGroupByLowercaseName[wholesalePg.Name.toString().trim().toLowerCase()]
        if (linkedPriceGroup) {
          wholesalePrice = article._Prices[linkedPriceGroup]
        }
      }
      if (outletPg != null) {
        const linkedPriceGroup = indexedCatalogPriceGroupByLowercaseName[outletPg.Name.toString().trim().toLowerCase()]
        if (linkedPriceGroup) {
          outletPrice = article._Prices[linkedPriceGroup]
        }
      }

      const newArt = new MyArticle(article, assignedCatalogAttributes, retailPrice?.Price || 0, wholesalePrice?.Price || 0, outletPrice?.Price || 0, [], true, [], [], [], catalogSeason, catalogDataSourceTypeId, catalogCOnfigSortOrder)
      res.push(newArt)
    }
    return res
  }

  sortArticles(arts: MyArticle[]) {
    // Sort by sort order, then by article number

    return arts.sort((a, b) => {
      if (a.SortOrder === b.SortOrder) {
        const la = a.ArticleNumber ? a.ArticleNumber.toLowerCase() : ''
        const lb = b.ArticleNumber ? b.ArticleNumber.toLowerCase() : ''

        return la > lb ? 1 : la < lb ? -1 : 0
      }
      return a.SortOrder > b.SortOrder ? 1 : -1
    })
  }

  articleMatchesCriteria(art: Article | MyArticle, myAttributes: Record<string, IMyAttribute>, criteria: FilterCriteria[], matchAll: boolean, checkOwnProperty = false): boolean {
    if (matchAll) {
      return criteria.every(c => c.matchesRecord(art, checkOwnProperty))
    }
    else {
      return criteria.some(c => c.matchesRecord(art, checkOwnProperty))
    }
  }

  async createUpdateFavorite(catalogCode: number, fav: FavoriteTag) {
    const res = await utils.tryAsync(createUpdateFavorite(catalogCode, fav.toCreateUpdate()))
    let newTag = fav
    if (res.success) {
      newTag = new FavoriteTag(catalogCode, res.result.data)
    }
    newTag.LocalUpdatedDate = new Date()
    await this.favoriteTags.put(newTag)
  }

  async deleteFavorite(catalogCode: number, favs: FavoriteTag[]) {
    const requestObject: UpdateFavoritesStatusModel[] = favs.map(f => ({ Id: f.Id, Status: 0 }))
    const res = await utils.tryAsync(updateFavoritesStatus(catalogCode, requestObject))
    if (res.success) {
      await this.favoriteTags.bulkPut(favs)
    }
  }

  async buildMyAttributes(catalog: CatalogDetails, linkedCatalogDetails: Record<number, CatalogDetails>, myUsername: string, ActiveArticlesOnly: boolean, customer: LinkedCustomer | undefined = undefined, customerSegmentation: CustomerSegmentation | undefined = undefined, activeArticleStateList: Array<ArticleStateModel> | [], isViewUnAssortedArticlesRestricted: boolean) {
    const attDic: Record<string, IMyAttribute> = {}
    for (const systemName in appConstants.staticAttributes) {
      const attribute = appConstants.staticAttributes[systemName]
      if (catalog.RequestsEnabled > 0 && attribute.IsRequest) {
        attDic[systemName] = attribute
      }
      else {
        attDic[systemName] = attribute
      }
    }
    // for child catalog add Not Assorted and Globally Dropped statuses
    if (catalog.DataSourceTypeId === appConstants.catalogTypes.inherited) {
      // TODO implement the restrict not assorted prvilage part
      attDic.Status.FilterLookup = new Map([
        [-1, 'All'],
        [1, 'Active'],
        [3, 'Globally Dropped'],
      ])
      // for Inherited Catalog dont show not assorted articles articles if the UI_ViewUnAssortedArticles is not assigned.
      if (!isViewUnAssortedArticlesRestricted) {
        attDic.Status.FilterLookup.set(2, 'Not Assorted')
      }
    }
    else {
      attDic.Status.FilterLookup = new Map([
        [-1, 'All'],
        [1, 'Active'],
        [0, 'Inactive'],
      ])
    }
    const staticAttributesWithFilterLookupInArticle = Object.values(attDic).filter(attribute => attribute.FilterLookupFromArticle)
    const lookupFromArticleAttributesData: string[] = staticAttributesWithFilterLookupInArticle.length ? staticAttributesWithFilterLookupInArticle.map(attribute => attribute.SystemName) : []
    const filter = { CatalogCode: catalog.CatalogCode } as Record<string, any>
    if (ActiveArticlesOnly) {
      filter.Status = 1
    }

    // Load all dynamic attributes
    catalog.AssignedCatalogAttributes.forEach((att) => {
      const newAtt: IMyAttribute = {
        AttributeType: att.AttributeTypeId,
        AttributeSource: att.AttributeSource,
        Creatable: att.Creatable,
        DisplayName: att.AttributeDisplayName,
        Editable: att.AttributeTypeId === AttributeType.Calc ? false : att.Editable, // for calc type, editable is false
        ReadOnly: att.AttributeTypeId === AttributeType.Calc, // for calc type ReadOnly = true
        AllowFiltering: true,
        FilterLookup: new Map(),
        IsRequired: att.IsRequired,
        IsSeasonless: att.IsSeasonless,
        Overridable: att.Overridable,
        IsStatic: false,
        IsPersonal: false,
        SystemName: att.AttributeSystemName,
        ValidationExpression: att.ValidationExpression,
        ValidationMessage: att.ValidationMessage,
        VettingList: att.AllowedValues,
        Visible: att.Visible,
        IsModelLevel: att.IsModelLevel || att.IsSeasonlessModelAttribute,
        IsRequest: false,
        Criteria: {},
        calcTypeDataType: undefined,
        VisibleInListByDefault: att.VisibleInListByDefault,
      }

      // Parsed the criteria
      try {
        if (utils.isValidStringValue(att.Criteria)) {
          newAtt.Criteria = JSON.parse(att.Criteria)
        }
      }
      catch (error) {
        console.error(`Invalid Criteria definition for ${att.AttributeSystemName} \n`, error)
      }

      // Parsed the validation expression
      try {
        if (utils.isValidStringValue(att.ValidationExpression)) {
          const parsed = JSON.parse(att.ValidationExpression)
          if (parsed.hasOwnProperty('F') && isObject(parsed.F)) {
            newAtt.parsedValidationExpression = parsed.F
          }
          if (att.AttributeTypeId === AttributeType.Calc && utils.isDefined(parsed.calculatedRegexExpression) && utils.isDefined(parsed.calculatedRegexExpression.type)) {
            newAtt.calcTypeDataType = parsed.calculatedRegexExpression.type
          }
        }
      }
      catch (error) {
        console.error(`unable to parsed validation expression for ${att.AttributeSystemName} \n`, error)
      }

      // If FilterLookup, add to array
      if (att.SupportFilterLookup) {
        if (!utils.isDefined(att.AllowedValues) || (att.AllowedValues && att.AllowedValues.length === 0)) {
          lookupFromArticleAttributesData.push(att.AttributeSystemName)
        }
        else {
          // when vetting list assigned list the vetting list
          att.AllowedValues.forEach((value) => {
            const key = value.toLowerCase()
            if (!newAtt.FilterLookup.has(null)) {
              newAtt.FilterLookup.set(null, '[Blank]')
            }
            if (value && !newAtt.FilterLookup.has(key)) {
              newAtt.FilterLookup.set(key, value)
            }
          })
        }
      }

      attDic[newAtt.SystemName] = newAtt
    })

    const attsLimitExcess = {} as Record<string, number>
    lookupFromArticleAttributesData.forEach((attribute) => {
      attDic[attribute].FilterLookup.clear()
    })
    await this.articles.where(filter).each((art) => {
      // Build filter lookup lists
      lookupFromArticleAttributesData.forEach((la) => {
        if (!attDic[la].FilterLookup.has(null)) {
          attDic[la].FilterLookup.set(null, '[Blank]')
        }
        const attValues: string[] = []
        if (utils.isDefined(art[la])) {
          if (attDic[la].AttributeType === AttributeType.MultiValue) {
            attValues.push(...art[la] as string[])
          }
          else {
            attValues.push(art[la]!.toString())
          }
        }
        attValues.forEach((attVal) => {
          const key = attVal.toLowerCase()
          if (!attDic[la].FilterLookup.has(key)) {
            if (attDic[la].FilterLookup.size >= appConstants.limits.attributeLookupValues) {
              if (!attsLimitExcess.hasOwnProperty(la)) { attsLimitExcess[la] = 0 }
              attsLimitExcess[la]++
            }
            if (attDic[la].AttributeType === AttributeType.DateOption) {
              attVal = utils.formatDate(attVal)
            }
            attDic[la].FilterLookup.set(key, attVal)
          }
        })
      })
    })

    if (Object.keys(attsLimitExcess).length > 0) {
      console.warn(`One or more attributes have unique values that exceed the lookup limit of ${appConstants.limits.attributeLookupValues}`, attsLimitExcess)
      for (const att in attsLimitExcess) {
        if (attsLimitExcess.hasOwnProperty(att)) {
          attDic[att].FilterLookup.clear()
        }
      }
    }

    const favoriteTagsAtt = attDic._FavoriteTags
    if (favoriteTagsAtt) {
      favoriteTagsAtt.FilterLookup.clear()
      filter.CreatedByUserName = myUsername
      await this.favoriteTags.where(filter).each((favoriteTag) => {
        if (!favoriteTagsAtt.FilterLookup.has(favoriteTag.Id)) {
          favoriteTagsAtt.FilterLookup.set(favoriteTag.Id, favoriteTag.Tag)
        }
      })
    }

    const deliveryDateAtt = attDic._DeliveryDates
    deliveryDateAtt?.FilterLookup.clear()
    if (deliveryDateAtt && catalog.CatalogCRDList.length) {
      deliveryDateAtt.FilterLookup.set(null, '[Blank]')
      catalog.CatalogCRDList.forEach((crd) => {
        if (crd.Status > 0 && crd.Description) {
          let description = crd.Description
          if (catalog.Config.FilterDeliveryDateByMMMYYYYFormat) {
            const d = new Date(crd.CustomerRequiredDate)
            const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d)
            const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
            description = `${mo}-${ye}`
          }
          deliveryDateAtt.FilterLookup.set(crd.Id, description)
        }
      })
    }

    const segmentationAtt = attDic._Segmentations
    segmentationAtt?.FilterLookup.clear()
    if (segmentationAtt && ((!utils.isDefined(customer) && catalog.Config.AllowFullRangeCustomerToFilterBySegmentation)
      || (utils.isDefined(customer) && catalog.Config.AllowCustomerToFilterBySegmentation))) {
      segmentationAtt.AllowFiltering = true
      segmentationAtt.FilterLookup.set(null, '[Blank]')
      if (utils.isDefined(customerSegmentation)) {
        customerSegmentation.Segmentations.forEach((segmentation) => {
          if (catalog._IndexedCatalogSegmentation[segmentation.SegmentationId] && catalog._IndexedCatalogSegmentation[segmentation.SegmentationId].Status > 0) { segmentationAtt.FilterLookup.set(segmentation.SegmentationId, catalog._IndexedCatalogSegmentation[segmentation.SegmentationId].Name) }
        })
      }
      else {
        Object.values(catalog._IndexedCatalogSegmentation).forEach((segmentation) => {
          if (segmentation.Status > 0) { segmentationAtt.FilterLookup.set(segmentation.Id, segmentation.Name) }
        })
      }
    }
    const articleStateAttribute = attDic.StateName
    articleStateAttribute?.FilterLookup.clear()
    if (articleStateAttribute && activeArticleStateList && activeArticleStateList.length) {
      articleStateAttribute.AllowFiltering = true
      activeArticleStateList.forEach((state) => {
        if (state.Status) {
          articleStateAttribute.FilterLookup.set(state.StateName, state.StateName)
        }
      })
    }
    const modelStateAttribute = attDic.ModelStateName
    modelStateAttribute?.FilterLookup.clear()
    if (modelStateAttribute && activeArticleStateList && activeArticleStateList.length) {
      modelStateAttribute.AllowFiltering = true
      activeArticleStateList.forEach((state) => {
        if (state.Status) {
          modelStateAttribute.FilterLookup.set(state.StateName, state.StateName)
        }
      })
    }
    const periodAtt = attDic.Period
    periodAtt?.FilterLookup.clear()
    if (periodAtt && catalog.ShipmentWindowRangeList.length > 0) {
      periodAtt.FilterLookup.set(null, '[Blank]')
      const retailWindows = catalog.ShipmentWindowRangeList.filter(s => utils.isValidStringValue(s.Period))
      const periods = uniqBy(retailWindows, 'Period').map(a => a.Period).sort((a, b) => a.localeCompare(b))
      periods.forEach((period) => {
        periodAtt.FilterLookup.set(period.toLowerCase(), period)
      })
    }
    const seasonAtt = attDic._Seasons
    seasonAtt?.FilterLookup.clear()
    if (seasonAtt && Object.keys(linkedCatalogDetails).length) {
      const indexedSeasonValueCount = Object.values(linkedCatalogDetails).reduce((acu, linkedCatalog) => {
        const seasonValue = utils.isValidStringValue(linkedCatalog.Season) ? linkedCatalog.Season : '[Blank]'
        if (!utils.isDefined(acu[seasonValue])) {
          acu[seasonValue] = 0
        }
        acu[seasonValue]++
        return acu
      }, {} as Record<string, number>)

      Object.values(linkedCatalogDetails).forEach((linkedCatalog) => {
        const seasonValue = utils.isValidStringValue(linkedCatalog.Season) ? linkedCatalog.Season : '[Blank]'
        seasonAtt.FilterLookup.set(linkedCatalog.CatalogCode, indexedSeasonValueCount[seasonValue] <= 1 ? seasonValue : `${seasonValue} (${linkedCatalog.CatalogCode})`)
      })
      seasonAtt.AllowFiltering = seasonAtt.FilterLookup.size > 0
    }

    if (catalog.RequestsEnabled > 0) {
      if (catalog.RequestAttributeList && catalog.RequestAttributeList.length) {
        catalog.RequestAttributeList.forEach((att) => {
          if (att.Status > 0) {
            const parsedValidationExpression = utils.tryParse(att.ValidationExpression)
            const newAtt: IMyAttribute = {
              AttributeType: att.AttributeTypeId,
              AttributeSource: 'request',
              Creatable: true,
              DisplayName: att.AttributeDisplayName,
              Editable: att.AttributeTypeId !== AttributeType.Calc, // for calc type, editable is false
              ReadOnly: att.AttributeTypeId === AttributeType.Calc, // for calc type ReadOnly = true
              AllowFiltering: true,
              FilterLookup: new Map(),
              IsRequired: false,
              IsSeasonless: false,
              Overridable: false,
              IsStatic: false,
              IsPersonal: false,
              IsRequest: true,
              SystemName: att.AttributeSystemName,
              VettingList: catalog.Config.RequestAttributesVettingList[att.AttributeSystemName],
              ValidationExpression: att.ValidationExpression,
              ValidationMessage: att.ValidationMessage,
              parsedValidationExpression: parsedValidationExpression && parsedValidationExpression.hasOwnProperty('F') && isObject(parsedValidationExpression.F) ? parsedValidationExpression.F : undefined,
              Visible: true,
              IsModelLevel: false,
              Criteria: {},
              VisibleInListByDefault: false,
            }

            if (newAtt.VettingList && newAtt.VettingList.length) {
              newAtt.VettingList.forEach((value) => {
                const key = value.toLowerCase()
                if (!newAtt.FilterLookup.has(null)) {
                  newAtt.FilterLookup.set(null, '[Blank]')
                }
                if (value && !newAtt.FilterLookup.has(key)) {
                  newAtt.FilterLookup.set(key, value)
                }
              })
            }

            attDic[newAtt.SystemName] = newAtt
          }
        })
      }
    }

    return attDic
  }

  async buildResourceTree(catalogCode: number): Promise<ITreeNode[]> {
    const resources = (await this.resources.where({ CatalogCode: catalogCode }).toArray())
      .sort((a, b) => {
        let result = a.SortOrder - b.SortOrder
        if (result === 0) {
          const aResourceCategoryName: string = a.ResourceCategory.trim().toLowerCase() + a.ResourceName.trim().toLowerCase()
          const bResourceCategoryName: string = b.ResourceCategory.trim().toLowerCase() + b.ResourceName.trim().toLowerCase()
          result = aResourceCategoryName.localeCompare(bResourceCategoryName)
        }
        return result
      })

    const tree: ITreeNode[] = []
    const resourcesCategoryIndex = {}
    resources.forEach((resource) => {
      if (resource.Status > 0 && resource.ResourceVisibleInResourceList > 0) {
        const resourceCategory = resource.ResourceCategory?.trim().toLowerCase() || 'others'
        if (!resourcesCategoryIndex.hasOwnProperty(resourceCategory)) {
          tree.push({
            key: resourceCategory,
            label: resourceCategory,
            path: [],
            checked: false,
            expanded: false,
            sortOrder: 0,
            children: [],
            actions: [],
          })
          resourcesCategoryIndex[resourceCategory] = tree.length - 1
        }
        tree[resourcesCategoryIndex[resourceCategory]].children.push({
          key: resource.ResourceId,
          label: resource.ResourceName?.trim().toLowerCase(),
          path: [],
          checked: false,
          expanded: false,
          sortOrder: 0,
          children: [],
          actions: [],
        })
      }
    })
    return tree
  }

  async getResourceTypes(): Promise<ResourceType[]> {
    try {
      const res = await getResourceTypes()
      const data = res.data.map((itm: any) => new ResourceType(itm))
      await this.resourceTypes.bulkPut(data)
    }
    catch (error) {
      console.warn('Unable to get resource types', error)
    }
    return await this.resourceTypes.toArray()
  }

  async getRelationTypes(catalogCode: number): Promise<RelationType[]> {
    try {
      const res = await getRelationTypes(catalogCode)
      const data = res.data.map((itm: any) => new RelationType(catalogCode, itm))
      await this.relationTypes.bulkPut(data)
      await this.relationTypes.where('Status').equals(0).delete()
    }
    catch (error) {
      console.warn('Unable to get relation types', error)
    }
    return await this.relationTypes.where({ CatalogCode: catalogCode }).toArray()
  }

  async getObjectRelations(catalogCode: number, objectId: number, entityId: number, isRequestArticle: ZeroOrOneType = 0): Promise<ObjectRelation[]> {
    try {
      const res = await getObjectRelations(catalogCode, objectId, entityId, isRequestArticle)
      const data = res.data.map((itm: any) => new ObjectRelation(catalogCode, objectId, entityId, itm))
      await this.objectRelations.bulkPut(data)
    }
    catch (error) {
      console.warn('Unable to get object relations', error)
    }
    return await this.objectRelations.where({ CatalogCode: catalogCode, ObjectId: objectId, EntityId: entityId, IsRequestArticle: isRequestArticle }).toArray()
  }

  async getAdminArticles(catalogCode: number) {
    return await this.articles.where('CatalogCode').equals(catalogCode).toArray()
  }

  // delete jobs older than 1 day
  async deleteOlderJobs() {
    try {
      const oneDayAgo = Date.now() - 24 * 60 * 60 * 1000
      await this.jobs
        .where('CreatedDate')
        .below(oneDayAgo)
        .delete()
    }
    catch (error) {
      console.warn('Error deleting old jobs', error)
    }
  }
}
