import tinycolor from 'tinycolor2'
import { fabric } from 'fabric'
import MbArticleDetails from '../merch/services/articleDetails'
import MbArticleImage from '../merch/services/articleImage'
import { merchConstants, whiteboardConstants } from '@/models/constants'
import type { ImageObject, ShapeObject, TextObject } from '@/api/fileServer/model/generateModel'
import type { ShapeType } from '@/modules/whiteboard/services/shape'
import WbArticleDetails from '@/modules/whiteboard/services/articleDetails'
import WbModelDetails from '@/modules/whiteboard/services/modelDetails'
import WbModelImage from '@/modules/whiteboard/services/modelImage'
import WbArticleImage from '@/modules/whiteboard/services/articleImage'
import utils from '@/services/utils'
import type DuneAsset from '@/models/duneAsset'
import type MyArticle from '@/models/myArticle'

export async function toPptJson(wbObj: any, frame: any, staticImagePath: string, userStore, indexedCatalogArticleMap: Record<number, any> = {}, indexedCatalogModelNumberMap: Record<number, any> = {}, assetMap: Record<string, any> = {}, modelNumberToArticleNumberMap: Record<string, string> = {}) {
  let obj: (ImageObject | ShapeObject | TextObject | undefined)
  if (wbObj && wbObj.type) {
    if (wbObj.type === whiteboardConstants.objectTypes.articleDetails
      || wbObj.type === whiteboardConstants.objectTypes.modelDetails
      || wbObj.type === whiteboardConstants.objectTypes.textBox) {
      let text = wbObj.text
      if (wbObj.type === whiteboardConstants.objectTypes.articleDetails && indexedCatalogArticleMap[wbObj.catalogCode] && indexedCatalogArticleMap[wbObj.catalogCode][wbObj.articleId]) {
        text = await WbArticleDetails.getText(indexedCatalogArticleMap[wbObj.catalogCode][wbObj.articleId], wbObj.showLabels, wbObj.attributes, wbObj.attributesSeperator || '\n', wbObj.attributePlaceHolder || '', userStore.myAttributes)
      }
      else if (wbObj.type === whiteboardConstants.objectTypes.modelDetails && indexedCatalogModelNumberMap[wbObj.catalogCode] && indexedCatalogModelNumberMap[wbObj.catalogCode][wbObj.modelNumber]) {
        text = await WbModelDetails.getText(indexedCatalogModelNumberMap[wbObj.catalogCode][wbObj.modelNumber], wbObj.showLabels, wbObj.attributes, wbObj.attributesSeperator || '\n', wbObj.attributePlaceHolder || '')
      }
      obj = {
        type: 'text',
        content: {
          text,
          options: {
            x: getInches(wbObj.left - frame.left),
            y: getInches(wbObj.top - frame.top),
            w: getInches(wbObj.width * wbObj.scaleX),
            h: getInches(wbObj.height * wbObj.scaleY),
            align: wbObj.textAlign,
            bold: !!(wbObj.fontWeight && wbObj.fontWeight.toLowerCase() === 'bold'),
            color: tinycolor(wbObj.fill).toHex(),
            fontFace: wbObj.fontFamily,
            fontSize: getInches(Number.parseInt((wbObj.fontSize * wbObj.scaleX).toFixed(0)) * 72),
            italic: !!(wbObj.fontStyle && wbObj.fontStyle.toLowerCase() === 'italic'),
            rotate: wbObj.angle,
            valign: 'top',
            margin: 0,
          },
        },
      } as TextObject
    }
    else if (wbObj.type === whiteboardConstants.objectTypes.sticky) {
      let fontSize = wbObj.fontSize
      if (fontSize < 0) {
        // calculate auto font size
        const text = new fabric.Textbox(wbObj.text, {
          width: wbObj.width,
          height: wbObj.height,
          fontSize: 100,
        })

        const tempElement = document.createElement('span')
        document.body.appendChild(tempElement)

        tempElement.style.visibility = 'hidden'
        tempElement.style.position = 'absolute'
        if (text.fontFamily) {
          tempElement.style.fontFamily = text.fontFamily
        }
        if (text.fontSize) {
          tempElement.style.fontSize = `${text.fontSize}px`
        }
        if (text.text) {
          tempElement.textContent = text.text
        }

        // Calculate the maximum font size that fits the text box
        while (tempElement.offsetWidth > text.width! || tempElement.offsetHeight > text.height!) {
          text.fontSize = (text.fontSize || 0) - 1 // Decrease the font size
          tempElement.style.fontSize = `${text.fontSize}px` // Update the font size of the temporary DOM element
        }

        fontSize = text.fontSize

        document.body.removeChild(tempElement)
      }
      obj = {
        type: 'text',
        content: {
          text: wbObj.text,
          options: {
            x: getInches(wbObj.left - frame.left),
            y: getInches(wbObj.top - frame.top),
            w: getInches(wbObj.width * wbObj.scaleX),
            h: getInches(wbObj.height * wbObj.scaleY),
            align: 'center',
            fill: tinycolor(wbObj.fill).toHex(),
            color: tinycolor(wbObj.textColor).toHex(),
            fontFace: wbObj.font,
            fontSize: getInches(Number.parseInt((fontSize).toFixed(0)) * 72),
            bold: wbObj.bold,
            italic: wbObj.italic,
            rotate: wbObj.angle,
            valign: 'top',
            margin: 0,
          },
        },
      } as TextObject
    }
    else if (wbObj.type === whiteboardConstants.objectTypes.articleImage
      || wbObj.type === whiteboardConstants.objectTypes.modelImage
      || wbObj.type === whiteboardConstants.objectTypes.image) {
      let src = wbObj.src
      if (wbObj.type === whiteboardConstants.objectTypes.articleImage && indexedCatalogArticleMap[wbObj.catalogCode] && indexedCatalogArticleMap[wbObj.catalogCode][wbObj.articleId]) {
        const assets: DuneAsset[] = assetMap[indexedCatalogArticleMap[wbObj.catalogCode][wbObj.articleId].ArticleNumber] ? utils.sort(assetMap[indexedCatalogArticleMap[wbObj.catalogCode][wbObj.articleId].ArticleNumber], ['SortOrder']) : []
        src = await WbArticleImage.getImageSrc(wbObj.catalogCode, wbObj.articleId, wbObj.objectId, wbObj.isRequest, wbObj.width, wbObj.height, assets, wbObj.assetKey)
      }
      else if (wbObj.type === whiteboardConstants.objectTypes.modelImage && modelNumberToArticleNumberMap[wbObj.modelNumber] && assetMap[modelNumberToArticleNumberMap[wbObj.modelNumber]]) {
        const assets: DuneAsset[] = assetMap[modelNumberToArticleNumberMap[wbObj.modelNumber]] ? utils.sort(assetMap[modelNumberToArticleNumberMap[wbObj.modelNumber]], ['SortOrder']) : []
        src = WbModelImage.getImageSrc(wbObj.catalogCode, wbObj.width, wbObj.height, assets)
      }
      const staticImageTerms = ['noimg', 'loading']
      const isValidSrc = utils.isValidStringValue(src)
      const hasData = isValidSrc && src.toString().match(/^data:image\/(jpeg|png|gif|svg\+xml);base64,([A-Za-z0-9+/=]+)/)
      const data = hasData ? src : undefined
      const path = !hasData ? (!isValidSrc || staticImageTerms.some(term => src.toString().includes(term)) ? staticImagePath : src) : undefined
      obj = {
        type: 'image',
        x: getInches(wbObj.left - frame.left),
        y: getInches(wbObj.top - frame.top),
        w: getInches(wbObj.width * wbObj.scaleX),
        h: getInches(wbObj.height * wbObj.scaleY),
        path,
        data,
        rotate: wbObj.angle,
      } as ImageObject
    }
    else if (wbObj.type === whiteboardConstants.objectTypes.shape) {
      const shapeType = wbObj.shapeType as ShapeType
      const type = shapeType === 'triangle'
        ? 'triangle'
        : shapeType === 'rectangle'
          ? 'rect'
          : shapeType === 'circle'
            ? 'ellipse'
            : shapeType === 'star'
              ? 'star'
              : 'line'
      obj = {
        type,
        x: getInches(wbObj.left - frame.left),
        y: getInches(wbObj.top - frame.top),
        w: getInches(wbObj.width * wbObj.scaleX),
        h: getInches(wbObj.height * wbObj.scaleY),
        fill: {
          color: tinycolor(wbObj.fill).toHex(),
        },
        line: {
          color: tinycolor(wbObj.stroke).toHex(),
          width: wbObj.strokeWidth,
        },
        rotate: wbObj.angle,
      } as ShapeObject
    }
  }
  return obj
}
export async function merchtoPptJson(mbObj: any, staticImagePath: string, userStore, indexedCatalogArticleMap: Record<number, MyArticle> = {}, assetMap: Record<string, DuneAsset[]> = {}, slideObjects: (ImageObject | ShapeObject | TextObject)[] = [], offsetCoords: Record<string, any> = { top: 0, left: 0, width: 0, height: 0, scaleX: 1, scaleY: 1, isGrouped: false }) {
  let obj: (ImageObject | ShapeObject | TextObject | undefined)
  if (mbObj && mbObj.type) {
    const groupLeft = offsetCoords.left
    const groupTop = offsetCoords.top
    const objectLeft = offsetCoords.isGrouped ? mbObj.left * offsetCoords.scaleX : mbObj.left
    const objectTop = offsetCoords.isGrouped ? mbObj.top * offsetCoords.scaleY : mbObj.top
    const x = getInches((groupLeft + objectLeft) - ((mbObj.width * mbObj.scaleX * offsetCoords.scaleX) / 2))
    const y = getInches((groupTop + objectTop) - ((mbObj.height * mbObj.scaleY * offsetCoords.scaleY) / 2))
    const w = getInches(mbObj.width * mbObj.scaleX * offsetCoords.scaleX)
    const h = getInches(mbObj.height * mbObj.scaleY * offsetCoords.scaleY)
    if (mbObj.type === merchConstants.objectTypes.articleDetails
      || mbObj.type === merchConstants.objectTypes.textBox) {
      let text = mbObj.text
      if (mbObj.type === merchConstants.objectTypes.articleDetails && indexedCatalogArticleMap[mbObj.articleId]) {
        const customOptionsMap = {
          'ArticleName': 'ModelName',
          '_myPrices.wholesale': '_WholesalePrice',
          '_myPrices.retail': '_RetailPrice',
          '_myPrices.outlet': '_OutletPrice',
        }
        // need to convert the article name to model name as t1 studio web support model name only
        if (mbObj.customOptions && mbObj.customOptions.articleProps.length) {
          for (let index = 0; index < mbObj.customOptions.articleProps.length; index++) {
            const articleProp = mbObj.customOptions.articleProps[index]
            if (customOptionsMap.hasOwnProperty(articleProp)) {
              mbObj.customOptions.articleProps[index] = customOptionsMap[articleProp]
            }
          }
        }
        text = await MbArticleDetails.getText(indexedCatalogArticleMap[mbObj.articleId], mbObj.showLabels, mbObj.customOptions.articleProps, mbObj.customOptions.separator || '\n', mbObj.customOptions.attributePlaceHolder || '', userStore.myAttributes)
      }
      obj = {
        type: 'text',
        content: {
          text,
          options: {
            x: getInches(groupLeft + mbObj.left), // as origin in top left
            y: getInches(groupTop + mbObj.top),
            w,
            h,
            align: mbObj.textAlign,
            bold: !!(mbObj.fontWeight && mbObj.fontWeight.toLowerCase() === 'bold'),
            color: mbObj.fill ? tinycolor(mbObj.fill).toHex() : '000000',
            fontFace: mbObj.fontFamily,
            fontSize: getInches(Number.parseInt((mbObj.fontSize * mbObj.scaleX).toFixed(0)) * 72),
            italic: !!(mbObj.fontStyle && mbObj.fontStyle.toLowerCase() === 'italic'),
            rotate: mbObj.angle,
            valign: 'top',
            margin: 0,
            fill: mbObj.backgroundColor ? mbObj.backgroundColor.slice(1) : '',
            // currenty in merch link not supported  so whn supported add here as well
          },
        },
      } as TextObject
    }
    else if (mbObj.type === merchConstants.objectTypes.articleImage
      || mbObj.type === merchConstants.objectTypes.image) {
      let src = mbObj.data
      if (mbObj.type === merchConstants.objectTypes.articleImage) {
        const assets: DuneAsset[] = indexedCatalogArticleMap[mbObj.articleId] && assetMap[indexedCatalogArticleMap[mbObj.articleId].ArticleNumber] ? utils.sort(assetMap[indexedCatalogArticleMap[mbObj.articleId].ArticleNumber], ['SortOrder']) : []
        src = await MbArticleImage.getImageSrc(mbObj.articleId, mbObj.objectId, mbObj.isRequest, mbObj.width, mbObj.height, assets, mbObj.imageType)
      }
      const staticImageTerms = ['noimg', 'loading']
      const isValidSrc = utils.isValidStringValue(src)
      const hasData = isValidSrc && src.toString().match(/^data:image\/(jpeg|png|gif|svg\+xml);base64,([A-Za-z0-9+/=]+)/)
      const data = hasData ? src : undefined
      const path = !hasData ? (!isValidSrc || staticImageTerms.some(term => src.toString().includes(term)) ? staticImagePath : src) : undefined
      obj = {
        type: 'image',
        x,
        y,
        w,
        h,
        path,
        data,
        rotate: mbObj.angle,
        transparency: indexedCatalogArticleMap[mbObj.articleId] && !indexedCatalogArticleMap[mbObj.articleId].Status ? 50 : 0,
      } as ImageObject
    }
    else if (mbObj.type === merchConstants.objectTypes.rectangle
      || mbObj.type === merchConstants.objectTypes.triangle
      || mbObj.type === merchConstants.objectTypes.circle
      || mbObj.type === merchConstants.objectTypes.line) {
      // const shapeType = mbObj.type as ShapeType
      const type = mbObj.type === 'triangle'
        ? 'triangle'
        : mbObj.type === 'rectangle'
          ? 'rect'
          : mbObj.type === 'circle'
            ? 'ellipse'
            : 'line'
      obj = {
        type,
        x,
        y,
        w,
        h,
        fill: {
          color: tinycolor(mbObj.fill).toHex(),
        },
        line: {
          color: tinycolor(mbObj.stroke).toHex(),
          width: mbObj.strokeWidth,
        },
        rotate: mbObj.angle,
      } as ShapeObject
    }
    else if (mbObj.type === merchConstants.objectTypes.group) {
      for (let i = 0; i < mbObj.objects.length; i++) {
        const groupObject = mbObj.objects[i]
        await merchtoPptJson(groupObject, staticImagePath, userStore, indexedCatalogArticleMap, assetMap, slideObjects, { top: offsetCoords.top + mbObj.top, left: offsetCoords.left + mbObj.left, width: offsetCoords.width + mbObj.width, height: offsetCoords.height + mbObj.height, scaleX: offsetCoords.scaleX * mbObj.scaleX, scaleY: offsetCoords.scaleY * mbObj.scaleY, isGrouped: true })
      }
    }
  }
  if (obj) {
    slideObjects.push(obj)
  }
  return slideObjects
}
function getInches(value: number) {
  return value / 96
}
